import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types'
import { Modal } from 'react-bootstrap';
import { postAxios } from '../../../../hooks/useAxios'
import { formatCpf } from './../../../../utils/formatCpf';
import axios from 'axios'


CreateRequester.prototypes = {
    showModal: PropTypes.bool,
    handleCloseModal: PropTypes.func,
  };

function CreateRequester(props) {
    const {showModal, handleCloseModal} = props;

    // adicao de novo nome
    function addRequester() {
        const name = document.querySelector('#name').value;
        const cpf = document.querySelector('#cpf').value;
	const email = document.querySelector('#email').value;
	const departamento = document.querySelector('#departamento').value;
//troca de axios
        /*postAxios('http://localhost:5000/api/create/solicitant', null, {name, cpf}, {withCredentials: true})
          .then((resp) => {
            console.log('resp abaixo')
            console.log(resp)
            if(resp.data.error){
                alert('Este CPF já está cadastrado!')
            }
            if(resp.data.errors){
                for(let i=0; i<resp.data.errors.length; i++){
                    let elemento = document.querySelector(`#${resp.data.errors[i].elemento}`);
                    console.log(resp.data.errors[i].elemento)
                    console.log(elemento)
                    elemento.placeholder = "Preencha esse campo";
                    elemento.classList.add('erro');
                }
                return;
            }
            if(resp.data.successful === true){
                window.location.reload();
            }
            else {
                window.location.href = "http://localhost:3000/bad/request";
            }
          })
          .catch((error) => {
            //   window.location.href = "http://localhost:3000/bad/request";
                console.log(error)
                console.error('Erro na solicitação POST:', error);
          })*/
        axios.post('/api/create/solicitant',{name, cpf, email, departamento}, {withCredentials: true}).then((resp) => {
            if(resp.data.error){
                alert('Este email já está cadastrado!')
            }
            if(resp.data.errors){
                for(let i=0; i<resp.data.errors.length; i++){
                    let elemento = document.querySelector(`#${resp.data.errors[i].elemento}`);
                    elemento.placeholder = "Preencha esse campo";
                    elemento.classList.add('erro');
                }
                return;
            }
            if(resp.data.successful === true){
                window.location.reload();
            }
            else {
                window.location.href = "/bad/request";
            }
          })
          .catch((error) => {
                window.location.href = "/bad/request";
                console.error('Erro na solicitação POST:', error);
          })
    }

    
    function createModal() {
        return (
        <Modal show={showModal} onHide={handleCloseModal} size="lg">
            <Modal.Header className='bg-gray center-element'>
            <Modal.Title>Adicione um nome de solicitante:</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <div className='form-group'>
                <div className='row'>
                    <div className='col-3'>
                        <label htmlFor='name' className='required'>Nome do solicitante:</label>
                        <input id='name' name='name' type='text' className='form-control'/>
                    </div>
                    <div className='col-3'>
                        <label htmlFor='cpf' >Cpf do solicitante:</label>
                        <input id='cpf' name='cpf' type='text' className='form-control' onInput={formatCpf}/>
                    </div>
		    <div className='col-3'>
		        <label>Email</label>
		        <input id='email' name='email' type='email' className='form-control'/>
		    </div>
		    <div className='col-3'>
		        <label>Departamento</label>
		        <input id='departamento' name='departamento' type='text' className='form-control'/>
		    </div>
                </div>
            </div>
            </Modal.Body>
            <Modal.Footer>
            <button className="cancel btn btn-gray" onClick={handleCloseModal}>Cancelar</button>
            <button className="enviar btn btn-gray" onClick={addRequester}>Confirmar</button>
            </Modal.Footer>
            </Modal>
        )
    }

    return (
        <>
        <div className='row'>
            <div class="col-12 text-center">
            {createModal()}
            </div>
        </div>
        </>
    );
}

export default CreateRequester;
