import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types'
import { Modal } from 'react-bootstrap';

ViewRequester.prototypes = {
    showModal: PropTypes.bool,
    handleCloseModal: PropTypes.func,
    requester: PropTypes.object,
  };

function ViewRequester(props) {
    const {showModal, handleCloseModal, requester, setRequester} = props;
    
    function createModal() {
        return (
        <Modal show={showModal} onHide={handleCloseModal} size="lg">
            <Modal.Header className='bg-gray center-element'>
            <Modal.Title>Visualizando o solicitante: {requester != null ? requester.name : null}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <div className='form-group'>
                <div className='row'>
                    <div className='col-3'>
                        <label htmlFor='nome' className='required'>Nome do solicitante:</label>
                        <input id='nome' name='nome' type='text' className='form-control' value={requester.nome} disabled={true} style={{cursor: 'not-allowed'}}/>
                    </div>
                    <div className='col-3'>
                        <label htmlFor='cpf' className='required'>Cpf do solicitante:</label>
                        <input id='cpf' name='cpf' type='text' className='form-control' value={requester.cpfCnpj} disabled={true} style={{cursor: 'not-allowed'}}  />
                    </div>
		    <div className='col-3'>
		       <label>Email</label>
		       <input id='email' name='email' type='email' className='form-control' value={requester.email} disabled={true} style={{cursor: 'not-allowed'}} />
		    </div>
			<div className='col-3'>
				<label>Departamento</label>
				<input id='departamento' name='departamento' type='text' className='form-control' value={requester.departamento} disabled={true} style={{cursor: 'not-allowed'}}/>
			</div>
                </div>
            </div>
            </Modal.Body>
            <Modal.Footer>
            <button className="btn btn-gray" onClick={handleCloseModal}>Voltar</button>
            </Modal.Footer>
            </Modal>
        )
    }

    return (
        <>
        <div className='row'>
            <div class="col-12 text-center">
            {createModal()}
            </div>
        </div>
        </>
    );
}

export default ViewRequester;
