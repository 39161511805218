import Card from "../../basic/app-dashboard/Card";

function CardAnalysis() {
    return(
        <Card
            href="/ssj/analysis"
            image="/dist/img/analysis.jpg"
            title="Análise de Serviços"
            description="Obtenha uma análise das principais questões do serviço colocado por meio do AN bot"
        />
    );
}

export default CardAnalysis;
