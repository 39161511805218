import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

export const ItemMenuManageUsers = (props) => {
    const { show } = props;
  
    if(show){
        return (
            <li className="nav-item">
              <Link to="/manage-users" className="nav-link">
                <i className="nav-icon fas fa-cog"></i>
                <p>
                  Gerenciar Usuários
                </p>
              </Link>
            </li>
        );
    }
    
  };
  
  ItemMenuManageUsers.prototypes = {
    show: PropTypes.bool,
  };

export default ItemMenuManageUsers;
