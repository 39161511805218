import { Link } from 'react-router-dom';

function ItemMenuManageSsj() {
    return(
        <>
            <li className="nav-item">
                <Link to="/manage-ssj" className="nav-link">
                <i className="fas fa-table nav-icon"></i>
                <p>Gerenciador SSJ</p>
                </Link>
            </li>

        </>
    );
}

export default ItemMenuManageSsj;
