import AppHeader from "../../basic/app-header/AppHeader";
import AppMenu from "../../basic/app-menu/AppMenu";
import AppFooter from "../../basic/app-footer/AppFooter";
import AppSetting from "../../basic/app-setting/AppSetting";
import React, { useState } from "react";
import { postAxios } from "../../../hooks/useAxios";
import VideoUploader from "./VideoUploader";

function SendVideo() {
    const [videoFile, setVideoFile] = useState(null);
    const [isProcessing, setIsProcessing] = useState(false);
    const [videoType, setVideoType] = useState("");
    const [additionalRequest, setAdditionalRequest] = useState("");
    const form = new FormData();


    // envio do formulario
    function sendForm() {
        if (isProcessing) return; // Impede cliques adicionais enquanto já está em andamento

        console.log("entrou no send");
        setIsProcessing(true);

        form.append("file", videoFile);
	form.append("type", videoType);
        form.append("request", additionalRequest);

        console.log("Dados enviados: ");
        // Iterar sobre os pares de chave/valor e logar cada um
        for (const pair of form.entries()) {
            console.log(pair[0], pair[1]);
        }

        const config = {
            headers: {
                "Content-Type": "multipart/form-data",
            },
            withCredentials: true
        };

        console.log("fora do axios");
        postAxios(
            "/api/create/degravation",
            form,
            null,
            config
        )
            .then((resp) => {
                console.log(resp);
                window.location = `/transcription/history`;
            })
            .catch((error) => {
                console.log(error);
            
                // Verifica se há uma resposta do servidor
                if (error.response) {
                    // Se o status for 429, lida com esse caso específico
                    if (error.response.status === 429) {
                        alert("Muitas requisições foram feitas ao Gemini, por favor aguande alguns minutos e tente novamente!");
                    } else {
                        // Para outros status de erro, você pode lidar de maneira genérica
                        if (error.response.data) {
                            alert(error.response.data);
                        } else {
                            alert("Ocorreu um erro! Tente novamente mais tarde.");
                        }
                    }
                } else {
                    // Caso o erro não tenha uma resposta do servidor (erro de rede, por exemplo)
                    alert("Erro de conexão. Por favor confira sua conexão e tente novamente.");
                }
            
                console.error("Erro na solicitação POST:", error);
            });
    }

    // * Funcoes auxiliares

    const handleVideoFileChange = (file) => {
        setVideoFile(file);
    };

    const handleChangeVideoType = (event) => {
        setVideoType(event.target.value);
    };

    const handleTextAreaChange = (event) => {
        setAdditionalRequest(event.target.value);
    };

    return (
        <>
            <AppHeader />
            <AppMenu />
            
                <div class="content-wrapper">
                    <section
                        class="content-header"
                        style={{ marginBottom: -5 }}
                    >
                        <div class="container-fluid">
                            <div class="row mb-2">
                                <div class="col-sm-6">
                                    <h1>
                                        Transcrição de vídeos
                                    </h1>
                                </div>
                                <div class="col-sm-6">
                                    <ol class="breadcrumb float-sm-right">
                                        <li class="breadcrumb-item">
                                            <a href="/">Início</a>
                                        </li>
                                        <li class="breadcrumb-item active">
                                            Transcrição de vídeos
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section class="content">
                        <div class="container-fluid">
                            <div class="card card-default">
                                <div class="card-header bg-gray center-element">
                                    <h3
                                        class="card-title"
                                        style={{ fontWeight: 600 }}
                                    >
                                        Envie o vídeo para transcrição
                                    </h3>
                                </div>
                                <div class="card-body">
                                {isProcessing ? (
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        height: "60vh"
                                    }}>
                                        <div className="loader-container">
                                            <p className="loading-text">Realizando a transcrição do vídeo</p>
                                            <div className="loader">
                                                <div className="ball"></div>
                                                <div className="ball"></div>
                                                <div className="ball"></div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                ) : (
                                    <div class="card-body">
                                        <div
                                            className="row center-element"
                                            style={{ marginBottom: 16 }}
                                        >
                                            <label
                                                htmlFor="video-type"
                                                className="required"
                                                style={{
                                                    marginLeft: -115,
                                                    marginRight: 10,
                                                }}
                                            >
                                                Tipo de gravação:
                                            </label>
                                            <select
                                                id="video-type"
                                                name="video-type"
                                                value={videoType}
                                                onChange={handleChangeVideoType}
                                                className="form-control col-4"
                                                style={{ marginRight: 30 }}
                                            >
                                                <option></option>
                                                <option>Audiência</option>
                                                <option>Reunião</option>
                                                <option>Outro</option>
                                            </select>
                                        </div>
                                        <div
                                            className="row center-element"
                                            style={{ marginBottom: 25 }}
                                        >
                                            <label
                                                htmlFor="aditional-request"
                                                style={{
                                                    marginLeft: -115,
                                                    marginRight: 10,
                                                }}
                                            >
                                                Pergunta adicional:
                                            </label>
                                            <textarea
                                                id="aditional-request"
                                                name="aditional-request"
                                                className="form-control col-4"
                                                style={{
                                                    marginRight: 30,
                                                    height: "12vh",
                                                }}
                                                placeholder="Adicione uma pergunta específica sobre a gravação caso necessário"
                                                value={additionalRequest}
                                                onChange={handleTextAreaChange}
                                            ></textarea>
                                        </div>
                                        <div className="row center-element">
                                            <label
                                                className="required"
                                                style={{
                                                    marginLeft: -165,
                                                    marginRight: 10,
                                                }}
                                            >
                                                Arquivo de gravação:
                                            </label>
                                            <VideoUploader
                                                setVideoFile={
                                                    handleVideoFileChange
                                                }
                                            />
                                        </div>
                                    </div>                            
                                )}
                                    <div class="col-12 center-element" style={{marginTop: 10}}>
                                        <a href="/">
                                            <button
                                                type="button"
                                                class="cancel btn btn-gray"
                                            >
                                                Cancelar
                                            </button>
                                        </a>
                                        <a>
                                            <button
                                                type="submit"
                                                class="enviar btn btn-gray"
                                                style={{ marginLeft: 5 }}
                                                onClick={sendForm}
                                                disabled={isProcessing}
                                            >
                                                {isProcessing
                                                    ? "Aguarde..."
                                                    : "Transcrever"}
                                            </button>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            <AppFooter />
            <AppSetting />
        </>
    );
}

export default SendVideo;

