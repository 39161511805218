import React from 'react';

function AppFooter(){
  return (
    <footer class="main-footer footer">
      <div className='wrapper-social-ft'>
          <a href='https://www.linkedin.com/company/almeidaenogueira/' target="_blank" rel="noopener noreferrer" className='href-social-ft'>
              <div className='icon linkedin'>
                  <div className='tooltip'>Linkedin</div>
                  <div><i className='fab fa-linkedin'></i></div>
              </div>
          </a>
          <a href='https://www.instagram.com/almeidaenogueira/' target="_blank" rel="noopener noreferrer" className='href-social-ft'>
              <div className='icon instagram'>
                  <div className='tooltip'>Instagram</div>
                  <div><i className='fab fa-instagram'></i></div>
              </div>
          </a>
          <a href='https://www.youtube.com/@anadvogados8921' target="_blank" rel="noopener noreferrer" className='href-social-ft'>
              <div className='icon youtube'>
                  <div className='tooltip'>Youtube</div>
                  <div><i className='fab fa-youtube'></i></div>
              </div>
          </a>
          <a href='https://almeidaenogueira.com.br/' target="_blank" rel="noopener noreferrer" className='href-social-ft'>
              <div className='icon site'>
                  <div className='tooltip'>Site</div>
                  <div><i className='fa fa-globe-americas'></i></div>
              </div>
          </a>
      </div>
      <strong className='ft-msg'>Desenvolvido por Analyticus.</strong>
    </footer>
  )
}

export default AppFooter;
