import AppHeader from '../../../basic/app-header/AppHeader';
import AppMenu from '../../../basic/app-menu/AppMenu';
import AppFooter from '../../../basic/app-footer/AppFooter';
import AppSetting from '../../../basic/app-setting/AppSetting';
import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

// TODO: alterar valores padrão do georreferenciamento, gravames e car para o bd - muito extenso, com acento e espaços - ruim 


function EditAsset(){
    /* considerando que no bd os bens terão um idTipo, que será de 1 a 4:
    * 1: dinheiro;
    * 2: bens móveis;
    * 3: bens imóveis;
    * 4: ações;
    */
    const [idTipo, setIdTipo] = useState(null);
    // variável de estado para definir se o bem imóvel possui gravames (true) ou não (false)
    const [gravames, setGravames] = useState(true);
    // variável de estado para indicar o tipo do bem imóvel, sendo rural (0) e urbano (1)
    const [tipoImovel, setTipoImovel] = useState(1);
    // variável para pegar o id da url
    const {id} = useParams();
    // armazenar todos os sócios para o select
    const [todosSocios, setTodosSocios] = useState([]);
    // armazenar os sócios selecionados
    const [socios1, setSocios1] = useState([]); // dinheiro
    const [socios2, setSocios2] = useState([]); // bens móveis
    const [socios3, setSocios3] = useState([]); // bens imóveis
    const [socios4, setSocios4] = useState([]); // quotas/ações
    // armazenar os sócios iniciais para serem exibidos
    const initialSocios = useRef([]);
    // armazenar as informações pré-carregadas do bem que será editado
    const [bem, setBem] = useState({
        capital: null,
        cadastro: null,
        valor: null,
        descbem: null,
        monetario: null,
        matriculabem: null,
        cri: null,
        tipobem: null,
        comarca: null,
        cidadebem: null, 
        imposto: null,
        mercado: null,
        valorlocacao: null,
        gravames: null,
        descgravames: null,
        ccir: null,
        hectares: null,
        nirf: null,
        itr: null,
        car: null,
        georreferenciamento: null,
        razao: null,
        cnpj_me: null,
        nire: null,
        quotas_acoes: null,
        valor_quotas: null,
        usuario: null,
        denominacao: null,
        capital_social: null,
        patrimonio_liquido: null,
        iptu: null,
    })

    // * Carregar dados do bem
    useEffect(() => {
        const getAsset = async () => { 
          try {
            const response = await axios.post(`http://localhost:5000/api/get/assets`, {id}, { withCredentials: true });
            if (response.data.status === 200) {
                setBem(response.data.asset);
                console.log(response.data);

                // Extrair apenas os valores da propriedade 'id_soc' do array de objetos
                const idSocs = response.data.assetPartners.map(item => item.id_soc);
                initialSocios.current = idSocs;

                if (response.data.asset.capital == 'dinheiro') {
                    setIdTipo(1);
                    console.log('response: '+response.data.asset.assetPartners);
                    setSocios1(idSocs);
                }
                else if (response.data.asset.capital == 'moveis') { 
                    setIdTipo(2);
                    setSocios2(idSocs);
                }
                else if (response.data.asset.capital == 'imoveis') {
                    setIdTipo(3);
                    setSocios3(idSocs);
                }
                else if (response.data.asset.capital == 'quotas') {
                    setIdTipo(4);
                    setSocios4(idSocs);
                }

                if(response.data.asset.gravames == "possui gravames"){
                    setGravames(true);
                }
                else {
                    setGravames(false);
                }

                if(response.data.asset.tipobem == "rural"){
                    setTipoImovel(0);
                }
                else if(response.data.asset.tipobem == "urbano"){
                    setTipoImovel(1);
                }
            } else {
                console.log("status: "+response.data.status)
                window.location.href = "http://localhost:3000/bad/request";
            }
          } catch (error) {
            console.error(error);
            window.location.href = "http://localhost:3000/bad/request";
          }
        };
        const getPartners = async () => {
            try {
                const response = await axios.post(`http://localhost:5000/api/get/partners`, { withCredentials: true });
                if (response) {
                    console.log(response.data.partners);
                    const nomes = response.data.partners.map(item => item.nome);
                    setTodosSocios(nomes);
                }
            } catch (error) {
                console.error(error);
                window.location.href = "http://localhost:3000/bad/request";
            }
        }
        
        getAsset();
        getPartners();
    }, []);
        console.log("id:  "+idTipo);

    if (idTipo === null) {
        return(
            <>
            <AppHeader/>
            <AppMenu/>
            <div className='content-wrapper'>
                <div className='card-body'>
                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100vh"
                    }}>
                        <div class="spinner-border" role="status">
                        <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>
            </div>
            </>
        ); 
      }
    
    // * Função para atualizar o bem
    // função para permitir que os campos com valores pré-carregados possam ser alterados
    function loadForm(event){
        let { name, value } = event.target;
        console.log(name)
        console.log(value)
        setBem(prevState => ({
          ...prevState,
          [name]: value
        }));
        
    }
    function updateAsset() {
        const acao = 'update';
        let socios = null;
        if(bem.capital == 'dinheiro') {
            socios = socios1;
        }
        else if(bem.capital == 'moveis') {
            socios = socios2;
        }
        else if(bem.capital == 'imoveis') {
            socios = socios3;
        }
        else if(bem.capital == 'quotas') {
            socios = socios4;
        }

        // função para trocar os valores monetarios para float para o bd
        for (const chave in bem) {
            if (bem.hasOwnProperty(chave)) {
              let valorDoAtributo = bem[chave];
              // expressão regular para verificar se começa com "R$ "
                const regex = /^R\$\s/;
                // método test() da expressão regular para verificar se a string atende ao padrão
                if(regex.test(valorDoAtributo)) {
                    valorDoAtributo = formatMoneyForDatabase(valorDoAtributo);
                    setBem(prevState => ({
                        ...prevState,
                        [chave]: valorDoAtributo
                    }));
                }
            }
        }

        axios.post(`http://localhost:5000/api/actions/assets`, {id, acao, bem, socios}, {withCredentials: true}).then((resp)=>{
            if(Array.isArray(resp.data.erros)){
                console.log(resp.data);
                if(resp.data.erros.length === 0 && resp.data.status == 200){
                    window.location.href = "http://localhost:3000/assets/view-assets";  
                } else {
                    console.log(resp.data.erros)
                    for(let i=0; i<resp.data.erros.length; i++){
                        let elemento = document.querySelector(`#${resp.data.erros[i].elemento}`)
                        if(resp.data.erros[i].elemento == 'georreferenciamento' || resp.data.erros[i].elemento == 'car' || resp.data.erros[i].elemento == 'gravames' || resp.data.erros[i].elemento == 'tipobem'){
                          elemento.style.color = '#EF5350';
                          elemento.style.fontWeight = 'bold';
                          elemento.classList.add('erro')
                        } else {
                          elemento.placeholder = "Preencha esse campo"
                          elemento.classList.add('erro')
                        }
                      }
                }
            }
        })
    }

    // * Funções de formatação

    // função para formatar os campos de valores monetários(R$ 000.000.000,00)
    function formatMoneyInput(e) {
        const campo = e.target;
        const valor = campo.value.replace(/\D/g, ""); // Remove tudo o que não é dígito
        let novoValor = "";
        // Adiciona o prefixo "R$ "
        novoValor += "R$ ";
        // Verifica se há parte inteira
        if (valor.length > 2) {
            const parteInteira = valor.slice(0, -2);
            const parteDecimal = valor.slice(-2);
            // Formatação para a parte inteira
            novoValor += parteInteira.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.") + "," + parteDecimal;
        } else {
            novoValor += valor;
        }

        // caso o input esteja vazio
        if(novoValor == "R$ "){
            campo.valor = "";
        }
        else {
            campo.value = novoValor;
        }
    }
    
    // coloca o valor em float do bd para o valor monetário (R$ 000.000,00) para ser apresentado na página
    function formatFloatToMoney(value) {
        const floatValue = parseFloat(value);
        if (isNaN(floatValue)) {
          return "R$ 0,00"; // Caso o valor não seja um número válido, retorna R$ 0,00
        }
      
        const formattedValue = floatValue.toFixed(2).replace(".", ",");
        return `R$ ${formattedValue.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")}`;
    }

    // coloca o valor monetário (R$ 000.000,00) para float com 2 casas decimais para ser colocada no bd
    function formatMoneyForDatabase(value) {
        return parseFloat(value.replace(/[^0-9,-]/g, "").replace(",", ".")).toFixed(2);
    }

    // função para formatar o campo de cnpj(00.000.000/0000-00)
    function formatCnpj(e) {
        const campo = e.target;
        const valor = campo.value.replace(/\D/g, ""); // Remove tudo o que não é dígito
        let novoValor = "";
        novoValor = valor.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2}).*/, "$1.$2.$3/$4-$5");
        campo.value = novoValor;
    }

    // função para formatar campos numéricos
    function formatNumeric(e){
        const campo = e.target;
        const valor = campo.value.replace(/\D/g, ""); // Remove tudo o que não é dígito
        campo.value = valor;
    }

    // função para o funcionamento do radio button
    function handleRadioChange(event) {
        const radioButton = event.target;
        const labels = document.querySelectorAll('.custom-radio label');
        labels.forEach(function(label) {
          label.classList.remove('selected');
        });
        console.log(radioButton.name);
        if(radioButton.name == "gravames"){
            if (radioButton.checked) {
            radioButton.nextElementSibling.classList.add('selected');
            setBem(prevState => ({
                ...prevState,
                gravames: radioButton.value 
            }));
            }
        }
        else if(radioButton.name == "tipobem"){
            if (radioButton.checked) {
            radioButton.nextElementSibling.classList.add('selected');
            setBem(prevState => ({
                ...prevState,
                tipobem: radioButton.value 
            }));
            }
        }
        else if(radioButton.name == "georreferenciamento"){
            if (radioButton.checked) {
            radioButton.nextElementSibling.classList.add('selected');
            setBem(prevState => ({
                ...prevState,
                georreferenciamento: radioButton.value 
            }));
            }
        }
        else if(radioButton.name == "car"){
            if (radioButton.checked) {
            radioButton.nextElementSibling.classList.add('selected');
            setBem(prevState => ({
                ...prevState,
                car: radioButton.value 
            }));
            }
        }
            
    }

    // * Função de verificação no vetor de sócios

    function socioExiste(socio, socios){
        let valor = false;
        for(let i=0; i<socios.length; i++){
        if(socio == socios[i]){
            valor = true
        }
        }
        console.log("valor de retorno:  "+valor)
        return valor
    }
    
    // * Funções da tabela de sócios

    async function removeRow(socio, tr, setSocios) {
       
        tr.remove();
        
        setSocios(prevSocios => {
        const indice = prevSocios.indexOf(socio);
        if (indice !== -1) {
            const novoSocios = [...prevSocios];
            novoSocios.splice(indice, 1);
            console.log(novoSocios);
            return novoSocios;
        }
        return prevSocios;
        });
        
    }

    // funcão para lidar com a remoção dos sócios iniciais da tabela
    function removeRowInitial(evt, socio, setSocios, table) {
        // Obtém a linha pai do botão clicado
        const campo = evt.target
        const row = campo.closest('tr');

        // Verifica se a linha foi encontrada
        if (row) {
        // Obtém a referência ao elemento tbody
        const tbody = document.querySelector(`${table} tbody`);

        // Remove a linha do tbody
        tbody.removeChild(row);
        }

        setSocios(prevSocios => {
            const indice = prevSocios.indexOf(socio);
            if (indice !== -1) {
                const novoSocios = [...prevSocios];
                novoSocios.splice(indice, 1);
                console.log(novoSocios);
                return novoSocios;
            }
            return prevSocios;
            });

    }

    async function createRow(socio, id, setSocios){
        
        const tbody = document.querySelector(`${id} tbody`);

        const tr = document.createElement('tr');
    
        const tdSocio = document.createElement('td');
        tdSocio.id = 'partner';
        tdSocio.textContent = socio;

        const tdAcao = document.createElement('td');
        tdAcao.classList.add('action-column');

        const buttonAcao = document.createElement('button');
        const iAcao = document.createElement('i')
        buttonAcao.className = 'btn btn-danger'
        iAcao.className = 'fa fa-trash center-element';
        iAcao.title = 'Remover';

        buttonAcao.addEventListener('click', ()=>{removeRow(socio, tr, setSocios, null)});

        buttonAcao.appendChild(iAcao);
        tdAcao.appendChild(buttonAcao);

        tr.appendChild(tdSocio);
        tr.appendChild(tdAcao);

        tbody.appendChild(tr);
    }

    function createTable(tbody, tableElement, tabela, divTable, socio, id, setSocios){
        const trHeader = document.createElement('tr');
        const thSocio = document.createElement('th');
        thSocio.textContent = 'Sócio';
        const thAcao = document.createElement('th');
        thAcao.textContent = 'Ação'
        trHeader.appendChild(thSocio);
        trHeader.appendChild(thAcao);

        tbody.appendChild(trHeader);

        
        tableElement.appendChild(tbody);
        tabela.appendChild(tableElement);
        divTable.appendChild(tabela);
        
        createRow(socio, id, setSocios)
    }

    function getPartner(idTable, idSelect, socios, setSocios){
        const socio = document.querySelector(`${idSelect}`).value
        
        console.log("adição sócios:  "+socios)
        const divTable = document.querySelector(`${idTable}`);
        const valueTable = document.querySelector(`${idTable}`).innerHTML;
        const tabela = document.createElement('div');
        tabela.classList.add('tab-pane', 'fade', 'show', 'active');
        tabela.setAttribute('id', 'tabela');
        tabela.setAttribute('role', 'tabpanel');
        const tableElement = document.createElement('table');
        tableElement.classList.add('table', 'table-striped', 'table-bordered', 'mt-10');
        const tbody = document.createElement('tbody');
        console.log("valueTable  =  "+valueTable)
        if(valueTable == null || valueTable == '' || valueTable == undefined){
            console.log('------------tabela')
            setSocios([...socios, socio])
            createTable(tbody, tableElement, tabela, divTable, socio, idTable, setSocios)
        } else {
            console.log('------------linha')
            if(socioExiste(socio, socios)){
                console.log("sócio atual" + socio)
                alert("Esse sócio já foi inserido") // TODO: melhorar front - indicação em vermelho e divErro
            } else {
                setSocios([...socios, socio])
                createRow(socio, idTable, setSocios)
            }
        }
    } 

    // * Função para os bens imóveis
    function returnGravames(){
        return(
          <div className='row'>
            <div className="col-md-12 form-group">
                <i class="fa fa-comment"></i>
                <label style={{marginLeft: 3}} for="descgravames">Descrição gravames:</label>
                <textarea id="descgravames" class="form-control" name="descgravames" rows="6" placeholder="Descreva aqui o gravame..." value={bem.descgravames} onChange={loadForm}></textarea>
            </div>
          </div>  
        );
      }
  
    function returnRural(){
    return (
        <div>
        <div className='row' style={{marginTop: 15}}>
            <div className='form-group col-4'>
            <label for="ccir">Número do CCIR:</label>
            <input type='text' class='form-control' name='ccir' id='ccir' value={bem.ccir} onChange={loadForm}/>
            </div>
            <div className='form-group col-4'>
            <label for="nirf">Número do NIRF:</label>
            <input type='text' class='form-control' name='nirf' id='nirf' value={bem.nirf} onChange={loadForm}/>
            </div>
            
            <div className='form-group col-4 text-center'>
                <label>Possui Georreferenciamento?</label>
                <div className='text-center' id='custom-radio'>
                <input type="radio" name="georreferenciamento" id="possui georreferenciamento" value="possui Georreferenciamento" checked={bem.georreferenciamento === 'possui Georreferenciamento'} onChange={handleRadioChange}/>
                <label style={{padding: 7}} for="possui georreferenciamento">Sim</label>

                <input type="radio" name="georreferenciamento" id="não possui georreferenciamento" value="não possui Georreferenciamento" checked={bem.georreferenciamento === 'não possui Georreferenciamento'} onChange={handleRadioChange}/>
                <label style={{padding: 7}} for="não possui georreferenciamento">Não</label>
                </div> 
            </div>
        </div>
        <div className='row'>
            <div className='form-group col-4'>
            <label for="hectares">Área do imóvel em hectares:</label>
            <input type='text' class='form-control' name='hectares' id='hectares' value={bem.hectares} onChange={loadForm}/>
            </div>
            <div className='form-group col-4'>
            <label for="itr">Valor constante no ITR:</label>
            <input type='text' class='form-control' name='itr' id='itr' onInput={formatMoneyInput} value={bem.itr} onChange={loadForm}/>
            </div>
            <div className='form-group col-4 text-center'>
            <label>Possui CAR?</label>
            <div className='text-center' id='custom-radio'>
                <input type="radio" name="car" id="possui CAR" value="possui CAR" checked={bem.car === 'possui CAR'} onChange={handleRadioChange}/>
                <label style={{padding: 7}} for="possui CAR">Sim</label>

                <input type="radio" name="car" id="não possui CAR" value="não possui CAR" checked={bem.car === 'não possui CAR'} onChange={handleRadioChange}/>
                <label style={{padding: 7}} for="não possui CAR">Não</label>
            </div> 
            </div> 
        </div>
        </div>
    );
    }

    function returnUrbano(){
    return(
        <div>
        <div className='row' style={{marginTop: 15}}>
            <div className='form-group col-6'>
            <label for="cadastro">Cadastro Municipal:</label>
            <input type='text' class='form-control' name='cadastro' id='cadastro' value={bem.cadastro} onChange={loadForm}/>
            </div>
            <div className='form-group col-6'>
            <label for="iptu">Valor constante do IPTU:</label>
            <input type='text' class='form-control' name='iptu' id='iptu' onInput={formatMoneyInput} value={bem.iptu} onChange={loadForm}/>
            </div>
        </div>
        </div>
    );
    }

    function bensImoveis(){
        return(
            <div>
            <div className='row'>
              <div class="form-group col-12">
                <label for="denominacao">Denominação:</label>
                <input type="text" name="denominacao" id="denominacao" class="form-control" value={bem.denominacao} onChange={loadForm}/>
              </div>
            </div>
            <div className='row'>
              <div className='col-5'>
                <label for="matriculabem">Número de matrícula do bem:</label>
                <input type="text" name="matriculabem" id="matriculabem" class="form-control" value={bem.matriculabem} onChange={loadForm}/>
              </div>
              <div className='col-4'>
                <label for="cri">Número do CRI:</label>
                <input type='text' name='cri' id='cri' class='form-control' value={bem.cri} onChange={loadForm}/>
              </div>
              <div className='col-3 text-center'>
                <label>Possui gravames?</label>
                <div className='text-center' id='custom-radio'>
                  <input type="radio" name="gravames" id="possui gravames" value="possui gravames" onClick={()=>{setGravames(true)}} checked={bem.gravames === 'possui gravames'} onChange={handleRadioChange}/>
                  <label style={{marginLeft: 2, padding: 7}} for="possui gravames">Sim</label>

                  <input type="radio" name="gravames" id="não possui gravames" value="não possui gravames" onClick={()=>{setGravames(false)}} checked={bem.gravames === 'não possui gravames'} onChange={handleRadioChange}/>
                  <label style={{marginLeft: 8, padding: 7}} for="não possui gravames">Não</label>
                </div> 
              </div>
            </div>
            <div className='row'>
              <div className=' col-4'>
                <label for="comarca">Comarca do bem:</label>
                <input type="text" name="comarca" id="comarca" class="form-control" value={bem.comarca} onChange={loadForm}/>
              </div>
              <div className=' col-3 text-center'>
                <label>Tipo do imóvel:</label>
                <div style={{marginTop: 2}} className=' text-center' id='custom-radio'>
                  <input type="radio" name="tipobem" id="rural" value="rural" onClick={()=>{setTipoImovel(0)}} checked={bem.tipobem === 'rural'} onChange={handleRadioChange}/>
                  <label style={{marginLeft: 2}} for="rural">Rural</label>

                  <input type="radio" name="tipobem" id="urbano" value="urbano" onClick={()=>{setTipoImovel(1)}} checked={bem.tipobem === 'urbano'} onChange={handleRadioChange}/>
                  <label for="urbano">Urbano</label>
                </div> 
              </div>
              <div className='form-group col-5'>
                <label for="cidadebem">Cidade em que o bem está situado:</label>
                <input type="text" name="cidadebem" id="cidadebem" class="form-control" value={bem.cidadebem} onChange={loadForm}/>
              </div>
            </div>
            <div className='row'>
              <div className='form-group col-4'>
                <label for="imposto">Valor do imposto de renda:</label>
                <input type="text" name="imposto" id="imposto" class="form-control" onInput={formatMoneyInput} value={bem.imposto} onChange={loadForm}/>
              </div>
              <div className='form-group col-4'>
                <label for="mercado">Valor de mercado:</label>
                <input type='text' name='mercado' id='mercado' class='form-control' onInput={formatMoneyInput} value={bem.mercado} onChange={loadForm}/>
              </div>
              <div className='form-group col-4'>
                <label for="valorlocacao">Valor recebido de locação:</label>
                <input type='text' name='valorlocacao' id='valorlocacao' class='form-control' onInput={formatMoneyInput} value={bem.valorlocacao} onChange={loadForm}/>
              </div>
            </div>
            <div className='row'>
              <div className="col-md-12">
                <i class="fa fa-comment"></i>
                <label style={{marginLeft: 3}} for="descbem">Descrição do bem:</label>
                <textarea id="descbem" class="form-control" name="descbem" rows="6" placeholder="Descreva aqui o bem inserido..." value={bem.descbem} onChange={loadForm}></textarea>
              </div>
            </div>
        </div>
        );
    }
    
    
    // * Função principal
    function returnBem(){
        if(idTipo == 1){
            return(
                <div>
                    <div className='row'>
                        <div className='col-6'>
                            <label for="denominacao">Denominação:</label>
                        </div>
                        <div className='col-6'>
                            <label for="valor">Valor em reais:</label>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='form-group col-6'>
                            <input type="text" name="denominacao" id="denominacao" className='form-control' value={bem.denominacao} onChange={loadForm}/>
                        </div>
                        <div className='form-group col-6'>
                            <input type="text" name="valor" id="valor" className="form-control" onInput={formatMoneyInput} value={bem.valor} onChange={loadForm}/>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-11'>
                            <label for="vincular_socios1">Vincular sócios</label>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='form-group col-11'>
                            <select class="form-control select" style={{width: '100%'}} aria-hidden='true' name='socio' id="vincular_socio1">
                                {
                                    todosSocios && todosSocios.map(resp=> (
                                        <option>{resp}</option>
                                    ))
                                }
                            </select>
                        </div>
                        <div className='form-group col-1'>
                            <button class="add btn btn-gray" onClick={()=>{getPartner('#table1', '#vincular_socio1', socios1, setSocios1)}}><i class="fas fa-plus"/></button>
                        </div>
                    </div>
                    <div className='row'>
                        <div className="card-body table-responsive p-0">
                            <div id="table1" class="tab-pane fade show active" role="tabpanel">
                                <table class="table table-striped table-bordered mt-10">
                                <tbody>
                                    <tr>
                                        <th>Sócio</th>
                                        <th>Ação</th>
                                    </tr>
                                    {
                                        initialSocios && initialSocios.current.map(resp => (
                                            <tr>
                                                <td>{resp}</td>
                                                <td class="action-column">
                                                    <button class="btn btn-danger" onClick={(evt)=>{removeRowInitial(evt, resp, setSocios1, '#table1')}}>
                                                    <i class="fa fa-trash center-element" title="Remover"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        if(idTipo == 2){
            return(
                <div className='card-body'>
                    <div className='row'>
                        <div className='col-6'>
                            <label for="denominacao">Denominação:</label>
                        </div>
                        <div className='col-6'>
                            <label for="monetario">Valor monetário:</label>
                        </div>    
                    </div>
                    <div className='row'>
                        <div class="form-group col-6">
                            <input type="text" name="denominacao" id="denominacao" class="form-control" value={bem.denominacao} onChange={loadForm}/>
                        </div>
                        <div class="form-group col-6">
                            <input type="text" name="monetario" id="monetario" class="form-control" onInput={formatMoneyInput} value={bem.monetario} onChange={loadForm}/>
                        </div>
                    </div>
                    <div className='row'>
                        <div className="col-md-12">
                            <div className="form-group">
                            <i class="fa fa-comment"></i>
                            <label style={{marginLeft: 3}} for="descbem">Descrição do bem:</label>
                            <textarea id="descbem" class="form-control" name="descbem" rows="6" placeholder="Descreva aqui o bem inserido..." value={bem.descbem} onChange={loadForm}></textarea>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12'>
                            <label for="vincular_socio2">Vincular sócios</label>
                        </div>
                        </div>
                        <div className='row'>
                        <div className='form-group col-11'>
                            <select class="form-control select" style={{width: '100%'}} aria-hidden='true' name='socio' id="vincular_socio2">
                                {
                                    todosSocios && todosSocios.map(resp=> (
                                        <option>{resp}</option>
                                    ))
                                }
                            </select>
                        </div>
                        <div className='col-1'>
                            <button class="add btn btn-gray" onClick={()=>{getPartner('#table2', '#vincular_socio2', socios2, setSocios2)}}><i class="fas fa-plus"/></button>
                        </div>
                    </div>
                    <div className="card-body table-responsive p-0">
                        <div id="table2" class="tab-pane fade show active" role="tabpanel">
                            <table class="table table-striped table-bordered mt-10">
                            <tbody>
                                <tr>
                                    <th>Sócio</th>
                                    <th>Ação</th>
                                </tr>
                                {
                                    initialSocios && initialSocios.current.map(resp => (
                                        <tr>
                                            <td>{resp}</td>
                                            <td class="action-column">
                                                <button class="btn btn-danger" onClick={(evt)=>{removeRowInitial(evt, resp, setSocios2, '#table2')}}>
                                                <i class="fa fa-trash center-element" title="Remover"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            );
        }
        if(idTipo == 3 && tipoImovel == 0 && gravames == false){
            return(
              <div className='card-body'>
                {bensImoveis()}
                {returnRural()}
                <div className='row'>
                    <div className='col-12'>
                        <label>Vincular sócios</label>
                    </div>
                    </div>
                    <div className='row'>
                    <div className='form-group col-11'>
                        <select class="form-control select" style={{width: '100%'}} aria-hidden='true' name='socio' id="vincular_socio3">
                            {
                                todosSocios && todosSocios.map(resp=> (
                                    <option>{resp}</option>
                                ))
                            }
                        </select>
                    </div>
                    <div className='col-1'>
                        <button class="add btn btn-gray" onClick={()=>{getPartner('#table3', '#vincular_socio3', socios3, setSocios3)}}><i class="fas fa-plus"/></button>
                    </div>
                </div>
                <div className="card-body table-responsive p-0">
                    <div id="table3" class="tab-pane fade show active" role="tabpanel">
                        <table class="table table-striped table-bordered mt-10">
                        <tbody>
                            <tr>
                                <th>Sócio</th>
                                <th>Ação</th>
                            </tr>
                            {
                                initialSocios && initialSocios.current.map(resp => (
                                    <tr>
                                        <td>{resp}</td>
                                        <td class="action-column">
                                            <button class="btn btn-danger" onClick={(evt)=>{removeRowInitial(evt, resp, setSocios3, '#table3')}}>
                                            <i class="fa fa-trash center-element" title="Remover"></i>
                                            </button>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                        </table>
                    </div>
                </div>
              </div>
            );
        }
        else if(idTipo == 3 && tipoImovel == 0 && gravames == true){
        return(
            <div className='card-body'>
            {bensImoveis()}
            {returnRural()}
            {returnGravames()}
            <div className='row'>
                <div className='col-12'>
                    <label>Vincular sócios</label>
                </div>
                </div>
                <div className='row'>
                <div className='form-group col-11'>
                    <select class="form-control select" style={{width: '100%'}} aria-hidden='true' name='socio' id="vincular_socio3">
                        {
                            todosSocios && todosSocios.map(resp=> (
                                <option>{resp}</option>
                            ))
                        }
                    </select>
                </div>
                <div className='col-1'>
                    <button class="add btn btn-gray" onClick={()=>{getPartner('#table3', '#vincular_socio3', socios3, setSocios3)}}><i class="fas fa-plus"/></button>
                </div>
            </div>
            <div className="card-body table-responsive p-0">
                <div id="table3" class="tab-pane fade show active" role="tabpanel">
                    <table class="table table-striped table-bordered mt-10">
                    <tbody>
                        <tr>
                            <th>Sócio</th>
                            <th>Ação</th>
                        </tr>
                        {
                            initialSocios && initialSocios.current.map(resp => (
                                <tr>
                                    <td>{resp}</td>
                                    <td class="action-column">
                                        <button class="btn btn-danger" onClick={(evt)=>{removeRowInitial(evt, resp, setSocios3, '#table3')}}>
                                        <i class="fa fa-trash center-element" title="Remover"></i>
                                        </button>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                    </table>
                </div>
            </div>
            </div>
        );
        }
        else if(idTipo == 3 && tipoImovel == 1 && gravames == false){
        return(
            <div className='card-body'>
            {bensImoveis()}
            {returnUrbano()}
            <div className='row'>
                <div className='col-12'>
                    <label>Vincular sócios</label>
                </div>
                </div>
                <div className='row'>
                <div className='form-group col-11'>
                    <select class="form-control select" style={{width: '100%'}} aria-hidden='true' name='socio' id="vincular_socio3">
                        {
                            todosSocios && todosSocios.map(resp=> (
                                <option>{resp}</option>
                            ))
                        }
                    </select>
                </div>
                <div className='col-1'>
                    <button class="add btn btn-gray" onClick={()=>{getPartner('#table3', '#vincular_socio3', socios3, setSocios3)}}><i class="fas fa-plus"/></button>
                </div>
            </div>
            <div className="card-body table-responsive p-0">
                <div id="table3" class="tab-pane fade show active" role="tabpanel">
                    <table class="table table-striped table-bordered mt-10">
                    <tbody>
                        <tr>
                            <th>Sócio</th>
                            <th>Ação</th>
                        </tr>
                        {
                            initialSocios && initialSocios.current.map(resp => (
                                <tr>
                                    <td>{resp}</td>
                                    <td class="action-column">
                                        <button class="btn btn-danger" onClick={(evt)=>{removeRowInitial(evt, resp, setSocios3, '#table3')}}>
                                        <i class="fa fa-trash center-element" title="Remover"></i>
                                        </button>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                    </table>
                </div>
            </div>
            </div>
        );
        }
        else if(idTipo == 3 && tipoImovel == 1 && gravames == true){
        return(
            <div className='card-body'>
            {bensImoveis()}
            {returnUrbano()}
            {returnGravames()}
            <div className='row'>
                <div className='col-12'>
                    <label>Vincular sócios</label>
                </div>
                </div>
                <div className='row'>
                <div className='form-group col-11'>
                    <select class="form-control select" style={{width: '100%'}} aria-hidden='true' name='socio' id="vincular_socio3">
                        {
                            todosSocios && todosSocios.map(resp=> (
                                <option>{resp}</option>
                            ))
                        }
                    </select>
                </div>
                <div className='col-1'>
                    <button class="add btn btn-gray" onClick={()=>{getPartner('#table3', '#vincular_socio3', socios3, setSocios3)}}><i class="fas fa-plus"/></button>
                </div>
            </div>
            <div className="card-body table-responsive p-0">
                <div id="table3" class="tab-pane fade show active" role="tabpanel">
                    <table class="table table-striped table-bordered mt-10">
                    <tbody>
                        <tr>
                            <th>Sócio</th>
                            <th>Ação</th>
                        </tr>
                        {
                            initialSocios && initialSocios.current.map(resp => (
                                <tr>
                                    <td>{resp}</td>
                                    <td class="action-column">
                                        <button class="btn btn-danger" onClick={(evt)=>{removeRowInitial(evt, resp, setSocios3, '#table3')}}>
                                        <i class="fa fa-trash center-element" title="Remover"></i>
                                        </button>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                    </table>
                </div>
            </div>
            </div>
        );
        }
        else if(idTipo == 4) {
            return(
            <div>
                <div className='row'>
                <div class="form-group col-12">
                    <label className='required' for="denominacao">Denominação:</label>
                    <input type="text" name="denominacao" id="denominacao" className="form-control" value={bem.denominacao} onChange={loadForm}/>
                </div>
                </div>
                <div className='row'>
                <div className='form-group col-6'>
                    <label for="razao">Informe a razão social da empresa:</label>
                    <input type='text' class='form-control' id="razao" name='razao' value={bem.razao} onChange={loadForm}/>
                </div>
                <div className='form-group col-3'>
                    <label for="cnpj_me">Informe o CNPJ/ME:</label>
                    <input type='text' class='form-control' onInput={formatCnpj} name="cnpj_me" id="cnpj_me" value={bem.cnpj_me} onChange={loadForm}/>
                </div>
                <div className='form-group col-3'>
                    <label for="nire">Informe o NIRE:</label>
                    <input type='text' class='form-control' name="nire" id="nire" onInput={formatNumeric} value={bem.nire} onChange={loadForm}/>
                </div>
                </div>
                <div className='row'>
                <div className='form-group col-6'>
                    <label for="capital_social">Valor do capital social:</label>
                    <input type='text' class='form-control' onInput={formatMoneyInput} name="capital_social" id="capital_social" value={bem.capital_social} onChange={loadForm}/>
                </div>
                <div className='form-group col-6'>
                    <label for="patrimonio_liquido">Valor do patrimônio líquido:</label>
                    <input type='text' class='form-control' onInput={formatMoneyInput} name="patrimonio_liquido" id="patrimonio_liquido" value={bem.patrimonio_liquido} onChange={loadForm}/>
                </div>
                </div>
                <div className='row'>
                <div className='form-group col-6'>
                    <label for="quotas_acoes">Informe o número de quotas ou ações:</label>
                    <input type='text' class='form-control' name="quotas_acoes" id="quotas_acoes" onInput={formatNumeric} value={bem.quotas_acoes} onChange={loadForm}/>
                </div>
                <div className='form-group col-6'>
                    <label for="valor_quotas">Informe o valor das quotas ou ações:</label>
                    <input type='text' class='form-control' onInput={formatMoneyInput} name="valor_quotas" id="valor_quotas" value={bem.valor_quotas} onChange={loadForm}/>
                </div>
                </div>
                <div className='row'>
                    <div className='col-12'>
                        <label>Vincular sócios</label>
                    </div>
                    </div>
                    <div className='row'>
                    <div className='form-group col-11'>
                        <select class="form-control select" style={{width: '100%'}} aria-hidden='true' name='socio' id="vincular_socio4">
                            {
                                todosSocios && todosSocios.map(resp=> (
                                    <option>{resp}</option>
                                ))
                            }
                        </select>
                    </div>
                    <div className='col-1'>
                        <button class="add btn btn-gray" onClick={()=>{getPartner('#table4', '#vincular_socio4', socios4, setSocios4)}}><i class="fas fa-plus"/></button>
                    </div>
                </div>
                <div className="card-body table-responsive p-0">
                    <div id="table4" class="tab-pane fade show active" role="tabpanel">
                        <table class="table table-striped table-bordered mt-10">
                        <tbody>
                            <tr>
                                <th>Sócio</th>
                                <th>Ação</th>
                            </tr>
                            {
                                initialSocios && initialSocios.current.map(resp => (
                                    <tr>
                                        <td>{resp}</td>
                                        <td class="action-column">
                                            <button class="btn btn-danger" onClick={(evt)=>{removeRowInitial(evt, resp, setSocios4, '#table4')}}>
                                            <i class="fa fa-trash center-element" title="Remover"></i>
                                            </button>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                        </table>
                    </div>
                </div>
            </div>
            );
        
        }
    }

    return(
        <>
        <AppHeader/>
        <AppMenu/>
        <div class="content-wrapper">
            <section class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-6">
                            <h1>Editor de bens</h1>
                        </div>
                        <div class="col-sm-6">
                            <ol class="breadcrumb float-sm-right">
                                <li class="breadcrumb-item"><a href="/">Início</a></li>
                                <li class="breadcrumb-item active">Editor de bens</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </section>
            <section class="content">
                <div class="container-fluid">
                    <div class="card card-default">
                        <div className="card-header center-element bg-gray">
                          <h3 className="card-title" style={{fontWeight: 600}}>{`Editar bem: ${bem.denominacao}`}</h3>
                        </div>
                        <div className='card-body'>
                            {returnBem()}
                            <div class="col-12 center-element">
                                <a href="/assets/view-assets"><button type="button" class="cancel btn btn-gray">Cancelar</button></a>
                                <button type="submit" class="enviar btn btn-gray" style={{marginLeft: 5}} onClick={updateAsset}>Confirmar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <AppFooter/>
        <AppSetting/>
        </>
    );
}

export default EditAsset;