import React from 'react';
import { useState, useEffect } from 'react';
import { postAxios } from '../../../hooks/useAxios';
import AppHeader from '../app-header/AppHeader';
import AppMenu from '../app-menu/AppMenu';
import AppFooter from '../app-footer/AppFooter';
import AppSetting from '../app-setting/AppSetting';
import CardSsj from '../../custom/ssj/CardSsj';
import CardBi from '../../custom/bi/CardBi';
import CardPaymentPortal from '../../custom/payment-portal/CardPaymentPortal';
import CardHolding from '../../custom/holding/CardHolding';
import CardManageSsj from '../../custom/manage-ssj/CardManageSsj';
import CardManageUsers from '../../custom/manage-users/CardManageUsers';
import CardAnalysis from '../../custom/ssj-analysis/CardAnalysis';

function AppDashboard() {
  const[user, setUser] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [hasSsj, setHasSsj] = useState(false);
  const [hasBi, setHasBi] = useState(false);
  const [hasPaymentPortal, setHasPaymentPortal] = useState(false);
  const [hasManageSsj, setHasManageSsj] = useState(false);
  const [hasHolding, setHasHolding] = useState(false);
  const [hasAnalysis, setHasAnalysis] = useState(false);


  useEffect(() => {
    const getUser = async () => {
        postAxios('/api/components/clients', null, null, {withCredentials: true})
            .then((resp) => {
                setUser(resp.data.user);
                resp.data.user.functions && resp.data.user.functions.map((resp) => {
                  if(resp === 'SSJ-Cliente'){
                    setHasSsj(true)
                  }
                  else if(resp === 'BI'){
                    setHasBi(true)
                  }
                  else if(resp === 'Portal-Pagamentos'){
                    setHasPaymentPortal(true)
                  }
                  else if(resp === 'SSJ-ADM'){
                    setHasManageSsj(true)
                  }
                  else if(resp === 'Holding') {
                    setHasHolding(true)
                  }
	  	  else if(resp === 'Analysis') {
		    setHasAnalysis(true)
		  }
                })
                resp.data.user.nivel === 'ADM' ? setIsAdmin(true) : setIsAdmin(false)
              })
            .catch((error) => {
                window.location.href = "/login";
                console.error('Erro na solicitação POST:', error);
            })
    }

    getUser();
    
  }, [hasBi, hasHolding, hasManageSsj, hasPaymentPortal, hasSsj, hasAnalysis,  isAdmin]); 

  if(user === null || !user) {
      return(
          <>
          <AppHeader/>
          <AppMenu/>
          <div className='content-wrapper'>
              <div className='card-body'>
                  <div style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100vh"
                  }}>
                      <div class="spinner-border" role="status">
                      <span class="sr-only">Loading...</span>
                      </div>
                  </div>
              </div>
          </div>
          <AppFooter/>
          <AppSetting/>
          </>
      ); 
  }

  return (
    <div className="content-wrapper" style={{minHeight: 675}}>
      <div className="content">
        <div className="container-fluid">
          <div className="row mb-2 center-element">
            <div className="col-12" style={{marginTop: "15px"}}>
              <h2 style={{marginLeft: "30px"}}>
                  Bem vindo de volta{user ? ', '+user.name : null}!
              </h2>
              <div className='background'>
                <section className="articles">
                    {hasSsj ? <CardSsj show={hasSsj} />: <></>}
                    
                    {hasBi && user.nivel === 'ADM' ? <CardBi show={hasBi} />: <></>}
                    {hasPaymentPortal ? <CardPaymentPortal show={hasPaymentPortal} />: <></>}
                    {isAdmin ? < CardManageUsers show={isAdmin} /> : <></>}
                    {hasManageSsj ?  <CardManageSsj show={hasManageSsj} /> : <></>}
                    {hasHolding ? <CardHolding show={hasHolding} /> : <></>}
	  	    {hasAnalysis ? <CardAnalysis show={hasAnalysis} /> :<></>}

                    {/* mais cartões aqui */}
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AppDashboard;
