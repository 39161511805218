import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types'
import { Modal } from 'react-bootstrap';
import { postAxios } from '../../../../hooks/useAxios'
import { formatCpf } from './../../../../utils/formatCpf';


EditRequester.prototypes = {
    showModal: PropTypes.bool,
    handleCloseModal: PropTypes.func,
    requester: PropTypes.object,
    setRequester: PropTypes.func,
  };

function EditRequester(props) {
    const {showModal, handleCloseModal, requester, setRequester} = props;
 

    // edita um solicitante
    function editRequester() {
        const name = document.querySelector('#nome').value;
        const cpf = document.querySelector('#cpf').value;
        const email = document.querySelector('#email').value;
	const departamento = document.querySelector('#departamento').value;
        postAxios(`/api/update/solicitant/${requester.id}`, {name, cpf, email, departamento})
          .then((resp) => {
            if(resp.data.error){
                alert('Este email já está cadastrado!')
            }
            if(resp.data.errors){
                for(let i=0; i<resp.data.errors.length; i++){
                    let elemento = document.querySelector(`#${resp.data.errors[i].elemento}`);
                    elemento.placeholder = "Preencha esse campo";
                    elemento.classList.add('erro');
                }
                return;
            }
            if(resp.data.successful === true){
                window.location.reload();
            }
            else {
                window.location.href = "/bad/request";
            }
          })
          .catch((error) => {
              window.location.href = "/bad/request";
              console.error('Erro na solicitação POST:', error);
          })
    }

    // função para permitir que os campos com valores pré-carregados possam ser alterados
    function loadForm(event){
        const { name, value } = event.target;
        setRequester(prevState => ({
          ...prevState,
          [name]: value
        }));
        
    }
    
    function createModal() {
        return (
        <Modal show={showModal} onHide={handleCloseModal} size="lg">
            <Modal.Header className='bg-gray center-element'>
            <Modal.Title>Editando o solicitante: {requester != null ? requester.name : null}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <div className='form-group'>
                <div className='row'>
                    <div className='col-3'>
                        <label htmlFor='name' className='required'>Nome do solicitante:</label>
                        <input id='nome' name='nome' type='text' className='form-control' value={requester.nome} onChange={loadForm}/>
                    </div>
                    <div className='col-3'>
                        <label htmlFor='cpf' >Cpf do solicitante:</label>
                        <input id='cpf' name='cpf' type='text' className='form-control' value={requester.cpfCnpj} onChange={loadForm} onInput={formatCpf}/>
                    </div>
		    <div className='col-3'>
		        <label>Email</label>
		        <input id='email' name='email' type='email' className='form-control' value={requester.email} onChange={loadForm} />
		   </div>
		   <div className='col-3'>
		        <label>Departamento</label>
		        <input id='departamento' type='text' className='form-control' value={requester.dapartamento} onChange={loadForm} name='departamento' />
		   </div>
                </div>
            </div>
            </Modal.Body>
            <Modal.Footer>
            <button className="cancel btn btn-gray" onClick={handleCloseModal}>Cancelar</button>
            <button className="enviar btn btn-gray" onClick={editRequester}>Confirmar</button>
            </Modal.Footer>
            </Modal>
        )
    }

    return (
        <>
        <div className='row'>
            <div class="col-12 text-center">
            {createModal()}
            </div>
        </div>
        </>
    );
}

export default EditRequester;
