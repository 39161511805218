import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";

const VideoUploader = ({ setVideoFile }) => {
    const [file, setFile] = useState(null);

    const onDrop = (acceptedFiles) => {
        const videoFile = acceptedFiles[0];
        setFile(URL.createObjectURL(videoFile));
        setVideoFile(videoFile);
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: 'video/*', // Aceita somente arquivos de vídeo
    });

    const handleDeleteFile = () => {
        setFile(null);
        setVideoFile(null);
    };

    return (
        <div style={containerStyle}>
            <div {...getRootProps()} style={dropzoneStyle}>
                <input {...getInputProps()} />
                {file ? (
                    <>
                        <video controls style={videoStyle}>
                            <source src={file} type="video/mp4" />
                            Seu navegador não suporta a tag de vídeo.
                        </video>
                        <button
                            className="btn btn-danger"
                            onClick={handleDeleteFile}
                            style={deleteButtonStyle}
                        >
                            <DeleteIcon />
                        </button>
                    </>
                ) : isDragActive ? (
                    <div>
                        <CloudUploadIcon style={archiveIndicator} />
                        <p style={textStyle}>Solte o vídeo aqui...</p>
                    </div>
                ) : (
                    <>
                        <div>
                            <CloudUploadIcon style={archiveIndicator} />
                            <p style={textStyle}>
                                Arraste e solte o vídeo aqui, ou clique para selecionar
                            </p>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

const containerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
};

const dropzoneStyle = {
    display: "flex",
    alignItems: "center",
    border: "2px dashed #cccccc",
    borderRadius: "4px",
    padding: "20px",
    textAlign: "center",
    cursor: "pointer",
    height: "35vh",
    width: "45vh",
};

const videoStyle = {
    width: "100%",
    height: "100%",
};

const textStyle = {
    marginTop: "8vh",
    color: "#888",
};

const archiveIndicator = {
    marginBottom: "-50px",
    color: "#9d9b9b",
    fontSize: "100px",
};

const deleteButtonStyle = {
    position: "absolute",
    cursor: "pointer",
    alignSelf: "flex-end",
};

export default VideoUploader;

