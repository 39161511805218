import AppHeader from '../../../../basic/app-header/AppHeader';
import AppMenu from '../../../../basic/app-menu/AppMenu';
import AppFooter from '../../../../basic/app-footer/AppFooter';
import AppSetting from '../../../../basic/app-setting/AppSetting';
import axios from 'axios';

function CreateLegal(){

    // função para formatar o campo de cep (00000-000)
    function formatCep(e) {
        const campo = e.target;
        const valor = campo.value.replace(/\D/g, ""); // Remove tudo o que não é dígito
        let novoValor = "";

        if (valor.length <= 10) {
        novoValor = valor.replace(/(\d{5})(\d{3}).*/, "$1-$2");
        } 

        campo.value = novoValor;
    }

    // função para formatar o campo de cpf(000.000.000-00)
    function formatCpf(e) {
        const campo = e.target;
        const valor = campo.value.replace(/\D/g, ""); // Remove tudo o que não é dígito
    
        let novoValor = "";
    
        novoValor = valor.replace(/(\d{3})(\d{3})(\d{3})(\d{2}).*/, "$1.$2.$3-$4");
    
        campo.value = novoValor;
    }

    // função para formatar o campo de cnpj(00.000.000/0000-00)
    function formatCnpj(e) {
        const campo = e.target;
        const valor = campo.value.replace(/\D/g, ""); // Remove tudo o que não é dígito
    
        let novoValor = "";
    
        novoValor = valor.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2}).*/, "$1.$2.$3/$4-$5");
    
        campo.value = novoValor;
    }

    // * Requisição para API
    function createJuridical(){
        const acao = "create"
        const tipo = "juridica"
        const nome = document.querySelector('#nome').value
        const nacionalidade = document.querySelector('#nacionalidade').value
        const cnpj = document.querySelector('#cnpj').value
        const nire = document.querySelector('#nire').value
        const endereco = document.querySelector('#endereco').value
        const cep = document.querySelector('#cep').value
        const cidade = document.querySelector('#cidade').value
        const estado = document.querySelector('#estado').value
        const representante = document.querySelector('#representante').value
        const cpfrepre = document.querySelector('#cpfrepre').value
        const enderecorep = document.querySelector('#enderecorep').value 
        

        axios.post(`http://localhost:5000/api/actions/partners`, {acao, tipo, nome, nacionalidade, cnpj, nire, endereco, cep, cidade, estado, representante, cpfrepre, enderecorep}, {withCredentials: true}).then((resp)=>{
            
            if(resp.data == 400){
                alert("Essa pessoa jurídica/cnpj já está cadastrado!")
            }
            if(resp.data == 404){
                window.location = `/erro`
            }
            if(Array.isArray(resp.data.erros) && resp.data.status == 200){
                console.log('entra na condicao correta')
                
                if(resp.data.erros.length === 0){
                    console.log('entra no redirecionamento')
                    window.location = `/partners`
                } else {
                    for(let i=0; i<resp.data.erros.length; i++){
                        let elemento = document.querySelector(`#${resp.data.erros[i].elemento}`)
                        elemento.style.backgroundColor = '#FFC0CB'
                    }
                }
            }
            
        })
    }

    return(
        <>
        <AppHeader/>
        <AppMenu/>
        <div class="content-wrapper">
            <section class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-6">
                            <h1>Criando novo sócio</h1>
                        </div>
                        <div class="col-sm-6">
                            <ol class="breadcrumb float-sm-right">
                                <li class="breadcrumb-item"><a href="/">Início</a></li>
                                <li class="breadcrumb-item active">Criando sócio</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </section>

            <section class="content">
                <div class="container-fluid">
                    <div class="card card-default">
                        <div class="card-body">
                            <div class="legal">
                                <h5>Qualificação de sócios:</h5>
                                <div className="row">
                                    <div className='col-8'>
                                        <div class="form-group">
                                            <label class="required" for="nome">Nome completo:</label>
                                            <input type="text" name="nome" id="nome" class="form-control"/>
                                        </div>
                                    </div>
                                    <div className='col-4'>
                                        <div class="form-group">
                                            <label class="required" >Nacionalidade:</label>
                                            <input type="text" name="nacionalidade" id="nacionalidade" class="form-control"/>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-6'>
                                        <div class="form-group">
                                            <label className='required'>CNPJ:</label>
                                            <input type="text" name="cnpj" id="cnpj" class="form-control" onInput={formatCnpj}/>
                                        </div>
                                    </div>
                                    <div className='col-6'>
                                        <div class="form-group">
                                            <label className='required'>NIRE:</label>
                                            <input type="text" name="nire" id="nire" class="form-control"/>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-5'>
                                        <div class="form-group">
                                            <label class="required" >Endereço:</label>
                                            <input type="text" name="endereco" id="endereco" class="form-control"/>
                                        </div>
                                    </div>
                                    <div className='col-3'>
                                        <div class="form-group">
                                            <label class="required" >CEP:</label>
                                            <input type="text" name="cep" id="cep" class="form-control" onInput={formatCep}/>
                                        </div>
                                    </div>
                                    <div className='col-2'>
                                        <div class="form-group">
                                            <label class="required" >Cidade:</label>
                                            <input type="text" name="cidade" id="cidade" class="form-control"/>
                                        </div>
                                    </div>
                                    <div className='col-2'>
                                        <label class="required" >Estado:</label>
                                        <select className="form-control" id="estado" name="estado">
                                            <option></option>
                                            <option>AC</option>
                                            <option>AL</option>
                                            <option>AP</option>
                                            <option>AM</option>
                                            <option>BA</option>
                                            <option>CE</option>
                                            <option>DF</option>
                                            <option>ES</option>
                                            <option>GO</option>
                                            <option>MA</option>
                                            <option>MT</option>
                                            <option>MS</option>
                                            <option>MG</option>
                                            <option>PA</option>
                                            <option>PB</option>
                                            <option>PR</option>
                                            <option>PE</option>
                                            <option>PI</option>
                                            <option>RJ</option>
                                            <option>RN</option>
                                            <option>RS</option>
                                            <option>RO</option>
                                            <option>RR</option>
                                            <option>SC</option>
                                            <option>SP</option>
                                            <option>SE</option>
                                            <option>TO</option>
                                        </select>
                                    </div>
                                </div>      
                                <div className='row'>
                                    <div className='col-4'>
                                        <div class="form-group">
                                            <label className='required'>Representante:</label>
                                            <input type="text" name="representante" id="representante" class="form-control"/>
                                        </div>
                                    </div>
                                    <div className='col-3'>
                                        <div class="form-group">
                                            <label className='required'>CPF (representante):</label>
                                            <input type="text" name="cpfrepre" id="cpfrepre" class="form-control" onInput={formatCpf}/>
                                        </div>
                                    </div>
                                    <div className='col-5'>
                                        <div class="form-group">
                                            <label className='required'>Endereço (representante):</label>
                                            <input type="text" name="enderecorep" id="enderecorep" class="form-control"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 center-element">
                                    <a href="/partners"><button type="button" class="cancel btn btn-gray">Cancelar</button></a>
                                    <button type="submit" class="enviar btn btn-gray" style={{marginLeft: 5}} onClick={createJuridical}>Enviar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <AppFooter/>
        <AppSetting/>
        </>
    )
}

export default CreateLegal;