import AppHeader from '../../../basic/app-header/AppHeader';
import AdmMenu from '../adm-menu/AdmMenu';
import AppFooter from '../../../basic/app-footer/AppFooter';
import AppSetting from '../../../basic/app-setting/AppSetting';
import React, {useEffect, useState} from 'react';
import Select from 'react-select';
import OverviewReceipt from '../adm-dashboard/OverviewReceipt';
import { Box, Container, Unstable_Grid2 as Grid } from '@mui/material';
import { postAxios } from '../../../../hooks/useAxios';
import { formatCpfCnpj } from '../../../../utils/formatCpfCnpj';
import { formatPhone } from '../../../../utils/formatPhone';
import { formatNumeric } from '../../../../utils/formatNumeric';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from '@mui/material';


function CreateClient() {
    const options = [
        { value: 'SSJ-Cliente', name: 'SSJ-Cliente', label: 'SSJ-Cliente'},
        { value: 'BI', name: 'BI', label: 'BI'},
        { value: 'Portal-Pagamentos' , name: 'Portal-Pagamentos', label: 'Portal-Pagamentos' },
        { value: 'SSJ-ADM', name: 'SSJ-ADM', label: 'SSJ-ADM' },
        { value: 'Holding', name: 'Holding', label: 'Holding'}
    ];

    
    // armazenar os componentes que foram escolhidos no select
    // const [components, setComponents] = useState([]);
    const [companiesAdded, setCompaniesAdded] = useState([]);
    const [selectedComponents, setSelectedComponents] = useState([]);
    const [valueProducts, setValueProducts] = useState(null);
    const [isProcessing, setIsProcessing] = useState(false);
    
    // Move a chamada para setValueProducts para dentro de um useEffect
    // useEffect(() => {
    //     setValueProducts(totalValue(selectedComponents));
    // }, [selectedComponents]);

    // funcao para calcular o valor total para o cliente com base nos componentes selecionados
    // function totalValue(selectedComponents) {
    //     let value = 0;
    //     if (selectedComponents) {
    //         selectedComponents.forEach((selectedLabel) => {
    //             // Encontre o componente correspondente no array original pelo label
    //             const component = products.find((c) => c.label === selectedLabel);
    //             if (component) {
    //                 value += component.value;
    //             }
    //         });
    //     }
    //     return value;
    // }

    const handleCompanies = () => {
        const companyName = document.querySelector('#company').value;
        const newItem = { id: companiesAdded.length + 1, name: `${companyName}` };
        setCompaniesAdded((prevItems) => [...prevItems, newItem]);
    };
  
    const deleteCompany = (id) => {
        // Filtrar os itens com base no id para remover o item desejado
        const updatedItems = companiesAdded.filter((item) => item.id !== id);
        setCompaniesAdded(updatedItems);
    };
      

    const handleSelectionChange = (selectedOptions) => {
        setSelectedComponents(selectedOptions);
    };

    // * Funcoes de formatacao

    // funcao de formatacao para valor monetario
    function formatFloatToMoney(value) {
        const floatValue = parseFloat(value);
        if (isNaN(floatValue)) {
          return "R$ 0,00"; // Caso o valor não seja um número válido, retorna R$ 0,00
        }
      
        const formattedValue = floatValue.toFixed(2).replace(".", ",");
        return `R$ ${formattedValue.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")}`;
    }

    // * Requisição para API
    function createClient(){
        if (isProcessing) return; // Impede cliques adicionais enquanto já está em andamento
        setIsProcessing(true);
        const corporate = document.querySelector('#name').value;
        const email = document.querySelector('#email').value;
        const cpfCnpj = document.querySelector('#cpfCnpj').value;
        const contactOne = document.querySelector('#contactOne').value;
        const contactTwo = document.querySelector('#contactTwo').value;
        const numberUsers = document.querySelector('#numberUsers').value;
        const bi = document.querySelector('#bi').value;
        const driveTrabalhista = document.querySelector('#driveTrabalhista').value;
        const driveEmpresarial = document.querySelector('#driveEmpresarial').value;
        const products = selectedComponents;
        const companies = companiesAdded;
        // const totalValue = valueProducts;
        console.log('antes do axios')
        
        postAxios(`/api/create/client`, {corporate, email, cpfCnpj, contactOne, contactTwo, numberUsers, products, bi, driveTrabalhista, driveEmpresarial, companies})
            
            .then((resp) => {
                console.log(resp.data.errors)
                if(resp.data.errors){
                    for(let i=0; i<resp.data.errors.length; i++){
                        let elemento = document.querySelector(`#${resp.data.errors[i].elemento}`);
                        console.log(resp.data.errors[i].elemento)
                        console.log(elemento)
                        if(resp.data.errors[i].elemento !== 'totalValue'){
                            if(resp.data.errors[i].elemento === 'products') {
                                elemento.classList.add('erro-select');
                                const placeholder = elemento.querySelector('#react-select-3-placeholder');
                                placeholder.innerHTML = "Preencha esse campo";
                            }
                            else {
                                elemento.placeholder = "Preencha esse campo";
                                elemento.classList.add('erro');
                            }
                            if(resp.data.errors[i].mensagem === 'Este CPF/CNPJ já está em uso!') {
                                alert('Este CPF/CNPJ já está em uso!');
                            }
                        }
                    }
                    return;
                }
                window.location = `/adm/clients`
            })
            .catch((error) => {
                window.location.href = "/bad/request";
                console.error('Erro na solicitação POST:', error);
            })
            .finally(() => {
                setIsProcessing(false); // Habilita o botão novamente após a conclusão ou erro
            })
    }

    return (
        <>
        <AppHeader />
        <AdmMenu />
        <div className="content-wrapper">
            {/* Content Header (Page header) */}
            <section className="content-header">
                <div className="container-fluid">
                <div className="row mb-2">
                    <div className="col-sm-6">
                    <h1>Criando novo cliente</h1>
                    </div>
                    <div className="col-sm-6">
                    <ol className="breadcrumb float-sm-right">
                        <li className="breadcrumb-item"><a href="/adm">Início</a></li>
                        <li className="breadcrumb-item"><a href="/adm/clients">Clientes</a></li>
                        <li className="breadcrumb-item active">Criar cliente</li>
                    </ol>
                    </div>
                </div>
                </div>
            </section>

            {/* Main content */}
            <section class="content">
                <div class="container-fluid">
                    <div class="card card-default">
                        <div class="card-body">
                            <div className="row">
                                <div className='col-6'>
                                    <div class="form-group">
                                        <label class="required" for="name">Razão Social:</label>
                                        <input type="text" name="name" id="name" class="form-control"/>
                                    </div>
                                </div>
                                <div className='col-6'>
                                    <div class="form-group">
                                        <label class="required" for="cpfCnpj">CPF/CNPJ:</label>
                                        <input type="text" name="cpfCnpj" id="cpfCnpj" class="form-control" onInput={formatCpfCnpj}/>
                                    </div>
                                </div>
                            </div>
                            <div className='row form-group'>
                                <div className='col-4'>
                                    <div class="form-group">
                                        <label class="required" for="email">E-mail:</label>
                                        <input type="email" name="email" id="email" class="form-control"/>
                                    </div>
                                </div>
                                <div className='col-4'>
                                    <div class="form-group">
                                        <label for="contactOne">Contato 1:</label>
                                        <input type="text" name="contactOne" id="contactOne" class="form-control" onInput={formatPhone}/>
                                    </div>
                                </div>
                                <div className='col-4'>
                                    <div class="form-group">
                                        <label for="contactTwo">Contato 2:</label>
                                        <input type="text" name="contactTwo" id="contactTwo" class="form-control" onInput={formatPhone}/>
                                    </div>
                                </div>
                            </div>
                            <div className='input-grid-client'>
                                <div className='c0'>
                                    <div class="form-group" >
                                        <label class="required" for="products">Produto(s):</label>
                                        {/* <Select
                                            options={options}
                                            isMulti={true}
                                            placeholder=''
                                            id="products"
                                            onChange={(selectedOptions) => {
                                                // Atualize o estado dos componentes selecionados para conter apenas labels
                                                const selectedLabels = selectedOptions.map((option) => option.label);
                                                setSelectedComponents(selectedLabels);
                                                console.log(components);
                                                setOptions(components2);
                                            }}
                                            isClearable={true}
                                        /> */}
                                       <Select
                                            isMulti
                                            placeholder=''
                                            options={options}
                                            value={selectedComponents}
                                            onChange={handleSelectionChange}
                                        />

                                    </div>
                                </div>
                                <div className='c1'>
                                    <div class="form-group">
                                        <label class="required" for="numberUsers">Quantidade de usuários:</label>
                                        <input type="number" name="numberUsers" id="numberUsers" class="form-control" onInput={formatNumeric}/>
                                    </div>
                                </div>
                                {/* <div className="col-4">
                                    <div className="form-group custom-center">
                                        <OverviewReceipt
                                        sx={{ borderRadius: '15px', boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2)' }}
                                        difference={4}
                                        width={4}
                                        heigth={1}
                                        fontSize={'h4'}
                                        positive
                                        value={formatFloatToMoney(totalValue(selectedComponents))}
                                        />
                                    </div>
                                </div> */}
                                <div className='c2 form-group' style={{marginLeft: 5}}>
                                        <div className='row'>
                                            <label className='required' htmlFor='company'>Empresa:</label>
                                        </div>
                                        <div className='row'>
                                            <div style={{width: '70%'}}>
                                                <input type="text" name="company" id="company" class="form-control"/>
                                            </div>
                                            <div style={{width: '25%', marginLeft: 5}}>
                                            <button class='btn btn-gray btn-block add' onClick={()=>{handleCompanies()}}><i class="fas fa-plus"/></button>
                                            </div>
                                        </div>
                                </div>
                                <div className='c6' style={{width: '300%', height: '115%', marginTop: -15}}>
                                    <div className='row' style={{marginLeft: 5}}>
                                       <h5 className='companies-added'>Empresas adicionadas:</h5>
                                    </div>
                                    <div className='row file-input-preview col-4' style={{marginLeft: 5}}>
                                    <ul>
                                        {companiesAdded && companiesAdded.map((option) => (
                                        <>
                                        <div className='row company-item'>
                                            <p>{option.name}</p>
                                            <div style={{marginLeft: 15, marginTop: -12}}>
                                                <IconButton
                                                    color="error"
                                                    aria-label="remove"
                                                    onClick={()=>{deleteCompany(option.id)}}
                                                    className="remove-button"
                                                    >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </div>
                                        </div>
                                        <p className='separator' style={{marginTop: -15}}/>
                                        </>
                                        ))}
                                    </ul>
                                    </div>
                                </div>


                                <div className='c3'>
                                    <div class="form-group">
                                        <label for='bi' className='required'>Link para integração do BI:</label>
                                        <input type="text" className='form-control' name='bi' id='bi'/>
                                    </div>
                                </div>
                                <div className='c4'>
                                    <div className='form-group'>
                                        <label for="driveTrabalhista" className='required'>Link para pasta trabalhista no drive:</label>
                                        <input type='text' className='form-control' name='driveTrabalhista' id='driveTrabalhista'/>
                                    </div>
                                </div>
                                <div className='c5'>
                                    <div className='form-group'>
                                        <label for="driveEmpresarial" className='required'>Link para pasta empresarial no drive:</label>
                                        <input type='text' className='form-control' name='driveEmpresarial' id='driveEmpresarial'/>
                                    </div>
                                </div>                   
                            </div>
                            <p  className="separator" style={{marginTop: 30}}/>
                            <div class="col-12 center-element">
                                <a href="/adm/clients"><button type="button" class="cancel btn btn-gray">Cancelar</button></a>
                                <button type="submit" class="enviar btn btn-gray" style={{marginLeft: 5}} onClick={createClient}>
                                  {isProcessing ? 'Aguarde...' : 'Enviar'}
                                </button>
                            </div>
                        </div> 
                    </div>
                </div>
            </section>
        {/* /.content */}
        </div>
        <AppFooter />
        <AppSetting />
        </>  
    );
}

export default CreateClient;
