import AppHeader from '../../../basic/app-header/AppHeader';
import AppMenu from '../../../basic/app-menu/AppMenu';
import AppFooter from '../../../basic/app-footer/AppFooter';
import AppSetting from '../../../basic/app-setting/AppSetting';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { Modal } from 'react-bootstrap';

// TODO: arrumar estilização nome/razão social
// TODO: fazer modal para visualização do sócio

function ViewPartners(){

    // * Variáveis de estado

    const [socios, setSocios] = useState(null);
    // considerando que no bd os bens terão um idTipo, que será de 1 e 2, indicando, respectivamente, pessoa física e pessoa jurídica
    const [idTipo, setIdTipo] = useState(0)
    // auxiliar para realizar a abertura do modal
    const [showModal, setShowModal] = useState(false);
    // armazenar as informações pré-carregadas do sócio que será visualizado
    const [olharSocio, setOlharSocio] = useState({
        nome: null, 
        nacionalidade: null, 
        nascimento: null, 
        cpf: null, 
        rg: null, 
        email: null, 
        profissao: null, 
        estado_civil: null, 
        regime: null, 
        endereco: null, 
        cep: null, 
        cidade: null, 
        estado: null, 
        cnpj: null, 
        nire: null, 
        representante: null, 
        cpfrepre: null, 
        endereco_representante: null
    })

    // * Requisições

    useEffect(() => {
        const getPartners = async () => {
          try {
            const response = await axios.post('http://localhost:5000/api/get/partners', { withCredentials: true });
            if (response.data.status === 200) {
              setSocios(response.data.partners);
            } else {
              window.location.href = "http://localhost:3000/bad/request";
            }
          } catch (error) {
            console.error(error);
            window.location.href = "http://localhost:3000/bad/request";
          }
        };
    
        getPartners();
    }, []);

    // * Spinner

    if (socios === null) {
        return(
            <>
            <AppHeader/>
            <AppMenu/>
            <div className='content-wrapper'>
                <div className='card-body'>
                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100vh"
                    }}>
                        <div class="spinner-border" role="status">
                        <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>
            </div>
            </>
        ); 
      }

    // * Função auxiliar da tabela
    
    // remover uma linha da tabela (sócio)
    async function deletePartner(id){
        try {
            const acao = "delete";
            await axios.post('http://localhost:5000/api/actions/partners', {acao, id}, {withCredentials: true}).then((resp)=>{
                if(resp.data.status == 200){
                    window.location.href = "http://localhost:3000/partners"
                }
            })
        } catch (error) {
            console.log(error);
            window.location.href = "http://localhost:3000/bad/request";
        }
        
    }

    // função para formatar o campo de cep (00000-000)
    function formatCep(e) {
        const campo = e.target;
        const valor = campo.value.replace(/\D/g, ""); // Remove tudo o que não é dígito
        let novoValor = "";

        if (valor.length <= 10) {
        novoValor = valor.replace(/(\d{5})(\d{3}).*/, "$1-$2");
        } 

        campo.value = novoValor;
    }

    // função para formatar o campo de cpf(000.000.000-00)
    function formatCpf(e) {
        const campo = e.target;
        const valor = campo.value.replace(/\D/g, ""); // Remove tudo o que não é dígito
    
        let novoValor = "";
    
        novoValor = valor.replace(/(\d{3})(\d{3})(\d{3})(\d{2}).*/, "$1.$2.$3-$4");
    
        campo.value = novoValor;
    }

    // * Funções de formatação

    // função para formatar o campo de cnpj(00.000.000/0000-00)
    function formatCnpj(e) {
        const campo = e.target;
        const valor = campo.value.replace(/\D/g, ""); // Remove tudo o que não é dígito
    
        let novoValor = "";
    
        novoValor = valor.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2}).*/, "$1.$2.$3/$4-$5");
    
        campo.value = novoValor;
    }

    // função para formatar o campo de RG(00.000.000-0)
    function formatRG(e) {
        const campo = e.target;
        const valor = campo.value.replace(/\D/g, ""); // Remove tudo o que não é dígito
    
        let novoValor = "";
    
        novoValor = valor.replace(/(\d{2})(\d{3})(\d{3})(\d{1}).*/, "$1.$2.$3-$4");
    
        campo.value = novoValor;
    }

    // * Fuções para modal de visualização do bem

    const handleCloseModal = () => {
        setShowModal(false);
      };
    
      const handleShowModal = () => {
        setShowModal(true);
    };

    async function searchPartner(id) {
        let left = 0;
        let right = socios.length - 1;

        while(left <= right) {
            const mid = Math.floor((left + right) / 2);
            const midValue = socios[mid].id;

            if(midValue == id) {
                setOlharSocio(socios[mid]);
                if(socios[mid].cpf) {
                    setIdTipo(1);
                }
                else if(socios[mid].cnpj) {
                    setIdTipo(2);
                }
                break;
            }
            else if(midValue < id) {
                left = mid + 1;
            }
            else {
                right = mid - 1;
            }
        }
    }

    // * Função auxiliar para visualização do sócio
    function returnSocio(){
        // pessoa física
        if(idTipo == 1){
            return(
                <>
                    <div className="row">
                        <div className='col-8'>
                            <div className="form-group">
                                <label className="required" for="nome">Nome completo:</label>
                                <input type="text" name="nome" id="nome" className="form-control" value={olharSocio.nome} disabled={true}/>
                            </div>
                        </div>
                        <div className='col-4'>
                            <div class="form-group">
                                <label class="required" >Nacionalidade:</label>
                                <input type="text" name="nacionalidade" id="nacionalidade" class="form-control" value={olharSocio.nacionalidade} disabled={true}/>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-4'>
                            <div class="form-group">
                                <label class="required" for="birthday">Data de nascimento:</label>
                                <input class="form-control" type="date" id="nascimento" name="nascimento" value={olharSocio.nascimento} disabled={true}/>
                            </div>
                        </div>
                        <div className='col-4'>
                            <div class="form-group">
                                <label class="required" >CPF:</label>
                                <input type="text" name="cpf" id="cpf" class="form-control" value={olharSocio.cpf} disabled={true}/>
                            </div>
                        </div>
                        <div className='col-4'>
                            <div class="form-group">
                                <label class="required" >RG:</label>
                                <input type="text" name="rg" id="rg" class="form-control" value={olharSocio.rg} disabled={true}/>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-6'>
                            <div class="form-group">
                                <label class="required" >E-mail:</label>
                                <input type="email" name="email" id="email" class="form-control" value={olharSocio.email} disabled={true}/>
                            </div>
                        </div>
                        <div className='col-6'>
                            <div class="form-group">
                                <label class="required" >Profissão:</label>
                                <input type="text" name="profissao" id="profissao" class="form-control" value={olharSocio.profissao} disabled={true}/>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-7'>
                            <div class="form-group">
                                <label class="required" >Estado civil:</label>
                                <div class="form-group">
                                    <div id="custom-radio">
                                        <input type="radio" name="estadocivil" id="casado" value="casado" checked={olharSocio.estado_civil === 'casado'} disabled={true}/>
                                        <label style={{marginLeft: 2}} for="casado">Casado(a)</label>

                                        <input type="radio" name="estadocivil" id="solteiro" value="solteiro" checked={olharSocio.estado_civil === 'solteiro'} disabled={true}/>
                                        <label for="solteiro">Solteiro(a)</label>

                                        <input type="radio" name="estadocivil" id="divorcio" value="divorcio" checked={olharSocio.estado_civil === 'divorcio'} disabled={true}/>
                                        <label for="divorcio">Divorcio(a)</label>

                                        <input type="radio" name="estadocivil" id="uniao" value="uniao" checked={olharSocio.estado_civil === 'uniao'} disabled={true}/>
                                        <label for="uniao">União estável</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-5'>
                            <div class="form-group">
                                <label class="required" >Regime de casamento:</label>
                                <input type="text" name="regime" id="regime" class="form-control" value={olharSocio.regime} disabled={true}/>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-5'>
                            <div class="form-group">
                                <label class="required" >Endereço:</label>
                                <input type="text" name="endereco" id="endereco" class="form-control" value={olharSocio.endereco} disabled={true}/>
                            </div>
                        </div>
                        <div className='col-3'>
                            <div class="form-group">
                                <label class="required" >CEP:</label>
                                <input type="text" name="cep" id="cep" class="form-control" value={olharSocio.cep} disabled={true}/>
                            </div>
                        </div>
                        <div className='col-2'>
                            <div class="form-group">
                                <label class="required" >Cidade:</label>
                                <input type="text" name="cidade" id="cidade" class="form-control" value={olharSocio.cidade} disabled={true}/>
                            </div>
                        </div>
                        <div className='col-2'>
                            <label class="required" >Estado:</label>
                            <select className="form-control" id="estado" name="estado" value={olharSocio.estado} disabled={true}>
                            <option></option>
                                <option>AC</option>
                                <option>AL</option>
                                <option>AP</option>
                                <option>AM</option>
                                <option>BA</option>
                                <option>CE</option>
                                <option>DF</option>
                                <option>ES</option>
                                <option>GO</option>
                                <option>MA</option>
                                <option>MT</option>
                                <option>MS</option>
                                <option>MG</option>
                                <option>PA</option>
                                <option>PB</option>
                                <option>PR</option>
                                <option>PE</option>
                                <option>PI</option>
                                <option>RJ</option>
                                <option>RN</option>
                                <option>RS</option>
                                <option>RO</option>
                                <option>RR</option>
                                <option>SC</option>
                                <option>SP</option>
                                <option>SE</option>
                                <option>TO</option>
                            </select>
                        </div>
                    </div>
                </>     
            );  
        // pessoa jurídica
        } else if(idTipo == 2){
            return(
                <>
                <div className="row">
                    <div className='col-8'>
                        <div class="form-group">
                            <label class="required" for="nome">Nome completo:</label>
                            <input type="text" name="nome" id="nome" class="form-control" value={olharSocio.nome} disabled={true}/>
                        </div>
                    </div>
                    <div className='col-4'>
                        <div class="form-group">
                            <label class="required" >Nacionalidade:</label>
                            <input type="text" name="nacionalidade" id="nacionalidade" class="form-control" value={olharSocio.nacionalidade} disabled={true}/>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-6'>
                        <div class="form-group">
                            <label className='required'>CNPJ:</label>
                            <input type="text" name="cnpj" id="cnpj" class="form-control" value={olharSocio.cnpj} disabled={true}/>
                        </div>
                    </div>
                    <div className='col-6'>
                        <div class="form-group">
                            <label className='required'>NIRE:</label>
                            <input type="text" name="nire" id="nire" class="form-control" value={olharSocio.nire} disabled={true}/>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-5'>
                        <div class="form-group">
                            <label class="required" >Endereço:</label>
                            <input type="text" name="endereco" id="endereco" class="form-control" value={olharSocio.endereco} disabled={true}/>
                        </div>
                    </div>
                    <div className='col-3'>
                        <div class="form-group">
                            <label class="required" >CEP:</label>
                            <input type="text" name="cep" id="cep" class="form-control" value={olharSocio.cep} disabled={true}/>
                        </div>
                    </div>
                    <div className='col-2'>
                        <div class="form-group">
                            <label class="required" >Cidade:</label>
                            <input type="text" name="cidade" id="cidade" class="form-control" value={olharSocio.cidade} disabled={true}/>
                        </div>
                    </div>
                    <div className='col-2'>
                        <label class="required" >Estado:</label>
                        <select className="form-control" id="estado" name="estado" value={olharSocio.estado} disabled={true}>
                        <option></option>
                            <option>AC</option>
                            <option>AL</option>
                            <option>AP</option>
                            <option>AM</option>
                            <option>BA</option>
                            <option>CE</option>
                            <option>DF</option>
                            <option>ES</option>
                            <option>GO</option>
                            <option>MA</option>
                            <option>MT</option>
                            <option>MS</option>
                            <option>MG</option>
                            <option>PA</option>
                            <option>PB</option>
                            <option>PR</option>
                            <option>PE</option>
                            <option>PI</option>
                            <option>RJ</option>
                            <option>RN</option>
                            <option>RS</option>
                            <option>RO</option>
                            <option>RR</option>
                            <option>SC</option>
                            <option>SP</option>
                            <option>SE</option>
                            <option>TO</option>
                        </select>
                    </div>
                </div>      
                <div className='row'>
                    <div className='col-4'>
                        <div class="form-group">
                            <label className='required'>Representante:</label>
                            <input type="text" name="representante" id="representante" class="form-control" value={olharSocio.representante} disabled={true}/>
                        </div>
                    </div>
                    <div className='col-3'>
                        <div class="form-group">
                            <label className='required'>CPF (representante):</label>
                            <input type="text" name="cpfrepre" id="cpfrepre" class="form-control" value={olharSocio.cpfrepre} disabled={true}/>
                        </div>
                    </div>
                    <div className='col-5'>
                        <div class="form-group">
                            <label className='required'>Endereço (representante):</label>
                            <input type="text" name="enderecorep" id="enderecorep" class="form-control" value={olharSocio.endereco_representante} disabled={true}/>
                        </div>
                    </div>
                </div>
                </>
            );
        }
    }
  
    function createModal() {
      return (
  
        <Modal show={showModal} onHide={handleCloseModal} size="lg">
           <Modal.Header className='bg-gray center-element'>
            <Modal.Title>Visualizando o sócio: {olharSocio != null ? olharSocio.nome : null}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {returnSocio()}
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-gray" onClick={handleCloseModal}>Voltar</button>
          </Modal.Footer>
          </Modal>
        )
        
    }

    return(
        <>
        <AppHeader/>
        <AppMenu/>
        {/* <!-- Content Wrapper. Contains page content --> */}
        <div class="content-wrapper">
            {/* <!-- Content Header (Page header) --> */}
            <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                <div class="col-sm-6">
                    <h1></h1>
                </div>
                <div class="col-sm-6">
                    <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="/">Início</a></li>
                    <li class="breadcrumb-item active">Pessoas Físicas</li>
                    </ol>
                </div>
                </div>
            </div>
            {/* <!-- /.container-fluid --> */}
            </section>

            {/* <!-- Main content --> */}
            <section class="content">
            <div class="container-fluid">
                <div class="row">
                <div class="col-12">
                    <div class="card">
                    <div class="card-header">
                        <h3 class="card-title">Sócios</h3>
                    </div>
                    <div class="card-header">
                        <a href="/newPersona/physical"><button class="btn btn-sm btn-success">Nova pessoa física</button></a>
                        <a id='new-jur-button' href="/newPersona/legal"><button class="btn btn-sm btn-success">Novo pessoa jurídica</button></a>
                    </div>
                    {/* <!-- /.card-header --> */}
                    <div class="card-body">
                        <table id="example1" class="table table-bordered table-striped dataTable dtr-inline">
                        <thead>
                            <tr>
                                <th>
                                    
                                    Nome
                                    <br/>
                                    Razão Social 
                                    
                                </th>
                                <th>Tipo</th>
                                <th>CPF/CNPJ</th>
                                <th>E-mail/Representante</th>
                                <th className='coluna-visualizar'>Ações</th>
                            </tr>
                        </thead>
                        <tbody id="tabela-corpo">
                        {
                            socios && socios.map(resp=> (
                                <tr>
                                    <td><a>{resp.nome}</a></td>
                                    <td><a>{resp.cpf ? "Física" : "Jurídica"}</a></td>
                                    <td><a>{resp.cpf || resp.cnpj}</a></td>
                                    <td style={{maxWidth: "55px"}}><a>{resp.email || resp.representante}</a></td>
                                    <td classList="action-column" className='center-element coluna-visualizar'>
                                        <button className='btn btn-gray eye-animation' style={{marginRight: 5}} onClick={()=>{searchPartner(resp.id); handleShowModal()}}><i class='fa fa-eye icon-white'></i></button>
                                        <a href={`/edit/partner/${resp.id}`}><button className='btn btn-primary pen-animation'><i class='fa fa-pen icon-white'></i></button></a>
                                        <button className='btn btn-danger trash-animation' style={{marginLeft: 5}} onClick={()=>{deletePartner(resp.id)}}><i class='fa fa-trash'></i></button>
                                    </td>
                                </tr>
                            ))
                        }
                        </tbody>
                        </table>
                        <div className='row'>
                            <div class="col-12 text-center">
                                {createModal()}
                            </div>
                        </div>
                    </div>
                    {/* <!-- /.card-body --> */}
                    </div>
                    {/* <!-- /.card --> */}
                </div>
                {/* <!-- /.col --> */}
                </div>
                {/* <!-- /.row --> */}
            </div>
            {/* <!-- /.container-fluid --> */}
            </section>
            {/* <!-- /.content --> */}
        </div>
        {/* <!-- /.content-wrapper --> */}
        <AppFooter/>
        <AppSetting/>
        </>
    )
}

export default ViewPartners;