import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types'
import { Modal } from 'react-bootstrap';
import { Avatar, IconButton } from '@mui/material';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';

ViewUser.prototypes = {
    showModal: PropTypes.bool,
    handleCloseModal: PropTypes.func,
    user: PropTypes.object,
    client: PropTypes.object
  };

function ViewUser(props) {
    const {showModal, handleCloseModal, user, client} = props;
    function createModal() {
        return (
        <Modal show={showModal} onHide={handleCloseModal} size="xl">
            <Modal.Header className='bg-gray center-element'>
            <Modal.Title>Informações do usuário {user != null ? user.id : null}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <div class="form-group">  
                <div className='input-grid form-group'>
                    <div className='campo1'>
                        <label htmlFor="profileImage" style={{marginLeft: 40}}>Foto de Perfil:</label>
                        <div
                            className=' form-group image-input-preview'
                            onDragOver={(e) => e.preventDefault()}
                        >
                            <div className="circle-placeholder">
                                <div className="image-container">
                                    {user.image ? (
                                        <div>
                                            <Avatar alt="Profile Image" src={URL.createObjectURL(user.image)} sx={{ width: 175, height: 175 }} />
                                        </div>
                                    ) : (
                                        <div className="input-indicator">                                                    
                                            <PhotoCameraIcon />
                                        </div>
                                    )}
                                </div>
                                <input
                                    id="imageInput"
                                    type="file"
                                    accept="image/*"
                                    style={{ display: 'none' }}
                                    disabled={true}
                                />
                            </div>
                        </div>
                    </div>
                    <div class="campo2">
                        <label class="required" for="username">Nome do usuário administrador:</label>
                        <input type="text" name="username" id="username" class="form-control" value={user.name || ''} disabled={true} style={{cursor: 'not-allowed'}}/>
                    </div> 
                    <div class="campo3">
                        <label class="required" >CPF:</label>
                        <input type="text" name="cpf" id="cpf" class="form-control" value={user.cpf || ''} disabled={true} style={{cursor: 'not-allowed'}}/>
                    </div>
                    <div class="campo4">
                        <label class="required" >E-mail:</label>
                        <input type="email" name="email" id="email" class="form-control" value={user.email || ''} disabled={true} style={{cursor: 'not-allowed'}}/>
                    </div>
                    <div class="campo5">
                        <label class="required" >Cliente (Empresa):</label>
                        <input name="client" id="client" class="form-control" value={client} disabled={true} style={{cursor: 'not-allowed'}}></input>
                    </div>
                    <div class="campo6">
                        <label class="required" >Senha:</label>
                        <input type="text" name="password" id="password" class="form-control" value={user.password || ''} disabled={true} style={{cursor: 'not-allowed'}}/>
                    </div>
                </div>
            </div> 
            </Modal.Body>
            <Modal.Footer>
            <button className="btn btn-gray" onClick={handleCloseModal}>Voltar</button>
            </Modal.Footer>
            </Modal>
        )
    }

    return (
        <>
        <div className='row'>
            <div class="col-12 text-center">
            {createModal()}
            </div>
        </div>
        </>
    );
}

export default ViewUser;
