import AppHeader from "../../../basic/app-header/AppHeader";
import AdmMenu from "../adm-menu/AdmMenu";
import AppFooter from "../../../basic/app-footer/AppFooter";
import AppSetting from "../../../basic/app-setting/AppSetting";
import AdmDashboard from "../adm-dashboard/AdmDashboard";
import { postAxios } from "../../../../hooks/useAxios";
import { useState, useEffect } from "react";

function HomeAdm() {

    const [auth, setAuth] = useState(false);

useEffect(() => {

	const getUser = async () => {
      	 	postAxios('/api/load/clients', null, null, {withCredentials: true})
        	.then((resp) => {
			console.log(resp);
			setAuth(resp.data.authenticate);
        	})
        	.catch((error) => {
            		window.location.href = "/adm/login";
            		console.error('Erro na solicitação', error);
        	})
    	}
	getUser();
}, []);

if(auth === null || !auth) {
    return <div style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh"
    }}>
    <div class="spinner-border" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>;
  }

    return(
        <>
        <AppHeader/>
        <AdmMenu/>
        <AdmDashboard/>
        <AppFooter/>
        <AppSetting/>
        </>
    );
}

export default HomeAdm;
