import AppHeader from '../app-header/AppHeader';
import AppMenu from '../app-menu/AppMenu';
import AppFooter from '../app-footer/AppFooter';
import AppSetting from '../app-setting/AppSetting';
import AppDashboard from '../app-dashboard/AppDashboard';
import { postAxios } from '../../../hooks/useAxios';
import { useState, useEffect } from 'react';

function Home() {
  
  const [user, setUser] = useState(false);

  useEffect(() => {	
  const getUser = async () => {
        postAxios('/api/components/clients', null, null, {withCredentials: true})
        .then((resp) => {
            setUser(resp.data.user);
        })
        .catch((error) => {
	    alert('Você está deslogado ou sua sessão expirou! Faça login novamente!');
            window.location = "/login";
            console.error('Erro na solicitação', error);
        })
    }
  getUser();
  },[]);

  if (user === null || !user) {
     return <div style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh"
     }}>
       <div class="spinner-border" role="status">
         <span class="sr-only">Loading...</span>
       </div>
     </div>;
   }
  
  return (
    <>
      <AppHeader />
      <AppMenu />
      <AppDashboard />
      <AppFooter />
      <AppSetting />
    </>  
  );
}

export default Home;
