import Card from "../../basic/app-dashboard/Card";

function CardBi() {
    return(
        <Card
            href="/bi"
            image="/dist/img/bi2.png"
            title="Business Intelligence (BI)"
            description="Visualize e estude os principais dados de sua empresa"
        />
    );
}

export default CardBi;