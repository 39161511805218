import { Link } from 'react-router-dom';

function ItemMenuBi() {
    return(
        <>
        <li className="nav-item">
              <Link to="/bi" className="nav-link">
                <i className="nav-icon fas fa-chart-bar"></i>
                <p>
                  BI
                </p>
              </Link>
            </li>
        </>
    );
}

export default ItemMenuBi;
