import { Link } from 'react-router-dom';

function ItemMenuTranscription() {
    return(
        <>
        <li className="nav-item has-treeview menu-open">
            <Link className="nav-link">
              <i className="nav-icon fas fa-video"></i>
              <p>
                Transcrição de vídeos
                <i className="right fas fa-angle-left"></i>
              </p>
            </Link>
            <ul className="nav nav-treeview">
            <li className="nav-item subitem">
                <Link to="/transcription" className="nav-link">
                <i className="fa fa-play nav-icon"></i>
                <p>Enviar o vídeo</p>
                </Link>
            </li>
            <li className="nav-item subitem">
                <Link to="/transcription/history" className="nav-link">
                <i className="fa fa-folder-open nav-icon"></i>
                <p>Histórico de transcrições</p>
                </Link>
            </li>
            </ul>
        </li>
        </>
    );
}

export default ItemMenuTranscription;
