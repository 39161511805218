function ItemMenuHolding() {
    return(
        <>
        <li className="nav-item has-treeview">
            <a className="nav-link">
            <i className="nav-icon fa fa-building"></i>
            <p>
                Holding
                <i className="right fas fa-angle-left"></i>
            </p>
            </a>
            <ul className="nav nav-treeview">
            <li className="nav-item subitem">
                <a href="/partners" className="nav-link">
                <i className="fa fa-user-friends nav-icon text-white"></i>
                <p>Sócios</p>
                </a>
            </li>
            <li className="nav-item subitem">
                <a href="/assets/view-assets" className="nav-link">
                <i className="fas fa-dollar-sign nav-icon text-white"></i>
                <p>Bens | Vínculo societário</p>
                </a>
            </li>
            <li className="nav-item subitem">
                <a href="/projects" className="nav-link">
                <i className="fa fa-pen nav-icon text-white"></i>
                <p>Projetos</p>
                </a>
            </li>
            <li className="nav-item subitem">
                <a href="/holding/dashboard" className="nav-link">
                <i className="fa fa-chart-pie nav-icon"></i>
                <p>Dashboard</p>
                </a>
            </li>
            </ul>
        </li>
        </>
    );
}

export default ItemMenuHolding;