import AppHeader from '../../../basic/app-header/AppHeader';
import AppMenu from '../../../basic/app-menu/AppMenu';
import AppFooter from '../../../basic/app-footer/AppFooter';
import AppSetting from '../../../basic/app-setting/AppSetting';
import React, { useState} from 'react';
import { Modal } from 'react-bootstrap';

function CreateProject(){
  const [step, setStep] = useState(1);
  const [form1Data, setForm1Data] = useState({
      // Campos específicos do formulário da primeira tab
  });
  const [socioSelecionado, setSocioSelecionado] = useState("");
  const [percentual, setPercentual] = useState("");
  const [projeto, setProjeto] = useState([]);
  const [bensSelecionado, setBensSelecionado] = useState("");
  const [bensSelecionadoTexto, setBensSelecionadoTexto] = useState("");
  const [visibility, setVisibility] = useState(false);
  const [exibirModal, setExibirModal] = useState(false);

  const nextStep = () => {
    setStep((prevStep) => prevStep + 1);
  };
  
  const prevStep = () => {
    setStep((prevStep) => prevStep - 1);
  };

  const handleForm1Change = (e) => {
    const { name, value } = e.target;
    setForm1Data((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    // Lógica para enviar os dados do formulário
    // Aqui você pode fazer uma chamada de API ou executar qualquer outra ação
    console.log({
      form1Data
    });
  };

  // Função para formatar o campo de porcentagem
  function formatPorcentagem(e) {
    const campo = e.target; // Obtém o campo de entrada como alvo do evento
    let valor = campo.value; // Obtém o valor atual do campo
    // Remove todos os caracteres, exceto os dígitos e o símbolo de "%"
    valor = valor.replace(/[^\d%]/g, '');
    if (valor !== '') { // Verifica se o valor não está vazio
      if (valor !== '%') { // Verifica se o valor não é apenas o símbolo "%"
        // Remove o símbolo "%" se ele não estiver no final do valor
        if (valor.indexOf('%') !== valor.length - 1) {
          valor = valor.replace(/%/g, '');
        }
        // Limita o valor entre 0 e 100
        valor = Math.max(0, Math.min(parseInt(valor, 10), 100));
        valor += '%'; // Adiciona o símbolo "%" no final do valor formatado
      }
    }
    campo.value = valor; // Atualiza o valor do campo
    // Define a posição do cursor antes do símbolo "%"
    campo.selectionStart = campo.value.length - 1;
    campo.selectionEnd = campo.value.length - 1;
  }


  // Função chamada ao clicar no botão "Adicionar"
  const handleAdicionarClick = () => {
    if(socioSelecionado == null ||percentual == null||bensSelecionado == null){
      return null;
    }
    const adicionarNovaLinha = (projeto, novaLinha) => {
      const linhaExistenteIndex = projeto.findIndex((linha) => linha.socio === novaLinha.socio);

      if (linhaExistenteIndex !== -1) {
        const linhaExistente = projeto[linhaExistenteIndex];
        const novoProjeto = [...projeto];
        const valorTotalSocio = [...linhaExistente.valor]
        let somaValores = valorTotalSocio.reduce((acumulador, valor) => acumulador + Number(valor), 0);
        somaValores = somaValores+ parseInt(novaLinha.valor)
        console.log('=================')
        console.log(somaValores)
        console.log(novaLinha.participacao)
        console.log('=================')
        novoProjeto[linhaExistenteIndex] = {
          ...linhaExistente,
          bens: [...linhaExistente.bens, ...novaLinha.bens],
          percentual: [...linhaExistente.percentual, ...novaLinha.percentual],
          valor: [...linhaExistente.valor, ...novaLinha.valor],
          participacao: parseInt((somaValores/novaLinha.participacao)*100),
        };
        return novoProjeto;
      } else {
        if(projeto.length===0){
          console.log('start........')
          return [...projeto, {socio: novaLinha.socio,
                               bens: novaLinha.bens,
                               percentual: novaLinha.percentual,
                               valor: novaLinha.valor,
                               participacao: 100}]
        }
        else{
          let somaValores = parseInt(novaLinha.valor)
          console.log('novo sócio')
          console.log(somaValores)
          console.log(novaLinha.participacao)
          console.log(novaLinha.participacao/somaValores)
          return [...projeto, {socio: novaLinha.socio,
            bens: novaLinha.bens,
            percentual: novaLinha.percentual,
            valor: novaLinha.valor,
            participacao: parseInt((somaValores/novaLinha.participacao)*100)}]
        }
        //return [...projeto, novaLinha];
      }
    };
    const totalTabela = calcularTotalValores(projeto)
    console.log("Total tabela: "+totalTabela)
    console.log("Bem selecionado: "+bensSelecionado)
    console.log("Percentual: "+percentual)
    const novoValor = Math.round((bensSelecionado * percentual) / 100);
    console.log("Novo valor: "+novoValor)
    const novaLinha = {
      socio: socioSelecionado,
      bens: [bensSelecionadoTexto],
      percentual: [percentual],
      valor: [novoValor],
      participacao: totalTabela+ novoValor,
    };

    const projetoAdicionado = adicionarNovaLinha(projeto, novaLinha);
    console.log('---------')
    console.log(projetoAdicionado)
    const novoProjeto = atualizarParticipacao(projetoAdicionado)
    console.log('---------')
    console.log(novoProjeto)
    setProjeto(novoProjeto);
    console.log(projeto)
  };

  const handleChangeSocio = (event) => {
    setSocioSelecionado(event.target.value);
  };

  const handleChangeBens = (event) => {
    const valorSelecionado = event.target.value;
    const textoSelecionado = event.target.options[event.target.selectedIndex].text;

    setBensSelecionado(valorSelecionado); // Armazena o valor selecionado
    setBensSelecionadoTexto(textoSelecionado); // Armazena o texto selecionado
  };

  const handleVisibility = () => {
    setVisibility(true);
  }

  // Função para calcular o total de valores do projeto
  const calcularTotalValores = (projeto) => {
    let totalValores = 0;

    projeto.forEach((linha) => {
      linha.valor.forEach((valor) => {
        totalValores += Number(valor);
      });
    });
    console.log("total valores: "+totalValores);
    return totalValores;
  };

  const handleChangePercentual = (event) => {
    const valorCampo = event.target.value.replace('%', ''); // Remove o símbolo de "%"
    const numericValue = parseInt(valorCampo, 10); // Converte para um número inteiro

    let percentualAtualizado;
    if (!isNaN(numericValue)) {
      percentualAtualizado = numericValue > 100 ? 100 : numericValue;
    } else {
      percentualAtualizado = 0; // Defina um valor padrão se a conversão falhar
    }

    setPercentual(percentualAtualizado);
  };

  const handleExcluirBem = (socio, index) => {
    // Percorre o projeto
    const projetoAtualizado = projeto.map((linha) => {
      // Se o sócio for igual ao sócio
      if (linha.socio === socio) {
        const novosBens = [...linha.bens];
        const novosPercentuais = [...linha.percentual];
        const novosValores = [...linha.valor];

        if (novosBens.length === 1) {
          // Se houver apenas um bem, remover toda a linha
          return null;
        } else {
          // Obtém o total de índices no array valores
          let totalArray = novosValores.length;
          // Obtém o último valor pois nessa etapa o projeto ainda não foi atualizado
          const ultimoValor = novosValores[totalArray - 1];

          // Remover apenas o último bem adicionado
          novosBens.splice(index, 1);
          novosPercentuais.splice(index, 1);
          novosValores.splice(index, 1);
          // Soma os valores agora que o último bem foi removido
          let somaValores = novosValores.reduce((acumulador, valor) => acumulador + Number(valor), 0);
          // Calcula o total dos projetos subtraindo o último valor
          const totalTabela = calcularTotalValores(projeto) - ultimoValor;

          // Retorna o novo projeto atualizado com os novos dados
          return {
            ...linha,
            bens: novosBens,
            percentual: novosPercentuais,
            valor: novosValores,
            participacao: parseInt((somaValores / totalTabela) * 100),
          };
        }
      }

      return linha;
    }).filter(Boolean); // Filtrar as linhas nulas removidas

    // Percorre o projeto
    const novoProjeto = atualizarParticipacao(projetoAtualizado);

    setProjeto(novoProjeto);
  };

  // Função para atualizar as participações do projeto
  function atualizarParticipacao(projetoAtualizado) {
    const novoProjeto = projetoAtualizado.map((linha) => {
      const novosBens = [...linha.bens];
      const novosPercentuais = [...linha.percentual];
      const novosValores = [...linha.valor];

      // Soma os valores agora que o último bem foi removido
      let somaValores = novosValores.reduce((acumulador, valor) => acumulador + Number(valor), 0);
      // Calcula o total dos projetos subtraindo o último valor
      const totalTabela = calcularTotalValores(projetoAtualizado);

      // Retorna o novo projeto atualizado com os novos dados
      return {
        ...linha,
        bens: novosBens,
        percentual: novosPercentuais,
        valor: novosValores,
        participacao: parseFloat((somaValores / totalTabela) * 100),
      };
    });

    return novoProjeto;
  }

  function createTable(){
    return(
      <div className="card-body table-responsive p-0">
        <div id="table" style={{ display: visibility ? 'flex' : 'none' }} className="tab-pane fade show active" role="tabpanel">
          <table className="table table-bordered table-striped mt-10">
            <tbody>
              <tr>
                <th>Sócio</th>
                <th className='text-center'>Bens</th>
                <th className='text-center'>Percentual</th>
                <th className='text-center'>Valor da Integralização</th>
                <th className='text-center'>Participação</th>
                <th className='text-center'>Ação</th> {/* Nova coluna de exclusão */}
              </tr>
              {projeto.map((linha, index) => (
                <tr key={index}>
                  <td>{linha.socio}</td>
                  <td className='text-center'>{linha.bens.join(", ")}</td>
                  <td className='text-center'>{linha.percentual.join("%, ")}%</td>
                  <td className='text-center'>R${' '}{linha.valor.reduce((acc, val) => acc + Number(val), 0).toLocaleString('pt-BR')}</td>
                  <td className='text-center'>{linha.participacao.toFixed(0)}%</td>
                  <td className='text-center'><button style={{marginRight: -10}} className="btn btn-danger" onClick={()=>handleExcluirBem(linha.socio, linha.bens.length - 1)}><i className="fa fa-trash center-element" title='Remover'></i></button></td>
                </tr>
              
              ))}
              <tr>
                <th>Total:</th>
                <td></td>
                <td></td>
                <td className='text-center'>R${' '}{calcularTotalValores(projeto).toLocaleString('pt-BR')}</td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
  const [showModal, setShowModal] = useState(false);

const handleCloseModal = () => {
      setShowModal(false);
    };
  
    const handleShowModal = () => {
      setShowModal(true);
    };

  function createModal() {
    return (

      <Modal show={showModal} onHide={handleCloseModal} size="lg">
         <Modal.Header>
          <Modal.Title>Confirme o envio do Projeto:</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {createTable()}
        </Modal.Body>
        <Modal.Footer>
          <button className="cancel btn btn-gray" onClick={handleCloseModal}>Cancelar</button>
          <button className="enviar btn btn-gray">Confirmar</button>
        </Modal.Footer>
        </Modal>
      )
    }
  
  // Função para renderizar a etapa atual do formulário
  const renderStep = () => {
      switch (step) {
          case 1:
            return(
                <div>
                    <h4 style={{marginTop: 5}}>Passo 1</h4>
                    <br />
                    <h5>Projeto de reestruturação societária:</h5>
                    <br />
                    <div className='row'>
                      <div className='col-6'>
                        <label for="nome_projeto">Nome do Projeto:</label>
                      </div>    
                    </div>
                    <div className='row'>
                      <div className='col-6'>
                        <input type='text' class='form-control' id='nome_projeto' />
                      </div>
                      <div className='col-2'>
                        <button className="btn btn-gray" onClick={nextStep}>Próximo</button>
                      </div>
                    </div>
                </div>
            );
          case 2:
            return(
              <div>
                <h4 style={{marginTop: 5}}>Passo 2</h4>
                <br />
                <h5>Monte seu Projeto:</h5>
                <br />
                <div className='row'>
                  <div className='col-5'>
                    <label for='vincular_socio'>Sócios:</label>
                  </div>
                  <div className='col-4'>
                    <label for='vincular_bem'>Bens:</label>
                  </div>
                  <div className='col-2'>
                    <label for='porcentagem'>Percentual:</label>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-5'>
                    <select class="form-control select" style={{width: '100%'}} aria-hidden='true' name='socio' id="vincular_socio" onChange={handleChangeSocio}>
                      <option value="">Selecione um sócio</option>
                      <option value="Fulano">Fulano</option>
                      <option value="Siclano">Siclano</option>
                      <option value="Beltrano">Beltrano</option>
                    </select>
                  </div>
                  <div className='col-4'>
                    <select class='form-control select' style={{width: '100%'}} aria-hidden='true' name='bem' id="vincular_bem" onChange={handleChangeBens}>
                      <option value={0}>Selecione um bem</option>
                      <option value={10000}>Moto</option>
                      <option value={120000}>Carro</option>
                      <option value={300000}>Casa</option>
                      <option value={60000}>R$ 60.000,00</option>
                    </select>
                  </div>
                  <div className='col-2'>
                    <input id='porcentagem' type='text' class='form-control' inputMode="numeric" onChange={(event)=>{handleChangePercentual(event); formatPorcentagem(event)}}/>
                  </div>
                  <div className='col-1'>
                    <button class='btn btn-gray btn-block add' onClick={()=>{handleAdicionarClick(); handleVisibility()}}><i class="fas fa-plus"/></button>
                  </div>
                </div>
                <br />
                {createTable()}
                <div className='row'>
                  <div style={{marginTop: 10}} class="col-12 text-center">
                    <button style={{marginRight: 5}} class='btn btn-gray' onClick={prevStep}>Voltar</button>
                    <button class='enviar btn btn-gray' onClick={handleShowModal}>Enviar</button>
                  </div>
                </div>
                <div className='row'>
                  <div class="col-12 text-center">
                    {createModal()}
                  </div>
                </div>
              </div>
            );
          default:
          return null;
      }
  };

  return(
      <>
      <AppHeader/>
      <AppMenu/>
      <div class="content-wrapper">
          <section class="content-header">
              <div class="container-fluid">
                  <div class="row mb-2">
                      <div class="col-sm-6">
                          <h1>Criando novo projeto</h1>
                      </div>
                      <div class="col-sm-6">
                          <ol class="breadcrumb float-sm-right">
                              <li class="breadcrumb-item"><a href="/">Início</a></li>
                              <li class="breadcrumb-item active">Criando novo projeto</li>
                          </ol>
                      </div>
                  </div>
              </div>
          </section>
          <section class="content">
              <div class="container-fluid">
                  <div class="card card-default">
                      <div class="card-body">
                      <form onSubmit={handleFormSubmit}>
                          {renderStep()}
                      </form>
                      </div>
                  </div>
              </div>
          </section>
      </div>
      <AppFooter/>
      <AppSetting/>
      </>
  );
}

export default CreateProject;