import './Login.css';
import React, {useEffect, useState} from 'react';
import axios from 'axios';
import { IconButton } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

//TODO: ver um método de fazer o checkbox de lembrar senha


function Login() {
    const [blockedTime, setBlockedTime] = useState(0);
    const [isDisabled, setIsDisabled] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    // * Funções para o relógio
    function formatTime(time) {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    }
        
    function Relogio(props) {
        const dataExp = new Date(localStorage.getItem('tempo'))
        const dataAtual = new Date()

        if(props.blockedTime == 0){
            if(dataExp > dataAtual){
            let conta = Math.ceil((dataExp - dataAtual) / 1000);
            setBlockedTime(conta)
            setIsDisabled(true)
            } else {
            setIsDisabled(false)  
            }
        }

        if(props.blockedTime > 0){
            //setBlockedTime(localStorage.getItem("tempo"))
            return (
            <>
            <div id="relogio" className='row'>
                <div className='clock-app'></div>
            </div>
            <div className='time-app'><a style={{marginTop: -50}}>{formatTime(props.blockedTime)}</a></div>
            </>
            );
        }
    }

    useEffect(() => {
    let interval = null;
    if (blockedTime > 0) {
        interval = setInterval(() => {
        setBlockedTime((prevTime) => prevTime - 1);
        }, 1000);
    }
    return () => clearInterval(interval);
    }, [blockedTime]);  

    // * Funções de validação para o front

    function validacao(caso1, caso2, caso3){
        const email = document.querySelector('#email')
        const senha = document.querySelector('#senha')
        let valorEmail = email.value
        let valorSenha = senha.value
        const emailLabel = document.querySelector('#tag-email')
        const senhaLabel = document.querySelector('#tag-senha')

        email.classList.add('invalid-border-app')
        senha.classList.add('invalid-border-app')
        if (caso1 != null){
            emailLabel.textContent = "Digite o email"
            email.classList.add('blank-app')

        } else if (caso3){
            emailLabel.textContent = "Dados incorretos"
            email.classList.add('invalid-app')  
        }
        if(caso2 != null){
            senhaLabel.textContent = "Digite a senha"
            senha.classList.add('blank-app')   
            
        } else if (caso3){
            senhaLabel.textContent = "Dados incorretos"
            senha.classList.add('invalid-app') 
        } 
        
        

        email.addEventListener("click", ()=>{
            email.classList.remove('invalid-app')
            email.classList.remove('blank-app')
            email.classList.remove('invalid-border-app')
            email.classList.remove('muitas-tentativas-app')
            emailLabel.textContent = "Email"
        })
        senha.addEventListener("click", ()=>{
            senha.classList.remove('invalid-app')
            senha.classList.remove('blank-app')
            senha.classList.remove('invalid-border-app')
            senha.classList.remove('aguarde-app')
            senhaLabel.textContent = "Senha"
        })  
    }

    function focusEmail(){
        let email = document.querySelector('#email').value
        let emailCampo = document.querySelector('#email')
        if(email != '' || email != null){
            emailCampo.classList.add('foco-app')
            if(email == ''){
                emailCampo.classList.remove('foco-app')
            }
        }
    }

    function focusSenha(){
        let senha = document.querySelector('#senha').value
        let senhaCampo = document.querySelector('#senha')
        if(senha != '' || senha != null){
            senhaCampo.classList.add('foco-app')
            if(senha == ''){
                senhaCampo.classList.remove('foco-app')
            }
        }
    }

    const togglePasswordVisibility = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };

    function muitasTentativas(){
        const email = document.querySelector('#email')
        const senha = document.querySelector('#senha')
        const emailLabel = document.querySelector('#tag-email')
        const senhaLabel = document.querySelector('#tag-senha')
        
        emailLabel.textContent = "Muitas tentativas inválidas"
        senhaLabel.textContent = "Aguarde 5 minutos"

        email.classList.add('muitas-tentativas-app');
        senha.classList.add('aguarde-app');
        
    }

    function removeInvalidBorder(){
        const email = document.querySelector('#email')
        const senha = document.querySelector('#senha')
        email.classList.remove('invalid-border-app')
        senha.classList.remove('invalid-border-app')

        // remove a classe de muitas tentativas e aguarde-app para evitar bugs
        email.classList.remove('muitas-tentativas-app')
        senha.classList.remove('aguarde-app')
        
    }

    // * Função de autentificação

    async function sign(){
        let msg = "entra funcao"
        try {
            msg = 'entra requisicao'
            const email = document.querySelector('#email').value;
            const senha = document.querySelector('#senha').value;

            await axios.post(`/api/sign`, {email, senha}, {withCredentials: true}).then((response)=>{
                if(response.data.status === 200){
                    document.cookie = `ANers=${response.data.token}; expires=Thu, 31 Dec 2023 23:59:59 GMT; path=/`;
                    window.location.href = "/";
                } else if(response.data.status === 400) {
                    if(response.data.msg1 ||  response.data.msg2 || response.data.msg == "invalido"){
                        setTimeout(()=>{
                            validacao(response.data.msg1, response.data.msg2, response.data.msg);
                        }, 50) //Pequeno delay de criação apenas para deixar mais intuitivo
                        
                    } else if (response.data.msg === "muitas tentativas") {
                        muitasTentativas();
                        // alert("Muitas tentativas inválidas! Tente novamente em 1 minuto.");
                        setBlockedTime(response.data.time);
                        localStorage.setItem("tempo", response.data.date)
                        setIsDisabled(true);
                    }
                }
            })
        } catch(error) {
            console.error(error);
            window.location.href = "/bad/request";
        }
    }

    // Adicionando evento de tecla Enter
    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.key === "Enter") {
                sign();
                removeInvalidBorder();
            }
        };
        document.addEventListener("keypress", handleKeyPress);
        return () => {
            document.removeEventListener("keypress", handleKeyPress);
        };
    }, []);

    return (
        <div className="login-container-app">
                <div className="">
                    <div className="card-body">
                        <div className='row'>
                            <div className='form-container-1'>
                            <div class="icon-heading">
                                <img src="/dist/img/marca_almeira_e_nogueira_fundo_colorido.png"alt="Ícone" class="icon-img"/>
                            </div>
                            <div className='text-portal'>
                                <p>Portal Almeida & Nogueira</p>
                            </div>
                            <div className='description-portal'> 
                                <p>Encontre soluções personalizadas que simplificam a interação com o escritório Almeida e Nogueira e oferecem acesso a serviços jurídicos de excelência.</p>
                            </div>
                            <div className='wrapper-social'>
                                <a href='https://www.linkedin.com/company/almeidaenogueira/' className='href-social'>
                                    <div className='icon linkedin'>
                                        <div className='tooltip'>Linkedin</div>
                                        <div><i className='fab fa-linkedin'></i></div>
                                    </div>
                                </a>
                                <a href='https://www.instagram.com/almeidaenogueira/' className='href-social'>
                                    <div className='icon instagram'>
                                        <div className='tooltip'>Instagram</div>
                                        <div><i className='fab fa-instagram'></i></div>
                                    </div>
                                </a>
                                <a href='https://www.youtube.com/@anadvogados8921' className='href-social'>
                                    <div className='icon youtube'>
                                        <div className='tooltip'>Youtube</div>
                                        <div><i className='fab fa-youtube'></i></div>
                                    </div>
                                </a>
                                <a href='https://almeidaenogueira.com.br/' className='href-social'>
                                    <div className='icon site'>
                                        <div className='tooltip'>Site</div>
                                        <div><i className='fa fa-globe-americas'></i></div>
                                    </div>
                                </a>
                            </div>

                            </div>
                            <div className='form-container-2'>
                                <h2 className='center-element'>Portal AN</h2>
                                <Relogio blockedTime={blockedTime} classList='clock-app'/>
                                <div className='row input-group-app'>
                                    <input type='email' id='email' className="campo-app" onChange={focusEmail} disabled={isDisabled}/>
                                    <label id='tag-email'>Email</label>
                                </div>
                                <div className="row input-group-app-senha">
                                    <input
                                        type={showPassword ? "text" : "password"}
                                        id="senha"
                                        className="campo-app"
                                        onChange={focusSenha}
                                        disabled={isDisabled}
                                    />
                                    <label id="tag-senha">Senha</label>
                                    <div className="show-hide-login">
                                        <IconButton
                                            onClick={togglePasswordVisibility}
                                            aria-label={
                                                showPassword
                                                  ? "Ocultar senha"
                                                  : "Mostrar senha"
                                            }
                                        >
                                            {showPassword ? (
                                                <VisibilityOffIcon />
                                            ) : (
                                                <VisibilityIcon />
                                            )}
                                        </IconButton>
                                    </div>
                                </div>
                                
                                <button onClick={()=>{sign(); removeInvalidBorder()}} className="btn-entrar" disabled={isDisabled}>Entrar</button>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
    );
}

export default Login;
