import AppHeader from '../../basic/app-header/AppHeader';
import AppMenu from '../../basic/app-menu/AppMenu';
import AppFooter from '../../basic/app-footer/AppFooter';
import AppSetting from '../../basic/app-setting/AppSetting';
import React, {useEffect, useState} from 'react';
import { Avatar, IconButton } from '@mui/material';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import DeleteIcon from '@mui/icons-material/Delete';
import { postAxios } from '../../../hooks/useAxios';
import { formatCpf } from '../../../utils/formatCpf';
import { useParams } from 'react-router-dom';
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

function EditUser() {

    // id do cliente, obtido pela url
    const {id} = useParams();
    const [user, setUser] = useState(null);
    const [usersClient, setUsersClient] = useState(null)
    const [imagePreview, setImagePreview] = useState(null);
    const [isProcessing, setIsProcessing] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [departments, setDepartments] = useState(null);
    const form = new FormData();

    // * Funcoes auxiliares ao input de foto de perfil

    const handleImageChange = (selectedImage) => {
        console.log(selectedImage)
        if (selectedImage) {
            const imageUrl = URL.createObjectURL(selectedImage);
            setImagePreview(imageUrl);
            // Adicione a imagem ao FormData
            form.append('image', selectedImage, selectedImage.name);
        }
      };
    
    const handleImageDrop = (e) => {
        e.preventDefault();
        const selectedImage = e.dataTransfer.files[0];
        handleImageChange(selectedImage);
    };

    const handlePreviewClick = () => {
        document.getElementById('imageInput').click();
    };

    const handleRemoveImage = () => {
        // Remova a imagem do FormData
        form.delete('image');
        setImagePreview(null);
    };
    
    // * Requisição para API

    // carregar os valore do usuario
    useEffect(() => {

        const getUser = async () => {
            postAxios(`/api/get/one/user/${id}`)
                .then((resp) => {
                    setUser(resp.data.user);
                    setUsersClient(resp.data.client)
		    setDepartments(resp.data.departamentos);
                    // setImagePreview(resp.data.adm.image) // espera-se que o usuario tenho o campo de imagem no bd para seu perfil
                })
                .catch((error) => {
                    window.location.href = "/bad/request";
                    console.log(error);
                })
            
        }

        getUser();
    
    }, [id]);

    if(user === null) {
        return(
            <>
            <AppHeader/>
            <AppMenu/>
            <div className='content-wrapper'>
                <div className='card-body'>
                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100vh"
                    }}>
                        <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>
            </div>
            <AppFooter/>
            <AppSetting/>
            </>
        ); 
    }

    // * Requisição para API
    function updateUser(){
        if (isProcessing) return; // Impede cliques adicionais enquanto já está em andamento
        setIsProcessing(true);
        console.log(user)
	const departamento = document.querySelector('#department').value;
        // insercao no formulario para o envio
        form.append('username', user.name);
        form.append('cpf', user.cpf);
        form.append('email', user.email);
        form.append('client', usersClient.name);
        form.append('password', user.password);
        form.append('confirmPassword', user.confirmPassword);
        form.append('department', departamento);
        const config = {
            headers: {
              'Content-Type': 'multipart/form-data', // Importante: defina o cabeçalho 'Content-Type' como 'multipart/form-data' para enviar arquivos
            },
        };
        
        postAxios(`/api/update/user/${id}`, form, null, config)
            .then((resp) => {
              
                if(resp.data.errors){
                    for(let i=0; i<resp.data.errors.length; i++){
                        let elemento = document.querySelector(`#${resp.data.errors[i].elemento}`);
                        
                        elemento.placeholder = "Preencha esse campo";
                        elemento.classList.add('erro');
                        
                        if(resp.data.errors[i].mensagem === 'Este CPF/CNPJ já está em uso!') {
                            alert('Este CPF/CNPJ já está em uso!');
                        }
                    }
                    return;
                }
                window.location = `/manage-users`
            })
            .catch((error) => {
                window.location.href = "/bad/request";
                console.error('Erro na solicitação POST:', error);
            })
            .finally(() => {
                setIsProcessing(false); // Habilita o botão novamente após a conclusão ou erro
            })
    }

    // função para permitir que os campos com valores pré-carregados possam ser alterados
    function loadForm(event){
        const { name, value } = event.target;
        setUser(prevState => ({
          ...prevState,
          [name]: value
        }));  
    }

    // funcoes para permitir a visualizacao da senha
    const togglePasswordVisibility = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };

    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword((prevShowConfirmPassword) => !prevShowConfirmPassword);
    };

    return (
        <>
        <AppHeader />
        <AppMenu />
        <div className="content-wrapper">
            {/* Content Header (Page header) */}
            <section className="content-header">
                <div className="container-fluid">
                <div className="row mb-2">
                    <div className="col-sm-6">
                    <h1>Editor de usuário</h1>
                    </div>
                    <div className="col-sm-6">
                    <ol className="breadcrumb float-sm-right">
                        <li className="breadcrumb-item"><a href="/">Início</a></li>
                        <li className="breadcrumb-item"><a href="/manage-users">Gerenciar usuários</a></li>
                        <li className="breadcrumb-item active">Editar usuário</li>
                    </ol>
                    </div>
                </div>
                </div>
            </section>

            {/* Main content */}
            <section class="content">
                <div class="container-fluid">
                    <div class="card card-default">
                        <div className="card-header center-element bg-gray">
                            <h3 className="card-title" style={{fontWeight: 600}}>{`Editar usuário: ${user.name}`}</h3>
                        </div>
                        <div class="card-body">  
                        <div className='input-grid form-group'>
                            <div className='campo1'>
                                <label htmlFor="profileImage" style={{marginLeft: 40}}>Foto de Perfil:</label>
                                <div
                                    className=' form-group image-input-preview'
                                    onDrop={handleImageDrop}
                                    onDragOver={(e) => e.preventDefault()}
                                >
                                    <div className="circle-placeholder" onClick={handlePreviewClick}>
                                        <div className="image-container">
                                            {imagePreview ? (
                                                <div>
                                                    <Avatar alt="Profile Image" src={imagePreview} sx={{ width: 175, height: 175 }} />
                                                </div>
                                            ) : (
                                                <div className="input-indicator">                                                    
                                                    <PhotoCameraIcon />
                                                </div>
                                            )}
                                        </div>
                                        <input
                                            id="imageInput"
                                            type="file"
                                            accept="image/*"
                                            onChange={(e) => handleImageChange(e.target.files[0])}
                                            style={{ display: 'none' }}
                                        />
                                    </div>
                                    <label htmlFor="imageInput" className='small-text' style={{marginLeft: 35}}>Clique ou arraste!</label>
                                    <div className='row'>
                                        {imagePreview && (
                                            <div style={{marginLeft: 150, marginTop: -45}}>
                                                <IconButton
                                                    color="error"
                                                    aria-label="remove"
                                                    onClick={handleRemoveImage}
                                                    className="remove-button"
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </div>
                                        )}
                                        
                                    </div>
                                </div>
                            </div>
                            <div class="campo2">
                                <label class="required" for="name">Nome do usuário administrador:</label>
                                <input type="text" name="name" id="name" class="form-control" value={user.name || ''} onChange={loadForm}/>
                            </div> 
                            <div class="campo3">
                                <label class="required" >CPF:</label>
                                <input type="text" name="cpf" id="cpf" class="form-control" onInput={formatCpf} value={user.cpf || ''} onChange={loadForm}/>
                            </div>
                            <div class="campo4">
                                <label class="required" >E-mail:</label>
                                <input type="email" name="email" id="email" class="form-control" value={user.email || ''} onChange={loadForm}/>
                            </div>
                            <div class="campo5">
                                <label class="required" >Cliente (Empresa):</label>
                                <input name="client" id="client" class="form-control" value={usersClient.name || ''} onChange={loadForm} disabled={true}>
                                </input>
                            </div>
	    		    <div class="campo6">
                                <label class="required" >Departamento:</label>
                                <select name="department" id="department" className="form-control">
	                          <option selected>{user.departamento || ''}</option>
                                  {departments && departments.map(resp => (
                                    <option key={resp.id} value={resp.departamento}>{resp.departamento}</option>
                                  ))}
                                </select>

                            </div>
	                    <div className="campo7">
                                        <label className="required" htmlFor="password">Senha:</label>
                                        <div className="input-group">
                                            <input
                                                className="form-control"
                                                type={showPassword ? "text" : "password"}
                                                id="password"
                                                name="password"
                                                value={user.password || ""}
                                                onChange={loadForm}
                                            />
                                            <div className=" show-hide">
                                                <IconButton
                                                    onClick={togglePasswordVisibility}
                                                    aria-label={showPassword ? "Ocultar senha" : "Mostrar senha"}
                                                    edge="end"
                                                >
                                                    {showPassword ? (
                                                        <VisibilityOffIcon />
                                                    ) : (
                                                        <VisibilityIcon />
                                                    )}
                                                </IconButton>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="campo8" style={{marginRight: -28}}> 
                                        <label>Confirme a senha:</label>
                                        <div className="input-group">
                                            <input
                                                className="form-control"
                                                type={showConfirmPassword ? "text" : "password"}
                                                id="confirmPassword"
                                                name="confirmPassword"
                                            />
                                            <div className="show-hide">
                                                <IconButton
                                                    onClick={toggleConfirmPasswordVisibility}
                                                    aria-label={showConfirmPassword ? "Ocultar senha" : "Mostrar senha"}
                                                    edge="end"
                                                >
                                                    {showConfirmPassword ? (
                                                        <VisibilityOffIcon />
                                                    ) : (
                                                        <VisibilityIcon />
                                                    )}
                                                </IconButton>
                                            </div>
                                        </div>
                                    </div>
                        </div>
                        <div class="col-12 center-element">
                            <a href="/manage-users"><button type="button" class="cancel btn btn-gray">Cancelar</button></a>
                            <button type="submit" class="enviar btn btn-gray" style={{marginLeft: 5}} onClick={updateUser}>
                                {isProcessing ? 'Aguarde...' : 'Enviar'}    
                            </button>
                        </div>
                        </div> 
                    </div>
                </div>
            </section>
        {/* /.content */}
        </div>
        <AppFooter />
        <AppSetting />
        </>  
    );
}

export default EditUser;
