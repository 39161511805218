import AppHeader from '../../../../basic/app-header/AppHeader';
import AppMenu from '../../../../basic/app-menu/AppMenu';
import AppFooter from '../../../../basic/app-footer/AppFooter';
import AppSetting from '../../../../basic/app-setting/AppSetting';
import { useState } from 'react';
import axios from 'axios';

function CreatePhysical(){
    const [selectedOption, setSelectedOption] = useState('');

    function handleRadioChange(radioButton) {
        const labels = document.querySelectorAll('.custom-radio label');
        labels.forEach(function(label) {
          label.classList.remove('selected');
        });
        
        if (radioButton.checked) {
          radioButton.nextElementSibling.classList.add('selected');
        }
        setSelectedOption(radioButton.target.value);   
    }
    // função para formatar o campo de cep (00000-000)
    function formatCep(e) {
        const campo = e.target;
        const valor = campo.value.replace(/\D/g, ""); // Remove tudo o que não é dígito
        let novoValor = "";

        if (valor.length <= 10) {
        novoValor = valor.replace(/(\d{5})(\d{3}).*/, "$1-$2");
        } 

        campo.value = novoValor;
    }

    // função para formatar o campo de cpf(000.000.000-00)
    function formatCpf(e) {
        const campo = e.target;
        const valor = campo.value.replace(/\D/g, ""); // Remove tudo o que não é dígito
    
        let novoValor = "";
    
        novoValor = valor.replace(/(\d{3})(\d{3})(\d{3})(\d{2}).*/, "$1.$2.$3-$4");
    
        campo.value = novoValor;
    }

    // função para formatar o campo de RG(00.000.000-0)
    function formatRG(e) {
        const campo = e.target;
        const valor = campo.value.replace(/\D/g, ""); // Remove tudo o que não é dígito
    
        let novoValor = "";
    
        novoValor = valor.replace(/(\d{2})(\d{3})(\d{3})(\d{1}).*/, "$1.$2.$3-$4");
    
        campo.value = novoValor;
    }


    // * Requisição para API
    function createPhysical(){
        console.log("entra na função")
        const acao = "create"
        const tipo = "fisica"
        const nome = document.querySelector('#nome').value
        const nascimento = document.querySelector('#nascimento').value
        const nacionalidade = document.querySelector('#nacionalidade').value
        const rg = document.querySelector('#rg').value
        const cpf = document.querySelector('#cpf').value
        const profissao = document.querySelector('#profissao').value
        const civil = selectedOption
        const regime = document.querySelector('#regime').value
        const endereco = document.querySelector('#endereco').value
        const cep = document.querySelector('#cep').value
        const cidade = document.querySelector('#cidade').value
        const estado = document.querySelector('#estado').value
        const email = document.querySelector('#email').value
        

        axios.post(`http://localhost:5000/api/actions/partners`, {acao, tipo, nome, nascimento, nacionalidade, rg, cpf, profissao, civil, regime, endereco, cep, cidade, estado, email}, {withCredentials: true}).then((resp)=>{
            
            if(resp.data.status == 400){
                alert("Essa pessoa/cpf já está cadastrado!")
            }
            if(resp.data.status == 404){
                window.location = `/erro`
            }
            if(Array.isArray(resp.data.erros)){
                if(resp.data.erros.length === 0){
                    window.location = `/partners`
                } else {
                    for(let i=0; i<resp.data.erros.length; i++){
                        let elemento = document.querySelector(`#${resp.data.erros[i].elemento}`)
                        console.log(resp.data.erros[i].elemento)
                        if(resp.data.erros[i].elemento == "civil"){
                            elemento.style.color = '#EF5350';
                            elemento.style.fontWeight = 'bold';
                        } else {
                            elemento.style.backgroundColor = '#FFC0CB'
                        }
                    }
                }
            }
            
        })
    }

    return(
        <>
        <AppHeader/>
        <AppMenu/>
        <div class="content-wrapper">

            <section class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-6">
                            <h1>Criando novo sócio</h1>
                        </div>
                        <div class="col-sm-6">
                            <ol class="breadcrumb float-sm-right">
                                <li class="breadcrumb-item"><a href="/">Início</a></li>
                                <li class="breadcrumb-item active">Criando sócio</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </section>

            <section class="content">
                <div class="container-fluid">
                    <div class="card card-default">
                        <div class="card-body">
                                <div class="physical">
                                    <h5>Qualificação de sócio:</h5>
                                        <div className="row">
                                            <div className='col-8'>
                                                <div class="form-group">
                                                    <label class="required" for="nome">Nome completo:</label>
                                                    <input type="text" name="nome" id="nome" class="form-control"/>
                                                </div>
                                            </div>
                                            <div className='col-4'>
                                                <div class="form-group">
                                                    <label class="required" >Nacionalidade:</label>
                                                    <input type="text" name="nacionalidade" id="nacionalidade" class="form-control"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-4'>
                                                <div class="form-group">
                                                    <label class="required" for="nascimento">Data de nascimento:</label>
                                                    <input class="form-control" type="date" id="nascimento" name="nascimento"/>
                                                </div>
                                            </div>
                                            <div className='col-4'>
                                                <div class="form-group">
                                                    <label class="required" >CPF:</label>
                                                    <input type="text" name="cpf" id="cpf" class="form-control" onInput={formatCpf}/>
                                                </div>
                                            </div>
                                            <div className='col-4'>
                                                <div class="form-group">
                                                    <label class="required" >RG:</label>
                                                    <input type="text" name="rg" id="rg" class="form-control" onInput={formatRG}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-6'>
                                                <div class="form-group">
                                                    <label class="required" >E-mail:</label>
                                                    <input type="email" name="email" id="email" class="form-control"/>
                                                </div>
                                            </div>
                                            <div className='col-6'>
                                                <div class="form-group">
                                                    <label class="required" >Profissão:</label>
                                                    <input type="text" name="profissao" id="profissao" class="form-control"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-7'>
                                                <div class="form-group">
                                                    <label class="required" id="civil">Estado civil:</label>
                                                    <div class="form-group">
                                                        <div id="custom-radio">
                                                            <input type="radio" name="estadocivil" id="casado" value="casado"  onChange={handleRadioChange}/>
                                                            <label id="label_casado" style={{marginLeft: 2}} for="casado">Casado(a)</label>

                                                            <input type="radio" name="estadocivil" id="solteiro" value="solteiro"  onChange={handleRadioChange}/>
                                                            <label id="label_solteiro" for="solteiro">Solteiro(a)</label>

                                                            <input type="radio" name="estadocivil" id="divorcio" value="divorcio" onChange={handleRadioChange}/>
                                                            <label id="label_divorcio" for="divorcio">Divorcio(a)</label>

                                                            <input type="radio" name="estadocivil" id="uniao" value="uniao"  onChange={handleRadioChange}/>
                                                            <label id="label_uniao" for="uniao">União estável</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-5'>
                                                <div class="form-group">
                                                    <label class="required" >Regime de casamento:</label>
                                                    <input type="text" name="regime" id="regime" class="form-control"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-5'>
                                                <div class="form-group">
                                                    <label class="required" >Endereço:</label>
                                                    <input type="text" name="endereco" id="endereco" class="form-control"/>
                                                </div>
                                            </div>
                                            <div className='col-3'>
                                                <div class="form-group">
                                                    <label class="required" >CEP:</label>
                                                    <input type="text" name="cep" id="cep" class="form-control" onInput={formatCep}/>
                                                </div>
                                            </div>
                                            <div className='col-2'>
                                                <div class="form-group">
                                                    <label class="required" >Cidade:</label>
                                                    <input type="text" name="cidade" id="cidade" class="form-control"/>
                                                </div>
                                            </div>
                                            <div className='col-2'>
                                                <label class="required" >Estado:</label>
                                                <select className="form-control" id="estado" name="estado">
                                                    <option></option>
                                                    <option>AC</option>
                                                    <option>AL</option>
                                                    <option>AP</option>
                                                    <option>AM</option>
                                                    <option>BA</option>
                                                    <option>CE</option>
                                                    <option>DF</option>
                                                    <option>ES</option>
                                                    <option>GO</option>
                                                    <option>MA</option>
                                                    <option>MT</option>
                                                    <option>MS</option>
                                                    <option>MG</option>
                                                    <option>PA</option>
                                                    <option>PB</option>
                                                    <option>PR</option>
                                                    <option>PE</option>
                                                    <option>PI</option>
                                                    <option>RJ</option>
                                                    <option>RN</option>
                                                    <option>RS</option>
                                                    <option>RO</option>
                                                    <option>RR</option>
                                                    <option>SC</option>
                                                    <option>SP</option>
                                                    <option>SE</option>
                                                    <option>TO</option>
                                                </select>
                                            </div>
                                        </div>                         
                                        <div class="col-12 center-element">
                                            <a href="/partners"><button type="button" class="cancel btn btn-gray">Cancelar</button></a>
                                            <button type="submit" class="enviar btn btn-gray" style={{marginLeft: 5}} onClick={createPhysical}>Enviar</button>
                                        </div>
                                    </div> 
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <AppFooter/>
        <AppSetting/>
        </>
    )
}

export default CreatePhysical;