import AppHeader from '../../../basic/app-header/AppHeader';
import AppMenu from '../../../basic/app-menu/AppMenu';
import AppFooter from '../../../basic/app-footer/AppFooter';
import AppSetting from '../../../basic/app-setting/AppSetting';
import React, { useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import axios from 'axios';

function CreateAsset(){
  //TODO: select dos sócios estpa com valor ficticio, deve ser puxado do bd


  // * Declaração variáveis de estado

  // funcionamento das tabs
  const [tab, setTab] = useState(1);
  const [step, setStep] = useState(1);
  const [step2, setStep2] = useState(1);
  const [step3, setStep3] = useState(1);
  const [step4, setStep4] = useState(1);
  const [form1Data, setForm1Data] = useState({});
  const [form2Data, setForm2Data] = useState({});
  const [form3Data, setForm3Data] = useState({});
  // armazenar os sócios selecionados para mandar ao bd
  const [todosSocios1, setTodosSocios1] = useState([]);
  const [todosSocios2, setTodosSocios2] = useState([]);
  const [todosSocios3, setTodosSocios3] = useState([]);
  const [todosSocios4, setTodosSocios4] = useState([]);
  // definir se o bem imóvel possui gravames (true) ou não (false)
  const [gravames, setGravames] = useState(false)
  // indicar o tipo do bem imóvel, sendo rural (0) e urbano (1)
  const [tipoImovel, setTipoImovel] = useState(null)
  // verificação de marcação dos campos de radio button
  const [selectedOptionGeo, setSelectedOptionGeo] = useState('');
  const [selectedOptionCar, setSelectedOptionCar] = useState('');
  const [selectedOptionGrav, setSelectedOptionGrav] = useState('');
  const [selectedOptionTipo, setSelectedOptionTipo] = useState('');
  // guardar os valores de tabs anteriores realizar a requisiçãoo do bem "dinheiro"
  const [valorDinheiro, setValorDinheiro] = useState(null);
  const [denominacaoDinheiro, setDenominacaoDinheiro] = useState(null);
  // guardar os valores de tabs anteriores realizar a requisiçãoo do bem "bens móveis"
  const [denominacaoMovel, setDenominacaoMovel] = useState(null);
  const [valorMovel, setValorMovel] = useState(null);
  const [descricaoMovel, setDescricaoMovel] = useState(null);
  // guardar os valores de tabs anteriores realizar a requisiçãoo do bem "bens imóveis"
  const [denominacaoImovel, setDenominacaoImovel] = useState(null);
  const [matriculaImovel, setMatriculaImovel] = useState(null);
  const [criImovel, setCriImovel] = useState(null);
  const [comarcaImovel, setComarcaImovel] = useState(null);
  const [cidadeImovel, setCidadeImovel] = useState(null);
  const [impostoImovel, setImpostoImovel] = useState(null);
  const [mercadoImovel, setMercadoImovel] = useState(null);
  const [locacaoImovel, setLocacaoImovel] = useState(null);
  const [descricaoImovel, setDescricaoImovel] = useState(null);
  const [ccirImovel, setCcirImovel] = useState(null);
  const [nirfImovel, setNirfImovel] = useState(null);
  const [areaImovel, setAreaImovel] = useState(null);
  const [itrImovel, setItrImovel] = useState(null);
  const [cadastroImovel, setCadastroImovel] = useState(null);
  const [iptuImovel, setIptuImovel] = useState(null);
  const [descGravamesImovel, setDescGravamesImovel] = useState(null);
  const [etapaImovel, setEtapaImovel] = useState(null);
  // guardar os valores de tabs anteriores realizar a requisiçãoo do bem "ações"
  const [denominacaoAcoes, setDenominacaoAcoes] = useState(null);
  const [razaoSocialAcoes, setRazaoSocialAcoes] = useState(null);
  const [cnpjMeAcoes, setCnpjMeAcoes] = useState(null);
  const [nireAcoes, setNireAcoes] = useState(null);
  const [capitalAcoes, setCapitalAcoes] = useState(null);
  const [patrimonioAcoes, setPatrimonioAcoes] = useState(null);
  const [numAcoes, setNumAcoes] = useState(null);
  const [valorAcoes, setValorAcoes] = useState(null);

  // * Funções auxiliares das tab's
  
  const handleFormSubmit = (e) => {
    e.preventDefault();
    // Lógica para enviar os dados do formulário
    // Aqui você pode fazer uma chamada de API ou executar qualquer outra ação
    console.log({
      form1Data,
      form2Data,
      form3Data
    });
  };

  const nextStep = () => {
    setStep((prevStep) => prevStep + 1);
  };

  const prevStep = () => {
    setStep((prevStep) => prevStep - 1);
  };
  const nextStep2 = () => {
    setStep2((prevStep) => prevStep + 1);
  };

  const prevStep2 = () => {
    setStep2((prevStep) => prevStep - 1);
  };
  const nextStep3 = () => {
    setStep3((prevStep) => prevStep + 1);
  };

  const prevStep3 = () => {
    setStep3((prevStep) => prevStep - 1);
  };
  
  const nextStep4 = () => {
    setStep4((prevStep) => prevStep + 1);
  };

  const prevStep4 = () => {
    setStep4((prevStep) => prevStep - 1);
  };

  // * Função radio button

  function handleRadioChangeGeo(radioButton) {
    const labels = document.querySelectorAll('.custom-radio label');
    labels.forEach(function(label) {
      label.classList.remove('selected');
    });
    
    if (radioButton.checked) {
      radioButton.nextElementSibling.classList.add('selected');
    }
    setSelectedOptionGeo(radioButton.target.value);   
  }

  function handleRadioChangeCar(radioButton) {
    const labels = document.querySelectorAll('.custom-radio label');
    labels.forEach(function(label) {
      label.classList.remove('selected');
    });
    
    if (radioButton.checked) {
      radioButton.nextElementSibling.classList.add('selected');
    }
    setSelectedOptionCar(radioButton.target.value);   
  }

  function handleRadioChangeGrav(radioButton) {
    const labels = document.querySelectorAll('.custom-radio label');
    labels.forEach(function(label) {
      label.classList.remove('selected');
    });
    
    if (radioButton.checked) {
      radioButton.nextElementSibling.classList.add('selected');
    }
    setSelectedOptionGrav(radioButton.target.value);   
  }

  function handleRadioChangeTipo(radioButton) {
    const labels = document.querySelectorAll('.custom-radio label');
    labels.forEach(function(label) {
      label.classList.remove('selected');
    });
    
    if (radioButton.checked) {
      radioButton.nextElementSibling.classList.add('selected');
    }
    setSelectedOptionTipo(radioButton.target.value);   
  }
  
  // * Funções de formatação

  // função para formatar os campos de valores monetários(R$ 000.000.000,00)
  function formatMoney(e) {
    const campo = e.target;
    const valor = campo.value.replace(/\D/g, ""); // Remove tudo o que não é dígito
    let novoValor = "";
    // Adiciona o prefixo "R$ "
    novoValor += "R$ ";
    // Verifica se há parte inteira
    if (valor.length > 2) {
      const parteInteira = valor.slice(0, -2);
      const parteDecimal = valor.slice(-2);
      // Formatação para a parte inteira
      novoValor += parteInteira.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.") + "," + parteDecimal;
    } else {
      novoValor += valor;
    }
    campo.value = novoValor;
  }

  // coloca o valor monetário (R$ 000.000,00) para float com 2 casas decimais para ser colocada no bd
  function formatMoneyForDatabase(value) {
    return parseFloat(value.replace(/[^0-9,-]/g, "").replace(",", ".")).toFixed(2);
  }

  // função para formatar o campo de cnpj(00.000.000/0000-00)
  function formatCnpj(e) {
    const campo = e.target;
    const valor = campo.value.replace(/\D/g, ""); // Remove tudo o que não é dígito
    let novoValor = "";
    novoValor = valor.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2}).*/, "$1.$2.$3/$4-$5");
    campo.value = novoValor;
  }

  // função para formatar campos numéricos
  function formatNumeric(e){
    const campo = e.target;
    const valor = campo.value.replace(/\D/g, ""); // Remove tudo o que não é dígito
    campo.value = valor;
  }

  // função para formatar campos somente com letras
  function formatAlphabetical(e){
    const campo = e.target;
    const valor = campo.value.replace(/\d/g, ""); // Remove todos os dígitos
    campo.value = valor;
  }

  // função para formatar campo de área em hectares
  function formatArea(e) {
    const campo = e.target;
    const valor = campo.value.replace(/\D/g, ""); // Remove tudo o que não é dígito
    const areaFormatada = valor.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " (ha)"; // Formata o número e adiciona a unidade "ha"
    campo.value = areaFormatada;
    // Define a posição do cursor antes do símbolo "(ha)"
    campo.selectionStart = campo.value.length - 5;
    campo.selectionEnd = campo.value.length - 5;
  }

  // coloca o valor da área em hectares somente em número para ser colocada no bd
  function formatAreaForDatabase(value) {
    return value.replace(/[\D.() ]/g, ""); // Remove todos os caracteres não numéricos, pontos, parênteses e espaços
  }
  
  // * Função de verificação no vetor de sócios

  function socioExiste(socio, todosSocios){
    let valor = false;
    for(let i=0; i<todosSocios.length; i++){
      if(socio == todosSocios[i]){
        valor = true
      }
    }
    console.log("valor de retorno:  "+valor)
    return valor
  }

  // * Função auxiliar de remoção de linha

  async function handleRemoveRow(tbody, setTodosSocios) {
    const rows = tbody.querySelectorAll('tr');
    rows.forEach((row, index) => {
      const tdIdx = row.querySelector('#index');
      const tdSocio = row.querySelector('#partner')
      if(tdSocio){
        const tdSocioText = tdSocio.textContent
        setTodosSocios(prevTodosSocios => {
          const socios = [...prevTodosSocios];
          console.log(tdSocioText)
          const indice = socios.indexOf(tdSocioText); 
          console.log(indice)
          if (indice !== -1) {
            tdIdx.textContent = indice + 1
          }
          return socios;
        });
      }
      
    });
  }

  // * Função auxiliar de criação de linha

  async function handleCreateRow(setTodosSocios, socio) {
      let valor=0;
      await setTodosSocios(prevTodosSocios => {
        const socios = [...prevTodosSocios];
        console.log("socios: "+socios)
        const indice = socios.indexOf(socio); 
        console.log("contador indice: "+indice)
        console.log("contador socio: "+socio)
        const contador = indice + 1
        console.log("contador : "+contador)
        if (indice !== -1) {
          valor = contador
        }
        return socios;
      });
      return valor;  
  }
  
  // * Funções da tabela de sócios

  async function removeRow(socio, tr, setTodosSocios, tbody) {
    // Remove a linha ao clicar no botão
    tr.remove();
    
    
    setTodosSocios(prevTodosSocios => {
      const indice = prevTodosSocios.indexOf(socio);
      if (indice !== -1) {
        const novoTodosSocios = [...prevTodosSocios];
        novoTodosSocios.splice(indice, 1);
        console.log(novoTodosSocios);
        return novoTodosSocios;
      }
      return prevTodosSocios;
    });
    
    handleRemoveRow(tbody, setTodosSocios);
  }

  async function createRow(socio, id, setTodosSocios){
    
    const tbody = document.querySelector(`${id} tbody`);

    const tr = document.createElement('tr');

    const tdIdx = document.createElement('td');
    tdIdx.classList.add('action-column');
    tdIdx.id = 'index';
    const cont = await handleCreateRow(setTodosSocios, socio)
    tdIdx.textContent = (cont);
  
    const tdSocio = document.createElement('td');
    tdSocio.id = 'partner';
    tdSocio.textContent = socio;

    const tdAcao = document.createElement('td');
    tdAcao.classList.add('action-column');

    const buttonAcao = document.createElement('button');
    const iAcao = document.createElement('i')
    buttonAcao.className = 'btn btn-danger'
    iAcao.className = 'fa fa-trash center-element';
    iAcao.title = 'Remover';

    buttonAcao.addEventListener('click', ()=>{removeRow(socio, tr, setTodosSocios, tbody)});

    buttonAcao.appendChild(iAcao);
    tdAcao.appendChild(buttonAcao);

    tr.appendChild(tdIdx);
    tr.appendChild(tdSocio);
    tr.appendChild(tdAcao);

    tbody.appendChild(tr);
  }

  function createTable(tbody, tableElement, tabela, divTable, socio, id, setTodosSocios){
    const trHeader = document.createElement('tr');
    const thIdx = document.createElement('th');
    thIdx.textContent = '#';
    const thSocio = document.createElement('th');
    thSocio.textContent = 'Sócio';
    const thAcao = document.createElement('th');
    thAcao.textContent = 'Ação'
    trHeader.appendChild(thIdx);
    trHeader.appendChild(thSocio);
    trHeader.appendChild(thAcao);

    tbody.appendChild(trHeader);

    
    tableElement.appendChild(tbody);
    tabela.appendChild(tableElement);
    divTable.appendChild(tabela);
    
    createRow(socio, id, setTodosSocios)
  }

  function getPartner(idTable, idSelect, todosSocios, setTodosSocios){
    const socio = document.querySelector(`${idSelect}`).value
    
    console.log("adição sócios:  "+todosSocios)
    const divTable = document.querySelector(`${idTable}`);
    const valueTable = document.querySelector(`${idTable}`).innerHTML;
    const tabela = document.createElement('div');
    tabela.classList.add('tab-pane', 'fade', 'show', 'active');
    tabela.setAttribute('id', 'tabela');
    tabela.setAttribute('role', 'tabpanel');
    const tableElement = document.createElement('table');
    tableElement.classList.add('table', 'table-striped', 'table-bordered', 'mt-10');
    const tbody = document.createElement('tbody');
    console.log("valueTable  =  "+valueTable)
    if(valueTable == null || valueTable == '' || valueTable == undefined){
      console.log('------------tabela')
      setTodosSocios([...todosSocios, socio])
      createTable(tbody, tableElement, tabela, divTable, socio, idTable, setTodosSocios)
    } else {
      console.log('------------linha')
      if(socioExiste(socio, todosSocios)){
        console.log("sócio atual" + socio)
        alert("Esse sócio já foi inserido") // TODO: melhorar front - indicação em vermelho e divErro
      } else {
        setTodosSocios([...todosSocios, socio])
        createRow(socio, idTable, setTodosSocios)
      }
    }
  }
  
  // * Validações

  async function validateDinheiro(){
    const tipo = "dinheiro"

    // pegar valor do campo
    const valor = document.querySelector("#valor").value
    const denominacao = document.querySelector("#denominacao_dinheiro").value

    // armazenar valor para a requisição
    setValorDinheiro(valor)
    setDenominacaoDinheiro(denominacao)

    // chamada para a validação no backend
    await axios.post(`http://localhost:5000/api/validation/asset`, {tipo, valor, denominacao}, {withCredentials: true}).then((resp)=>{

      if(resp.data.status == 404){
        window.location = `/erro`
      }
    
      if(Array.isArray(resp.data.errors)){
        if(resp.data.errors.length === 0){
          nextStep();
        } else {
          for(let i=0; i<resp.data.errors.length; i++){
            console.log('entou no for')
            let elemento = document.querySelector(`#${resp.data.errors[i].elemento}`)
            elemento.placeholder = "Preencha esse campo"
            elemento.classList.add('erro')
          }
        }
      }
    })
  }

  function validateBemMovel(){
    const tipo = "bens_moveis"

    //pegar valor dos campos
    const denominacao = document.querySelector("#denominacao_moveis").value
    const valor = document.querySelector("#valor_monetario").value
    const descricao = document.querySelector("#descricao_movel").value

    // armazenar valores para a requisição
    setDenominacaoMovel(denominacao)
    setValorMovel(valor)
    setDescricaoMovel(descricao)

    // chamada para a validação no backend
    axios.post(`http://localhost:5000/api/validation/asset`, {tipo, denominacao, valor, descricao}, {withCredentials: true}).then((resp)=>{
    
      if(resp.data.status == 404){
        window.location = `/erro`
      }
      if(Array.isArray(resp.data.errors)){
        if(resp.data.errors.length === 0){
          nextStep2();
        } else {
          for(let i=0; i<resp.data.errors.length; i++){
            let elemento = document.querySelector(`#${resp.data.errors[i].elemento}`)
            elemento.placeholder = "Preencha esse campo"
            elemento.classList.add('erro')
          }
        }
      }
    })
  }
  function validateBemImovel(){
      const tipo = "bens_imoveis"
      const etapa = etapaImovel
      //  "etapa" é referente as variações do cadastro de bem imóvel
      /*
       - etapa = 1: fase inicial (geral)
       - etapa = 2: caso do bem ser rural
       - etapa = 3: caso do bem ser urbano
       - gravames = true: bem possui gravames
       - gravames = false: bem não possui gravames
       */
      
      if(etapa == 1){

        // pegar valor dos campos
        const denominacao = document.querySelector('#denominacao_imoveis').value
        const num_matricula = document.querySelector('#matricula_bem').value
        const cri = document.querySelector('#cri').value
        const gravames = selectedOptionGrav
        const comarca = document.querySelector('#comarca').value
        const tipo_imovel = selectedOptionTipo
        const cidade = document.querySelector('#cidade_bem').value
        const valor_imposto = document.querySelector('#imposto_renda').value
        const valor_mercado = document.querySelector('#valor_mercado').value
        const valor_locacao = document.querySelector('#valor_locacao').value
        const descricao = document.querySelector('#descricao_imovel').value

        // armazenar valores para a requisição
        setDenominacaoImovel(denominacao)
        setMatriculaImovel(num_matricula)
        setCriImovel(cri)
        setComarcaImovel(comarca)
        setCidadeImovel(cidade)
        setImpostoImovel(valor_imposto)
        setMercadoImovel(valor_mercado)
        setLocacaoImovel(valor_locacao)
        setDescricaoImovel(descricao)

        // chamada para a validação no backend
        axios.post(`http://localhost:5000/api/validation/asset`, {etapa, tipo, denominacao, num_matricula, cri, gravames, comarca, tipo_imovel, cidade, valor_imposto, valor_mercado, valor_locacao, descricao}, {withCredentials: true}).then((resp)=>{
          if(resp.data.status == 404){
            window.location = `/erro`
          }
          if(Array.isArray(resp.data.errors)){
            if(resp.data.errors.length === 0){
              nextStep3();
            } else {
              console.log(resp.data.errors)
              for(let i=0; i<resp.data.errors.length; i++){
                let elemento = document.querySelector(`#${resp.data.errors[i].elemento}`)
                if(resp.data.errors[i].elemento == 'gravames' || resp.data.errors[i].elemento == 'tipo'){
                  elemento.style.color = '#EF5350';
                  elemento.style.fontWeight = 'bold';
                  elemento.classList.add('erro')
                } else {
                  elemento.placeholder = "Preencha esse campo"
                  elemento.classList.add('erro')
                }
              }
            }
          }
        })
      }
      if(etapa == 2){

        // pegar valor dos campos
        const ccir = document.querySelector('#ccir').value
        const nirf = document.querySelector('#nirf').value
        const geo = selectedOptionGeo
        const area = document.querySelector('#area').value
        const valor_itr = document.querySelector('#itr').value
        const car = selectedOptionCar
        let descricao_gravames = undefined
        if(gravames){
          descricao_gravames = document.querySelector('#descricao_gravames').value
        }

        // armazenar valores para a requisição
        setCcirImovel(ccir)
        setNirfImovel(nirf)
        setAreaImovel(area)
        setItrImovel(valor_itr)
        setDescGravamesImovel(descricao_gravames)
      
        // chamada para a validação no backend
        axios.post(`http://localhost:5000/api/validation/asset`, {etapa, tipo, gravames, ccir, nirf, geo, area, valor_itr, car, descricao_gravames}, {withCredentials: true}).then((resp)=>{
          if(resp.data.status == 404){
            window.location = `/erro`
          }
          if(Array.isArray(resp.data.errors)){
            if(resp.data.errors.length === 0){
              nextStep3();
            } else {
              console.log(resp.data.errors)
              for(let i=0; i<resp.data.errors.length; i++){
                let elemento = document.querySelector(`#${resp.data.errors[i].elemento}`)
                if(resp.data.errors[i].elemento == 'geo' || resp.data.errors[i].elemento == 'car'){
                  elemento.style.color = '#EF5350';
                  elemento.style.fontWeight = 'bold';
                  elemento.classList.add('erro')
                } else {
                  elemento.placeholder = "Preencha esse campo"
                  elemento.classList.add('erro')
                }
              }
            }
          }
        })
      }
      if(etapa == 3){
        // pegar valor dos campos
        const cadastro = document.querySelector('#cadastro_municipal').value
        const valor_iptu = document.querySelector('#iptu').value
        let descricao_gravames = undefined
        if(gravames){
          descricao_gravames = document.querySelector('#descricao_gravames').value
        }

        // armazenar valores para a requisição
        setCadastroImovel(cadastro)
        setIptuImovel(valor_iptu)
        setDescGravamesImovel(descricao_gravames)

        // chamada para a validação no backend
        axios.post(`http://localhost:5000/api/validation/asset`, {etapa, tipo, gravames, cadastro, valor_iptu, descricao_gravames}, {withCredentials: true}).then((resp)=>{
          if(resp.data.status == 404){
            window.location = `/erro`
          }
          if(Array.isArray(resp.data.errors)){
            if(resp.data.errors.length === 0){
              nextStep3();
            } else {
              console.log(resp.data.errors)
              for(let i=0; i<resp.data.errors.length; i++){
                let elemento = document.querySelector(`#${resp.data.errors[i].elemento}`)
                elemento.placeholder = "Preencha esse campo"
                elemento.classList.add('erro')
              }
            }
          }
        })
      }
  }
  function validateAcoes(){
    const tipo = "acoes"

    // pegar valor dos campos
    const denominacao = document.querySelector('#denominacao_acoes').value
    const razao_social = document.querySelector('#razao_social_acoes').value
    const cnpj_me = document.querySelector('#cnpj_me_acoes').value
    const nire = document.querySelector('#nire_acoes').value
    const valor_capital = document.querySelector('#valor_capital_acoes').value
    const valor_patrimonio = document.querySelector('#valor_patrimonio_acoes').value
    const num_quotas = document.querySelector('#num_quotas').value
    const valor_quotas = document.querySelector('#valor_quotas').value

    // armazenar valores para a requisição
    setDenominacaoAcoes(denominacao)
    setRazaoSocialAcoes(razao_social)
    setCnpjMeAcoes(cnpj_me)
    setNireAcoes(nire)
    setCapitalAcoes(valor_capital)
    setPatrimonioAcoes(valor_patrimonio)
    setNumAcoes(num_quotas)
    setValorAcoes(valor_quotas)

    // chamada para a validação no backend
    axios.post(`http://localhost:5000/api/validation/asset`, {tipo, denominacao, razao_social, cnpj_me, nire, valor_capital, valor_patrimonio, num_quotas, valor_quotas}, {withCredentials: true}).then((resp)=>{
      if(resp.data.status == 404){
        window.location = `/erro`
      }
      
      if(Array.isArray(resp.data.errors)){
        if(resp.data.errors.length === 0){
          nextStep4();
        } else {
          console.log(resp.data.errors)
          for(let i=0; i<resp.data.errors.length; i++){
            let elemento = document.querySelector(`#${resp.data.errors[i].elemento}`)
            elemento.placeholder = "Preencha esse campo"
            elemento.classList.add('erro')
          }
        }
      }
    })
  }

  // * Requisições para API
  
  function registerDinheiro(){
    const acao = "create"
    const tipo = "dinheiro"
    // recupera o valor do campo
    const valor = formatMoneyForDatabase(valorDinheiro)
    const denominacao = denominacaoDinheiro
    const socios = [...todosSocios1]

    // chamada para a criação do bem no backend
    axios.post(`http://localhost:5000/api/actions/assets`, {acao, tipo, valor, denominacao, socios}, {withCredentials: true}).then((resp)=>{
      if(resp.data.status == 404){
        window.location = `/erro`
      }
      if(resp.data.status == 200){
          window.location = `/assets/view-assets`
      }
    })
  }

  function registerBemMovel(){
    const acao = "create"
    const tipo = "bens_moveis"

    // recupera os valores dos campos
    const denominacao = denominacaoMovel
    const valor = formatMoneyForDatabase(valorMovel)
    const descricao = descricaoMovel
    const socios = [...todosSocios2]

    // chamada para a criação do bem no backend
    axios.post(`http://localhost:5000/api/actions/assets`, {acao, tipo, denominacao, valor, descricao, socios}, {withCredentials: true}).then((resp)=>{
      if(resp.data.status == 404){
        window.location = `/erro`
      }
      if(resp.data.status == 200){
          window.location = `/assets/view-assets`
      }
    })
  }

  function registerBemImovel(){
    const acao = "create"
    const tipo = "bens_imoveis"
    const etapa = etapaImovel
    // "etapa" é referente as variações do cadastro de bem imóvel
    /*
    - etapa = 1: fase inicial (geral)
    - etapa = 2: caso do bem ser rural
    - etapa = 3: caso do bem ser urbano
    - gravames = true: bem possui gravames
    - gravames = false: bem não possui gravames
    */
    
    // recupera o valor dos campos
    const denominacao = denominacaoImovel
    const num_matricula = matriculaImovel
    const cri = criImovel
    const gravames = selectedOptionGrav
    const comarca = comarcaImovel
    const cidade = cidadeImovel
    const valor_imposto = formatMoneyForDatabase(impostoImovel)
    const valor_mercado = formatMoneyForDatabase(mercadoImovel)
    const valor_locacao = formatMoneyForDatabase(locacaoImovel)
    const descricao = descricaoImovel
    let ccir = null, nirf = null, geo = null, area = null, valor_itr = null, car = null // rural
    let cadastro = null, valor_iptu = null // urbano
    let descricao_gravames = null //gravames
    const socios = [...todosSocios3]
    if(etapa == 2){
      ccir = ccirImovel
      nirf = nirfImovel
      geo = selectedOptionGeo
      area = formatAreaForDatabase(areaImovel)
      valor_itr = formatMoneyForDatabase(itrImovel)
      car = selectedOptionCar
    }

    if(etapa == 3){
      cadastro = cadastroImovel
      valor_iptu = formatMoneyForDatabase(iptuImovel)
    }

    if(gravames){
      descricao_gravames = descGravamesImovel
    }

    // chamada para a criação do bem no backend
    axios.post(`http://localhost:5000/api/actions/assets`, {acao, etapa, tipo, denominacao, num_matricula, cri, gravames, comarca, cidade, valor_imposto, valor_mercado, valor_locacao, descricao, ccir, nirf, geo, area, valor_itr, car, cadastro, valor_iptu, descricao_gravames, socios}, {withCredentials: true}).then((resp)=>{
      if(resp.data.status == 404){
        window.location = `/erro`
      }
      if(resp.data.status == 200){
        window.location = `/assets/view-assets`
      }
    })
  }

  function registerAcoes(){
    const acao = "create"
    const tipo = "acoes"
    // recupera o valor dos campos
    const denominacao = denominacaoAcoes
    const razao_social = razaoSocialAcoes
    const cnpj_me = cnpjMeAcoes
    const nire = nireAcoes
    const valor_capital = formatMoneyForDatabase(capitalAcoes)
    const valor_patrimonio = formatMoneyForDatabase(patrimonioAcoes)
    const num_quotas = numAcoes
    const valor_quotas = formatMoneyForDatabase(valorAcoes)
    const socios = [...todosSocios4]

    // chamada para a criação do bem no backend
    axios.post(`http://localhost:5000/api/actions/assets`, {acao, tipo, denominacao, razao_social, cnpj_me, nire, valor_capital, valor_patrimonio, num_quotas, valor_quotas, socios}, {withCredentials: true}).then((resp)=>{
      if(resp.data.status == 404){
        window.location = `/erro`
      }
      if(resp.data.status == 200){
          window.location = `/assets/view-assets`
      }
    })
  }

  // * Função dos gravames (bens imóveis)

  function returnGravames(){
    return(
      <div className='row'>
        <div className="col-md-12">
          <div className="form-group">
            <i class="fa fa-comment"></i>
            <label style={{marginLeft: 3}}>Descrição gravames:</label>
            <textarea id="descricao_gravames" class="form-control" name="descricao_gravames" rows="6" placeholder="Descreva aqui o gravame..."></textarea>
          </div>
        </div>
      </div>  
    );
  }

  // * Função para bens imóveis rurais

  function returnRural(){
    return (
      <div>
        <h4 style={{marginTop: 5}}>Passo 2</h4>
        <div className='row'>
          <div className='form-group col-4'>
            <label>Número do CCIR:</label>
            <input type='text' class='form-control' name='ccir' id='ccir' onChange={formatNumeric}/>
          </div>
          <div className='form-group col-4'>
            <label>Número do NIRF:</label>
            <input type='text' class='form-control' name='nirf' id='nirf' onChange={formatNumeric}/>
          </div>
          
          <div className='form-group col-4 text-center'>
              <label id="geo">Possui Georreferenciamento?</label>
              <div className='form-group text-center' id='custom-radio'>
                <input type="radio" name="georreferenciamento" id="sim_geo" value="sim_geo" onChange={handleRadioChangeGeo}/>
                <label style={{padding: 7}} for="sim_geo">Sim</label>

                <input type="radio" name="georreferenciamento" id="nao_geo" value="nao_geo" onChange={handleRadioChangeGeo}/>
                <label style={{padding: 7}} for="nao_geo">Não</label>
              </div> 
            </div>
        </div>
        <div className='row'>
          <div className='form-group col-4'>
            <label>Área do imóvel em hectares:</label>
            <input type='text' class='form-control' name='area' id='area' onChange={formatArea}/>
          </div>
          <div className='form-group col-4'>
            <label>Valor constante no ITR:</label>
            <input type='text' class='form-control' name='itr' id='itr' onChange={formatMoney}/>
          </div>
          <div className='form-group col-4 text-center'>
            <label id="car">Possui CAR?</label>
            <div className='form-group text-center' id='custom-radio'>
              <input type="radio" name="car" id="sim_car" value="sim_car" onChange={handleRadioChangeCar}/>
              <label style={{padding: 7}} for="sim_car">Sim</label>

              <input type="radio" name="car" id="nao_car" value="nao_car" onChange={handleRadioChangeCar}/>
              <label style={{padding: 7}} for="nao_car">Não</label>
            </div> 
          </div> 
        </div>
      </div>
    );
  }

  // * Função para bens imóveis urbanos

  function returnUrbano(){
    return(
      <div>
        <h4 style={{marginTop: 5}}>Passo 2</h4>
        <div className='row'>
          <div className='form-group col-6'>
            <label>Cadastro Municipal:</label>
            <input type='text' class='form-control' name='cadastro_municipal' id='cadastro_municipal'/>
          </div>
          <div className='form-group col-6'>
            <label>Valor constante do IPTU:</label>
            <input type='text' class='form-control' name='iptu' id='iptu' onChange={formatMoney}/>
          </div>
        </div>
      </div>
    );
  }

  // * Funções de rederização das step's

  // Dinheiro
  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <div>
            <h4 style={{marginTop: 5}}>Passo 1</h4>
            
            <div id="stepmoney">
                <div className='row'>
                    <div className='col-6'>
                        <label for="denominacao_dinheiro" className="required">Denominação:</label>
                    </div>   
                    <div className='col-4'>
                        <label for="valor" className="required">Valor (em reais):</label>
                    </div>    
                </div>
                <div ca className='row'>
                    <div class="form-group col-6">
                        <input type="text" name="denominacao_dinheiro" id="denominacao_dinheiro" class="form-control"/>
                    </div>
                    <div class="form-group col-4">
                        <input type="text" name="valor" id="valor" class="form-control" onChange={formatMoney}/>
                    </div>
                    <div className='col-2'>
                        <button className="btn btn-gray" onClick={validateDinheiro}>Próximo</button>
                    </div>
                </div>
            </div>
          </div>
        );
      case 2:
        return (
          <div>
            <h4 style={{marginTop: 5}}>Passo 2</h4>
            <div className='row'>
              <div className='col-12'>
                <label>Vincular sócios</label>
              </div>
            </div>
            <div className='row'>
              <div className='form-group col-11'>
                <select class="form-control select" style={{width: '100%'}} aria-hidden='true' name='socio' id="vincular_socio1">
                  <option>Pedro Enrico Barchi Nogueira</option>
                  <option>Sócio 2</option>
                  <option>Sócio 3</option>
                  <option>Sócio 4</option>
                  <option>Sócio 5</option>
                </select>
              </div>
              <div className='col-1'>
                <button class="add btn btn-gray" onClick={() => getPartner('#table1', '#vincular_socio1',todosSocios1, setTodosSocios1)}><i class="fas fa-plus"/></button>
              </div>
            </div>
      
            <br />
            <div className="card-body table-responsive p-0">
              <div id="table1"></div>
            </div>
            <div className='row'>
              <div style={{marginTop: 10}} class="col-12 text-center">
                <button style={{marginRight: 5}} class='btn btn-gray' onClick={()=>{prevStep()}}>Voltar</button>
                <button class='enviar btn btn-gray' onClick={registerDinheiro}>Enviar</button>
              </div>
            </div>
            
          </div>
        );
      default:
        return null;
    }
  };
  // Bens móveis
  const renderStep2 = () => {
    switch (step2) {
      case 1:
        return (
          <div>
            <h4 style={{marginTop: 5}}>Passo 1</h4>
            <div className='row'>
                <div className='col-6'>
                    <label for="nome" className='required'>Denominação:</label>
                </div>
                <div className='col-6'>
                    <label for="nome" className='required'>Valor monetário:</label>
                </div>    
            </div>
            <div className='row'>
              <div class="form-group col-6">
                  <input type="text" name="denominacao" id="denominacao_moveis" class="form-control"/>
              </div>
              <div class="form-group col-6">
                  <input type="text" name="valor_monetario" id="valor_monetario" class="form-control" onChange={formatMoney}/>
              </div>
            </div>
            <div className='row'>
              <div className="col-md-12">
                <div className="form-group">
                  <i class="fa fa-comment"></i>
                  <label style={{marginLeft: 3}} className='required'>Descreva o bem:</label>
                  <textarea id="descricao_movel" class="form-control" name="descricao_movel" rows="6" placeholder="Descreva aqui o bem inserido..."></textarea>
                </div>
                <div class="col-xs-12 text-center">
                  <button className="btn btn-gray" onClick={validateBemMovel}>Próximo</button>
                </div>
              </div>
            </div>  
          </div>
        );
      case 2:
        return (
          <div>
            <h4 style={{marginTop: 5}}>Passo 2</h4>
            <div className='row'>
              <div className='col-12'>
                <label>Vincular sócios</label>
              </div>
            </div>
            <div className='row'>
              <div className='col-11'>
                <select class="form-control select" style={{width: '100%'}} aria-hidden='true' name='socio' id="vincular_socio2">
                  <option>Sócio 1</option>
                  <option>Sócio 2</option>
                  <option>Sócio 3</option>
                  <option>Sócio 4</option>
                  <option>Sócio 5</option>
                </select>
              </div>
              <div className='col-1'>
                <button class="add btn btn-gray" onClick={() => getPartner('#table2', '#vincular_socio2', todosSocios2, setTodosSocios2)}><i class="fas fa-plus"/></button>
              </div>
            </div>
            <br />
            <div className="card-body table-responsive p-0">
              <div id="table2"></div>
            </div>
            <div className='row'>
              <div style={{marginTop: 10}} class="col-12 text-center">
                <button style={{marginRight: 5}} class='btn btn-gray' onClick={()=>{prevStep2()}}>Voltar</button>
                <button class='enviar btn btn-gray' onClick={registerBemMovel}>Enviar</button>
              </div>
            </div>
          </div>
        );
      default:
        return null;
    }
  };
  // Bens imóveis
  const renderStep3 = () => {
    switch (step3) {
      case 1:
        if(etapaImovel != 1){
          setEtapaImovel(1)
        }
        return (
          <div>
            <h4 style={{marginTop: 5}}>Passo 1</h4>
            <div className='row'>
              <div class="form-group col-12">
                <label className='required'>Denominação:</label>
                <input type="text" name="denominacao" id="denominacao_imoveis" class="form-control"/>
              </div>
            </div>
            <div className='row'>
              <div className='form-group col-5'>
                <label className='required'>Número de matrícula do bem:</label>
                <input type="text" name="matricula_bem" id="matricula_bem" class="form-control" onChange={formatNumeric}/>
              </div>
              <div className='form-group col-4'>
                <label className='required'>Número do CRI:</label>
                <input type='text' name='cri' id='cri' class='form-control' onChange={formatNumeric}/>
              </div>
              <div className='form-group col-3 text-center'>
                <label id='gravames' className='required'>Possui gravames?</label>
                <div className='form-group text-center' id='custom-radio'>
                  <input type="radio" name="gravames" id="sim" value="sim" onClick={()=>{setGravames(true)}} onChange={handleRadioChangeGrav}/>
                  <label style={{marginLeft: 2, padding: 7}} for="sim">Sim</label>

                  <input type="radio" name="gravames" id="nao" value="nao" onClick={()=>{setGravames(false)}} onChange={handleRadioChangeGrav}/>
                  <label style={{marginLeft: 8, padding: 7}} for="nao">Não</label>
                </div> 
              </div>
            </div>
            <div className='row'>
              <div className='form-group col-4'>
                <label className='required'>Comarca do bem:</label>
                <input type="text" name="comarca" id="comarca" class="form-control" onChange={formatAlphabetical}/>
              </div>
              <div className='form-group col-3 text-center'>
                <label id="tipo" className='required'>Tipo do imóvel:</label>
                <div style={{marginTop: 2}} className='form-group text-center' id='custom-radio'>
                  <input type="radio" name="tipo_imovel" id="rural" value="rural" onClick={()=>{setTipoImovel(0)}} onChange={handleRadioChangeTipo}/>
                  <label style={{marginLeft: 2}} for="rural">Rural</label>

                  <input type="radio" name="tipo_imovel" id="urbano" value="urbano" onClick={()=>{setTipoImovel(1)}} onChange={handleRadioChangeTipo}/>
                  <label for="urbano">Urbano</label>
                </div> 
              </div>
              <div className='form-group col-5'>
                <label className='required'>Cidade em que o bem está situado:</label>
                <input type="text" name="cidade_bem" id="cidade_bem" class="form-control" onChange={formatAlphabetical}/>
              </div>
            </div>
            <div className='row'>
              <div className='form-group col-4'>
                <label className='required'>Valor do imposto de renda:</label>
                <input type="text" name="imposto_renda" id="imposto_renda" class="form-control" onChange={formatMoney}/>
              </div>
              <div className='form-group col-4'>
                <label className='required'>Valor de mercado:</label>
                <input type='text' name='valor_mercado' id='valor_mercado' class='form-control' onChange={formatMoney}/>
              </div>
              <div className='form-group col-4'>
                <label className='required'>Valor recebido de locação:</label>
                <input type='text' name='valor_locacao' id='valor_locacao' class='form-control' onChange={formatMoney}/>
              </div>
            </div>
            <div className='row'>
              <div className="col-md-12">
                <div className="form-group">
                  <i class="fa fa-comment"></i>
                  <label style={{marginLeft: 3}} className='required'>Descreva o bem:</label>
                  <textarea id="descricao_imovel" class="form-control" name="descricao_imovel" rows="6" placeholder="Descreva aqui o bem inserido..."></textarea>
                </div>
              </div>
            </div>
          <div class="col-xs-12 text-center">
            <button className="btn btn-gray" onClick={validateBemImovel}>Próximo</button>
          </div>
          </div>
        );
      case 2:
        if(tipoImovel == 0 && gravames == false){
          if(etapaImovel != 2){
            setEtapaImovel(2)
          }
          return(
            <div>
              {returnRural()}
              <div className='row'>
                <div class="col-12 text-center">
                  <button style={{marginRight: 5}} class='btn btn-gray' onClick={prevStep3}>Voltar</button>
                  <button class='btn btn-gray' onClick={validateBemImovel}>Próximo</button>
                </div>
              </div>
            </div>
          );
        }
        else if(tipoImovel == 0 && gravames == true){
          if(etapaImovel != 2){
            setEtapaImovel(2)
          }
          return(
            <div>
              {returnRural()}
              {returnGravames()}
              <div className='row'>
                <div class="col-12 text-center">
                  <button style={{marginRight: 5}} class='btn btn-gray' onClick={prevStep3}>Voltar</button>
                  <button class='btn btn-gray' onClick={validateBemImovel}>Próximo</button>
                </div>
              </div>
            </div>
          );
        }
        else if(tipoImovel == 1 && gravames == false){
          if(etapaImovel != 3){
            setEtapaImovel(3)
          }
          return(
            <div>
              {returnUrbano()}
              <div className='row'>
                <div class="col-12 text-center">
                  <button style={{marginRight: 5}} class='btn btn-gray' onClick={prevStep3}>Voltar</button>
                  <button class='btn btn-gray' onClick={validateBemImovel}>Próximo</button>
                </div>
              </div>
            </div>
          );
        }
        else if(tipoImovel == 1 && gravames == true){
          if(etapaImovel != 3){
            setEtapaImovel(3)
          }
          return(
            <div>
              {returnUrbano()}
              {returnGravames()}
              <div className='row'>
                <div class="col-12 text-center">
                  <button style={{marginRight: 5}} class='btn btn-gray' onClick={prevStep3}>Voltar</button>
                  <button class='btn btn-gray' onClick={validateBemImovel}>Próximo</button>
                </div>
              </div>
            </div>
          );
        }

        
      case 3:
        return (
          <div>
            <h4 style={{marginTop: 5}}>Passo 3</h4>
            <div className='row'>
              <div className='col-12'>
                <label>Vincular sócios</label>
              </div>
            </div>
            <div className='row'>
              <div className='col-11'>
                <select class="form-control select" style={{width: '100%'}} aria-hidden='true' name='socio' id="vincular_socio3">
                  <option>Sócio 1</option>
                  <option>Sócio 2</option>
                  <option>Sócio 3</option>
                  <option>Sócio 4</option>
                  <option>Sócio 5</option>
                </select>
              </div>
              <div className='col-1'>
                  <button class="add btn btn-gray" onClick={() => getPartner('#table3', '#vincular_socio3', todosSocios3, setTodosSocios3)}><i class="fas fa-plus"/></button>
              </div> 
            </div>
            <br />
            <div className="card-body table-responsive p-0">
              <div id="table3"></div>
            </div>
            <div className='row'>
              <div style={{marginTop: 10}} class="col-12 text-center">
                <button style={{marginRight: 5}} class='btn btn-gray' onClick={()=>{prevStep3()}}>Voltar</button>
                <button class='enviar btn btn-gray' onClick={registerBemImovel}>Enviar</button>
              </div>
            </div>
          </div>
        );
      default:
        return null;
    }
  };
  // Quotas ou Ações
  const renderStep4 = () => {
    switch (step4) {
      case 1:
        return (
          <div>
            <h4 style={{marginTop: 5}}>Passo 1</h4>
            <div className='row'>
              <div class="form-group col-12">
                <label>Denominação:</label>
                <input type="text" class="form-control" id="denominacao_acoes"/>
              </div>
            </div>
            <div className='row'>
              <div className='form-group col-6'>
                <label>Informe a razão social da empresa:</label>
                <input type='text' class='form-control' id="razao_social_acoes"/>
              </div>
              <div className='form-group col-3'>
                <label>Informe o CNPJ/ME:</label>
                <input type='text'  class='form-control' onChange={formatCnpj} id="cnpj_me_acoes"/>
              </div>
              <div className='form-group col-3'>
                <label>Informe o NIRE:</label>
                <input type='text' class='form-control' id="nire_acoes" onChange={formatNumeric}/>
              </div>
            </div>
            <div className='row'>
              <div className='form-group col-6'>
                <label>Valor do capital social:</label>
                <input type='text' class='form-control' onChange={formatMoney} id="valor_capital_acoes"/>
              </div>
              <div className='form-group col-6'>
                <label>Valor do patrimônio líquido:</label>
                <input type='text' class='form-control' onChange={formatMoney} id="valor_patrimonio_acoes"/>
              </div>
            </div>
            <div className='row'>
              <div className='form-group col-6'>
                <label>Informe o número de quotas ou ações:</label>
                <input type='text' class='form-control' id="num_quotas" onChange={formatNumeric}/>
              </div>
              <div className='form-group col-6'>
                <label>Informe o valor das quotas ou ações:</label>
                <input type='text' class='form-control' onChange={formatMoney} id="valor_quotas"/>
              </div>
              <div class="col-12 text-center">
                <button class='btn btn-gray' onClick={validateAcoes}>Próximo</button>
              </div>
            </div>
            
          </div>
        );
      case 2:
        return (
        <div>
          <h4 style={{marginTop: 5}}>Passo 2</h4>
          <div className='row'>
            <div className='col-12'>
              <label>Vincular sócios</label>
            </div>
          </div>
          <div className='row'>
            <div className='col-11'>
              <select class="form-control select" style={{width: '100%'}} aria-hidden='true' name='socio' id="vincular_socio4">
                <option>Sócio 1</option>
                <option>Sócio 2</option>
                <option>Sócio 3</option>
                <option>Sócio 4</option>
                <option>Sócio 5</option>
              </select>
            </div>
            <div className='col-1'>
              <button type="button" class="add btn btn-gray" onClick={() => getPartner('#table4', '#vincular_socio4', todosSocios4, setTodosSocios4)}><i class="fas fa-plus"/></button>
            </div> 
          </div>
          <div className='row'>
            <div className='col-12'>
              <br />
              <div className="card-body table-responsive p-0">
                <div id="table4"></div>
              </div>
            </div>
            
          </div>
          
          <div className='row'>
            <div style={{marginTop: 10}} class="col-12 text-center">
              <button style={{marginRight: 5}} class='btn btn-gray' onClick={()=>{prevStep4()}}>Voltar</button>
              <button class='enviar btn btn-gray' onClick={registerAcoes}>Enviar</button>
            </div>
          </div>
        </div>
        );
      default:
        return null;
    }
  };

  return (
    <div>
        <AppHeader/>
        <AppMenu/>
        <div class="content-wrapper">
            <section class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-6">
                            <h1>Criando novo bem</h1>
                        </div>
                        <div class="col-sm-6">
                            <ol class="breadcrumb float-sm-right">
                                <li class="breadcrumb-item"><a href="/">Início</a></li>
                                <li class="breadcrumb-item active">Criando bem</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- Content Header (Page header) --> */}
            <section class="content-header">
              <div class="container-fluid">
                <div class="card card-default">
                  <div class="card-body">
                    <Tabs activeKey={tab} onSelect={(key) => setTab(parseInt(key))}>
                        <Tab eventKey={1} title="Dinheiro">
                        <form onSubmit={handleFormSubmit}>
                            {renderStep()}
                        </form>
                        </Tab>
                        <Tab eventKey={2} title="Bens Móveis">
                        <form onSubmit={handleFormSubmit}>
                            {/* Campos do formulário da Tab 2 */}
                            {renderStep2()}
                            
                        </form>
                        </Tab>
                        <Tab eventKey={3} title="Bens Imóveis">
                        <form onSubmit={handleFormSubmit}>
                            {/* Campos do formulário da Tab 3 */}
                            {renderStep3()}
                            
                        </form>
                        </Tab>
                        <Tab eventKey={4} title="Quotas ou Ações">
                        <form onSubmit={handleFormSubmit}>
                            {/* Campos do formulário da Tab 3 */}
                            {renderStep4()}
                            
                        </form>
                        </Tab>
                    </Tabs>
                  </div>
                </div>
              </div>
            </section> 
            </div>
        <AppFooter/>
        <AppSetting/>
    </div>
  )

}

export default CreateAsset;