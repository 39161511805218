import AppHeader from "../../basic/app-header/AppHeader";
import AppMenu from "../../basic/app-menu/AppMenu";
import AppFooter from "../../basic/app-footer/AppFooter";
import AppSetting from "../../basic/app-setting/AppSetting";
import { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import { Button, Container, Typography, Box } from "@mui/material";
import { styled } from "@mui/system";

const OptionBox = styled(Box)(({ selected }) => ({
    padding: 16,
    cursor: "pointer",
    textAlign: "left",
    position: "relative",
    "&::before": {
        content: '""',
        position: "absolute",
        bottom: 0,
        left: 0,
        width: "70%",
        height: "3px",
        backgroundColor: "#d3d3d3", // Cor da linha cinza claro
	boxShadow: selected ? '0 6px 8px rgba(0, 0, 0, 0.8)' : 'none',

    },
    "&::after": {
        content: '""',
        position: "absolute",
        bottom: 0,
        left: 0,
        width: selected ? "70%" : "0",
        height: "3px",
        backgroundColor: "#000",
        transition: "width 0.3s ease",
    },
    "&:hover::after": {
        width: "70%",
    },
    "& p": {
        fontSize: "2rem", // Aumenta o tamanho da fonte
        fontWeight: 600, // Define o texto como semi-bold
        margin: 0,
    },
}));

export default function AnalysisHome() {
    const [selectedOption, setSelectedOption] = useState(null);

    const content = {
        Contrato: {
            title: "Análise de Contrato",
            description: `A funcionalidade de Análise de Contrato foi desenvolvida 
para agilizar o processo de revisão de documentos jurídicos, proporcionando 
aos advogados uma ferramenta eficiente para iniciar seu trabalho. Abaixo estão 
os detalhes sobre o uso e funcionamento desta funcionalidade:
        
- **Envio de Contrato:** O advogado pode enviar um contrato diretamente 
pelo sistema.
- **Formulário de Detalhamento:** Junto ao envio do contrato, um formulário 
deve ser preenchido com as seguintes informações:
  - **Tipo de Contratante e Contratado:** Identifique as partes envolvidas 
  no contrato.
  - **Natureza do Contrato:** Selecione se o contrato é de natureza 
  empresarial ou trabalhista.
  - **Foco da Análise:** Especifique o principal objetivo da análise.
  - **Descrição da Análise:** Forneça uma breve descrição, 
  entitulando sua análise.
        
- **Processamento por IA:** O contrato, juntamente com as informações 
fornecidas no formulário, é enviado para nosso sistema. Utilizando uma IA, 
o sistema aplica prompts pré-configurados para realizar uma análise inicial 
do documento.
        
- **Resultados da Análise:** A análise inicial visa poupar tempo e fornecer 
uma base para o trabalho do advogado. No entanto, é importante observar que:
  - **Conferência Necessária:** As informações geradas pela IA podem não 
  ser 100% precisas.
  - **Verificação dos Dados:** É imprescindível que os advogados verifiquem 
  e validem todas as informações antes de procederem com suas conclusões e 
  ações jurídicas.
        
Esta ferramenta foi projetada para otimizar seu tempo e auxiliar no início 
de suas análises contratuais, mas a responsabilidade final pela precisão 
e validade das informações permanece com o profissional jurídico.`,
            route: "contract",
        },
        Sentença: {
            title: "Análise de Sentença",
            description: `A funcionalidade de Análise de Sentença foi criada para 
acelerar o processo de revisão de sentenças jurídicas, proporcionando aos 
advogados uma ferramenta eficiente e precisa para iniciar seu trabalho. 
Abaixo estão os detalhes sobre o uso e funcionamento desta funcionalidade:

- **Envio da Sentença:** O advogado pode enviar uma sentença diretamente 
pelo sistema.
- **Formulário de Detalhamento:** Junto ao envio da sentença, um formulário 
deve ser preenchido com as seguintes informações:
  - **Partes Envolvidas:** Identifique se as partes são autor ou réu.
  - **Natureza da Sentença:** Selecione se a sentença é de natureza 
  empresarial ou trabalhista.
  - **Descrição da Análise:** Forneça uma breve descrição, 
  entitulando sua análise.
        
- **Processamento por IA:** A sentença, juntamente com as informações 
fornecidas no formulário, é enviada para nosso sistema. Utilizando uma IA 
, o sistema aplica prompts pré-configurados para realizar uma análise 
inicial do documento.
        
- **Resultados da Análise:** A análise inicial visa poupar tempo e fornecer 
uma base para o trabalho do advogado. No entanto, é importante observar que:
  - **Conferência Necessária:** As informações geradas pela IA podem não 
  ser 100% precisas.
  - **Verificação dos Dados:** É imprescindível que os advogados verifiquem 
  e validem todas as informações antes de procederem com suas conclusões e 
  ações jurídicas.
        
Esta ferramenta foi projetada para otimizar seu tempo e auxiliar no início 
de suas análises de sentenças, mas a responsabilidade final pela precisão 
e validade das informações permanece com o profissional jurídico.`,
            route: "sentence",
        },
    };

    const handleOptionClick = (option) => {
        setSelectedOption(option);
    };

    const handleNextClick = () => {
        if (selectedOption) {
            window.location.href = `/request/analysis/${content[selectedOption].route}`;
        } else {
            // Handle case where no option is selected
            console.log("No option selected");
        }
    };

    return (
        <>
            <AppHeader />
            <AppMenu />
            <div className="content-wrapper">
                <section class="content-header" style={{ marginBottom: -5 }}>
                    <div class="container-fluid">
                        <div class="row mb-2">
                            <div class="col-sm-6">
                                <h1>Análise do serviço</h1>
                            </div>
                            <div class="col-sm-6">
                                <ol class="breadcrumb float-sm-right">
                                    <li class="breadcrumb-item">
                                        <a href="/">Início</a>
                                    </li>
                                    <li class="breadcrumb-item active">
                                        Análise do serviço
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="content">
                    <div className="container-fluid">
                        <div className="card card-default">
                            <div className="card-body analysis-home">
                                <div
                                    className={`contract-home ${
                                        selectedOption ? "visible" : "blank"
                                    }`}
                                >
                                    {selectedOption ? (
                                        <>
                                            <h3 className="contract-title">
                                                {content[selectedOption].title}
                                            </h3>
                                            <div className="result-body-home markdown-content">
                                                <ReactMarkdown>
                                                    {
                                                        content[selectedOption]
                                                            .description
                                                    }
                                                </ReactMarkdown>
                                            </div>
                                        </>
                                    ) : (
                                        <div
                                            className="placeholder-text center-element"
                                            style={{
                                                color: "#6c757d",
                                                fontSize: "1.1rem",
                                            }}
                                        >
                                            Por favor, selecione uma opção para
                                            continuar.
                                        </div>
                                    )}
                                </div>

                                <Container maxWidth="sm">
                                    <Typography
                                        variant="subtitle1"
                                        gutterBottom
                                    >
                                        Serviços jurídicos:
                                    </Typography>
                                    <OptionBox
                                        onClick={() =>
                                            handleOptionClick("Contrato")
                                        }
                                        selected={selectedOption === "Contrato"}
                                    >
                                        <Typography>Contrato</Typography>
                                    </OptionBox>
                                    <OptionBox
                                        onClick={() =>
                                            handleOptionClick("Sentença")
                                        }
                                        selected={selectedOption === "Sentença"}
                                    >
                                        <Typography>Sentença</Typography>
                                    </OptionBox>
                                    <Box mt={30} textAlign="left">
                                        <Button
                                            variant="contained"
                                            sx={{ backgroundColor: "#6C757D" }}
                                            onClick={handleNextClick}
                                        >
                                            Próximo
                                        </Button>
                                    </Box>
                                </Container>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <AppFooter />
            <AppSetting />
        </>
    );
}

