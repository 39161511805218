
function ItemMenuAdminUsers() {
    return(
        <li className="nav-item">
            <a href="/adm/admin-users" className="nav-link">
            <i className="nav-icon fas fa-users"></i>
            <p>
                Administradores - Cliente
            </p>
            </a>
        </li>
    );
}

export default ItemMenuAdminUsers;