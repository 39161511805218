import React, { useEffect, useState, useCallback, useRef } from 'react';
import AppHeader from '../../../basic/app-header/AppHeader';
import AppMenu from '../../../basic/app-menu/AppMenu';
import AppFooter from '../../../basic/app-footer/AppFooter';
import AppSetting from '../../../basic/app-setting/AppSetting';
import { postAxios } from '../../../../hooks/useAxios';

function TableStatus() {
    const [expandedRows, setExpandedRows] = useState([]);
    const [services, setServices] = useState(null);
    const tableRef = useRef(null);
    // const [services, setServices] = useState([
    //     {
    //         id: 1,
    //         status: 'concluído',
    //         solicitant: 'Pedro',
    //         solicitantEmail: 'pedro@email.com',
    //         type: 1,
    //         department: 'Dep1',
    //         nature: 'Trabalhista',
    //         sendDate: '12/08/23 12:45',
    //         returnDate: '15/08/23 14:00',
    //         request: 'pedido de teste1',
    //         comment: 'comentário adicional 1',
    //         files: [{name: 'link1.pdf'}],
    //     },
    //     {
    //         id: 2,
    //         status: 'processando',
    //         solicitant: 'Jonathan',
    //         solicitantEmail: 'jonathan@email.com',
    //         type: 2,
    //         department: 'Dep2',
    //         nature: 'Empresarial',
    //         sendDate: '02/07/23 12:45',
    //         returnDate: null,
    //         request: 'pedido de teste2',
    //         comment: 'comentário adicional 2',
    //         files: [{name: 'link2.pdf'}, {name: 'link2-1.pdf'}, {name: 'link2-2.pdf'},{name: 'linkdeumarquivodenomegrnade.pdf'}],
    //     }
    // ]);

    const getServices = useCallback(async () => {
            postAxios('/api/get/logs/ssj/')
              .then((resp) => {
                  setServices(resp.data.logs)
                  
              })
              .catch((error) => {
                  window.location.href = "/bad/request";
                  console.error('Erro na solicitação POST:', error);
              })
    }, []);

    useEffect(() => {
        getServices();
    }, [getServices]);

    useEffect(() => {
        // Função para fechar linhas expandidas quando clicar fora da tabela
        const handleClickOutsideTable = (event) => {
            if (tableRef.current && !tableRef.current.contains(event.target)) {
                setExpandedRows([]);
            }
        };

        // Adiciona o event listener para capturar cliques fora da tabela
        document.addEventListener('click', handleClickOutsideTable);

        // Remove o event listener quando o componente for desmontado
        return () => {
            document.removeEventListener('click', handleClickOutsideTable);
        };
    }, []);

    const toggleExpandRow = (id) => {
        if (expandedRows.includes(id)) {
          setExpandedRows(expandedRows.filter((rowId) => rowId !== id)); // Collapse the clicked row
        } else {
          setExpandedRows([...expandedRows, id]); // Expand the clicked row
        }
    };

    if(services === null) {
        return(
            <>
            <AppHeader/>
            <AppMenu/>
            <div className='content-wrapper'>
                <div className='card-body'>
                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100vh"
                    }}>
                        <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>
            </div>
            <AppFooter/>
            <AppSetting/>
            </>
        ); 
    }

    return(
        <>
        <AppHeader/>
        <AppMenu/>
        <div className="content-wrapper">
            {/* <!-- Content Header (Page header) --> */}
            <section className="content-header" style={{marginBottom: -5}}>
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6"/>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href="/">Início</a></li>
                                <li className="breadcrumb-item active">SSJ</li>
                                <li className="breadcrumb-item active">Status de solicitações</li>
                            </ol>
                        </div>
                    </div>
                </div>
                {/* <!-- /.container-fluid --> */}
            </section>
            {/* <!-- Main content --> */}
            <section className="content">
            <div className="container-fluid">
                <div className="row">
                <div className="col-12">
                    <div className="card">
                    <div className="card-header">
                        <h3 className="card-title" style={{fontWeight: 600}}>Solicitações de serviços</h3>
                    </div>
                    {/* <!-- /.card-header --> */}
                    <div className="card-body">
                        <table id="example1" className="table table-bordered table-striped dataTable dtr-inline">
                        <thead>
                        <tr>
                            <th>Nº da pasta</th>
                            <th>Solicitante</th>
                            <th>Tipo</th>
                            <th>Departamento</th>
                            <th>Natureza</th>
                            <th>Data(Envio)</th>
                            <th>Data(Confirmação)</th>
                        </tr>
                        </thead>
                        <tbody id="tabela-corpo" ref={tableRef}>
                        {services &&
                          services.map((resp) => (
                            <React.Fragment key={resp.id}>
                              <tr>
                                <td className='service-cell'>
                                    
                                  <div 
                                    onClick={() => toggleExpandRow(resp.id)}
                                  >{resp.pasta}
                                    {expandedRows.includes(resp.id) ? (
                                      <i className="fas fa-plus extend-line green"></i>
                                    ) : (
                                      <i className="fas fa-plus extend-line"></i>
                                    )}
                                  </div>
                                </td>
                                <td>{`${resp.nome} (${resp.email})`}</td>
                                <td>{resp.tipo}</td>
                                <td>{resp.departamento}</td>
                                <td>{resp.natureza}</td>
                                <td>{resp.data_envio}</td>
                                <td>{resp.data_confirmacao || 'Aguardando confirmação...'}</td>
                              
                              {expandedRows.includes(resp.id) && (
                                <tr className="overlay-row">
                                  <td colSpan="7" className="extra-info-content">
                                    <div>
                                      <div className='row'>
                                        <div className='col-11'>
                                          <h3>Mais informações:</h3>
                                        </div>
                                        <div className='col-1'>
                                          <button className="close-button btn btn-danger" onClick={() => toggleExpandRow(resp.id)}>X</button>
                                        </div>
                                      </div>
                                      <label><i className='fa fa-hourglass-half'></i> {`Status: ${resp.status}`}</label>
                                      <div className='row '>
                                          <div className="form-group col-4">
                                              <i class="fa fa-paperclip"></i>
                                              <label style={{marginLeft: 3}}>Pedido do Cliente:</label>
                                              <textarea id="request" className="form-control" name="request" rows="6" disabled={true} value={resp.pedido}></textarea>
                                          </div>
                                          <div className="form-group col-4">
                                              <i class="fa fa-comment"></i>
                                              <label style={{marginLeft: 3}}>Comentários:</label>
                                              <textarea id="comment" className="form-control" name="comment" rows="6" disabled={true} value={resp.comentario}></textarea>
                                          </div>
                                          <div className='form-group col-4'>
                                              <i class="fa fa-folder"></i>
                                              <label htmlFor="profileImage" style={{marginLeft: 3}}>Arquivos:</label>
                                              <div className='form-group file-input-preview view'>
                                                  <div className="square-placeholder" >
                                                      <div className="file-container">
                                                      {resp.files ? (
                                                          <ul className='archive-list'>
                                                          {resp.files.map((file, index) => (
                                                              <li className='archive-item file-item view'>
                                                             <a href={`/api/download/attachment/${file.caminho}`}> {file.nome}</a>
                                                              </li>
                                                          ))}
                                                          </ul>
                                                      ) : (
                                                          null
                                                      )}
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              )}
			     </tr>
                            </React.Fragment>
                          ))}
                        </tbody>
                        </table>
                    </div>
                    {/* <!-- /.card-body --> */}
                    </div>
                    {/* <!-- /.card --> */}
                </div>
                {/* <!-- /.col --> */}
                </div>
                {/* <!-- /.row --> */}
            </div>
            {/* <!-- /.container-fluid --> */}
            </section>
            {/* <!-- /.content --> */}
        </div>
        <AppFooter/>
        <AppSetting/>
        </>
    )
}

export default TableStatus;
