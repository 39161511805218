export function formatCpfCnpj(e) {
    const campo = e.target;
    let valor = campo.value.replace(/\D/g, ""); // Remove tudo o que não é dígito

    // Limita o valor a 14 caracteres (máximo para um CNPJ)
    valor = valor.slice(0, 14);

    let novoValor = "";

    if (valor.length === 11) { // Verifica se o valor é um CPF
        novoValor = valor.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
    } else if (valor.length === 14) { // Verifica se o valor é um CNPJ
        novoValor = valor.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
    } else {
        // Caso não seja nem CPF nem CNPJ, você pode lidar com isso da maneira que preferir
        novoValor = valor;
    }

    campo.value = novoValor;
}
