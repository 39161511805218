import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types'
import { Modal } from 'react-bootstrap';

ViewService.prototypes = {
    showModal: PropTypes.bool,
    handleCloseModal: PropTypes.func,
    service: PropTypes.object,
  };

function ViewService(props) {
    const {showModal, handleCloseModal, service} = props;
    
    function createModal() {
        return (
        <Modal show={showModal} onHide={handleCloseModal} size="xl">
            <Modal.Header className='bg-gray center-element'>
            <Modal.Title>Informações do serviço {service != null ? service.id : null}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <div className='form-group'>
                <div className='row' >
                    <p style={{color: '#6C7077', marginLeft: 5}}><i className='fa fa-clock' style={{color: '#C2C7D0'}}/> Status do serviço: {service.status}</p>
                </div>
                <div className='row form-group'>
                    <div className='col-4'>
                        <label htmlFor='company' className='required'>Empresa:</label>
                        <input id='company' name='company' type='text' className='form-control' value={service.cliente} disabled={true} style={{cursor: 'not-allowed'}}/>
                    </div>
                    <div className='col-4'>
                        <label htmlFor='name' className='required'>Nome do solicitante:</label>
                        <input id='name' name='name' type='text' className='form-control' value={service.nome} disabled={true} style={{cursor: 'not-allowed'}}/>
                    </div>
                    <div className='col-4'>
                        <label htmlFor='email' className='required'>Email do solicitante:</label>
                        <input id='email' name='email' type='text' className='form-control' value={service.email} disabled={true} style={{cursor: 'not-allowed'}}/>
                    </div>
                </div>
                <div className='row form-group'>
                    <div className='col-3'>
                        <label htmlFor='type' className='required'>Tipo:</label>
                        <input id='type' name='type' type='text' className='form-control' value={service.tipo} disabled={true} style={{cursor: 'not-allowed'}}/>
                    </div>
                    <div className='col-3'>
                        <label htmlFor='nature' className='required'>Natureza:</label>
                        <input id='nature' name='nature' type='text' className='form-control' value={service.natureza} disabled={true} style={{cursor: 'not-allowed'}}/>
                    </div>
                    <div className='col-3'>
                        <label htmlFor='department' className='required'>Departamento:</label>
                        <input id='department' name='department' type='text' className='form-control' value={service.departamento} disabled={true} style={{cursor: 'not-allowed'}}/>
                    </div>
                    <div className='col-3'>
                        <label htmlFor='priority' className='required'>Prioridade:</label>
                        <input id='priority' name='priority' type='text' className='form-control' value={service.prioridade} disabled={true} style={{cursor: 'not-allowed'}}/>
                    </div>
                </div>
                <div className='row '>
                    <div className="form-group col-4">
                        <i class="fa fa-paperclip"></i>
                        <label style={{marginLeft: 3}}>Pedido do Cliente:</label>
                        <textarea id="request" className="form-control" name="request" rows="6" disabled={true} value={service.pedido}></textarea>
                    </div>
                    <div className="form-group col-4">
                        <i class="fa fa-comment"></i>
                        <label style={{marginLeft: 3}}>Comentários:</label>
                        <textarea id="comment" className="form-control" name="comment" rows="6" disabled={true} value={service.comentario}></textarea>
                    </div>
                    
                </div>
            </div>
            </Modal.Body>
            <Modal.Footer>
            <button className="btn btn-gray" onClick={handleCloseModal}>Voltar</button>
            </Modal.Footer>
            </Modal>
        )
    }

    return (
        <>
        <div className='row'>
            <div class="col-12 text-center">
            {createModal()}
            </div>
        </div>
        </>
    );
}

export default ViewService;

/*

<div className='form-group col-4'>
                        <i class="fa fa-folder"></i>
                        <label htmlFor="profileImage" style={{marginLeft: 3}}>Arquivos:</label>
                        <div className='form-group file-input-preview view'>
                            <div className="square-placeholder" >
                                <div className="file-container">
                                {service.files ? (
                                    <ul className='archive-list'>
                                    {service.files.map((file, index) => (
                                        <li className='archive-item file-item view'>
                                        {file.name}
                                        </li>
                                    ))}
                                    </ul>
                                ) : (
                                    null
                                )}
                                </div>
                            </div>
                        </div>
                    </div>
*/
