import React, { useState, useEffect } from 'react';

export default function OptionsMenu({ data, itemsPerPage, onItemSelect, onIndexSelected, onStyleSelected, onButtonClick }) {
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedButton, setSelectedButton] = useState(null); 

  useEffect(() => {
    if (data && data.length > 0) {
        setSelectedButton(data[0].id); // Define o primeiro botão como ativo quando os dados são carregados
    }
  }, [data]);

  const totalPages = Math.ceil(data.length / itemsPerPage);

  function showPage(pageIndex) {
    const startIndex = pageIndex * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const pageData = data.slice(startIndex, endIndex);

    return pageData.map((item, index) => {
      const isActive = item.id === selectedButton;

      return (
        <button 
          key={item.id} 
          className={`option-btn btn btn-${index} ${isActive ? 'option-btn-active' : ''}`}
          onClick={() => handleButtonClick(item.id, item, index)}
        >
          {item.name}
        </button>
      );
    });
  }

  function handleButtonClick(id, item, index) {
    if (id === selectedButton) {
      setSelectedButton(null); // Desmarca o botão se ele já estiver selecionado
    } else {
      setSelectedButton(id); // Marca o botão clicado
      onItemSelect(item)
      onIndexSelected(id);
      onStyleSelected(index);
      onButtonClick(id, item, index);
    }
  }

  function nextPage() {
    if (currentPage < totalPages - 1) {
      setCurrentPage(currentPage + 1);
      setSelectedButton(null); // Limpa a seleção ao mudar de página
    }
  }

  function prevPage() {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
      setSelectedButton(null); // Limpa a seleção ao mudar de página
    }
  }

  return (
    <div>
      <div className="menu">{showPage(currentPage)}</div>
	   <div className='center-element' style={{ marginTop: 30 }}>
        <button className='btn' onClick={prevPage} disabled={currentPage === 0} >
            <i className='fa fa-angle-left' ></i>
        </button>
        <span className='page-indicator'>{currentPage + 1}/{totalPages}</span> 
        <button  className='btn' onClick={nextPage} disabled={currentPage === totalPages - 1}>
            <i className='fa fa-angle-right'></i>
        </button>
      </div> 
    </div>
  );
}

