import PropTypes from 'prop-types'
import { propTypes } from 'react-bootstrap/esm/Image';

function BiFrame(props) {
    const {title, url} = props;

    return (
        <iframe
            id="iframe"
            title={title}
            width="100%"
            height="700"
            src={url}
            frameBorder="0"
            style={{ border: '0' }}
            allowFullScreen
        ></iframe>
    );
    
};

BiFrame.prototypes = {
    title: PropTypes.string,
    url: PropTypes.string,
};

export default BiFrame;