import { useState } from 'react';
import axios from 'axios';

function FeedbackModal() {


    return(
    <div className='feedback-modal'>
        <h2 className='center-element form-group'>Avalie nosso serviço</h2>
        <div className='row form-group'>
            {/* <label >Por favor, nos conte o motivo para ter dado essa nota</label> */}
            <textarea id='feedback' className="form-control"name="feedback" rows="6" placeholder="Por favor, nos conte o motivo para ter dado essa nota..." style={{margin: 7}}/>
        </div>
        {/* <textarea id="pedido" class="form-control" name="pedido" rows="6" placeholder="Coloque aqui o pedido do cliente..."></textarea> */}

        
        <button className="btn btn-gray enviar" >Enviar</button>
    </div>
    );
}

export default FeedbackModal;