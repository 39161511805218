import AppHeader from '../../../basic/app-header/AppHeader';
import AppMenu from '../../../basic/app-menu/AppMenu';
import AppFooter from '../../../basic/app-footer/AppFooter';
import AppSetting from '../../../basic/app-setting/AppSetting';
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Modal } from 'react-bootstrap';

// TODO: fazer modal para visualização do bem

function ViewAssets(){
    /* considerando que no bd os bens terão um idTipo, que será de 1 a 4:
    * 1: dinheiro;
    * 2: bens móveis;
    * 3: bens imóveis;
    * 4: ações;
    */
    const [idTipo, setIdTipo] = useState(null);
    const [bens, setBens] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [olharBem, setOlharBem] = useState([]);
    // armazenar os sócios iniciais para serem exibidos
    const initialSocios = useRef([]);
    // variável de estado para definir se o bem imóvel possui gravames (true) ou não (false)
    const [gravames, setGravames] = useState(null);
    // variável de estado para indicar o tipo do bem imóvel, sendo rural (0) e urbano (1)
    const [tipoImovel, setTipoImovel] = useState(null);

    useEffect(() => {
        const getAssets = async () => {
          try {
            const response = await axios.post('http://localhost:5000/api/get/assets', { withCredentials: true });
            if (response.data.status === 200) {
              setBens(response.data.assets);
            } else {
              window.location.href = "http://localhost:3000/bad/request";
            }
          } catch (error) {
            console.error(error);
            window.location.href = "http://localhost:3000/bad/request";
          }
        };

        
    
        getAssets();
    }, []);
    
    // requisição para um único bem
    async function getOneAsset(id) {
        try {
            const response = await axios.post(`http://localhost:5000/api/get/assets`, {id}, { withCredentials: true });
            if (response.data.status === 200) {

                // Extrair apenas os valores da propriedade 'id_soc' do array de objetos
                const idSocs = response.data.assetPartners.map(item => item.id_soc);
                initialSocios.current = idSocs;

                // colocar o tipo do bem
                if (response.data.asset.capital == 'dinheiro') {
                    setIdTipo(1);
                }
                else if (response.data.asset.capital == 'moveis') { 
                    setIdTipo(2);
                }
                else if (response.data.asset.capital == 'imoveis') {
                    setIdTipo(3);
                }
                else if (response.data.asset.capital == 'quotas') {
                    setIdTipo(4);
                }

                // colocar se tem gravames ou não
                if(response.data.asset.gravames == 'possui gravames') {
                    setGravames(true);
                }
                else if(response.data.asset.gravames == 'não possui gravames') {
                    setGravames(false);
                }

                // colocar se é rural ou urbano
                if(response.data.asset.tipobem == 'rural') {
                    setTipoImovel(0);
                }
                else if(response.data.asset.tipobem == 'urbano') {
                    setTipoImovel(1);
                }
            } else {
                console.log("status: "+response.data.status)
                window.location.href = "http://localhost:3000/bad/request";
            }
        } catch (error) {
            console.error(error);
            window.location.href = "http://localhost:3000/bad/request";
        }
    }

    if (bens === null) {
        return(
            <>
            <AppHeader/>
            <AppMenu/>
            <div className='content-wrapper'>
                <div className='card-body'>
                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100vh"
                    }}>
                        <div class="spinner-border" role="status">
                        <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>
            </div>
            </>
        ); 
    }

    async function removeAsset(id){
        try {
            const acao = "delete"
            await axios.post('http://localhost:5000/api/actions/assets', {acao, id}, {withCredentials: true}).then((resp)=>{
                if(resp.data.status == 200){
                    window.location.href = "http://localhost:3000/assets/view-assets"
                }
            })
        } catch (error) {
            console.log(error);
            window.location.href = "http://localhost:3000/bad/request";
        }   
    }

    // * Funções para visualização do bem

    function returnGravames(){
        return(
          <div className='row'>
            <div className="col-md-12 form-group">
                <i class="fa fa-comment"></i>
                <label style={{marginLeft: 3}}>Descrição gravames:</label>
                <textarea id="descricao_movel" class="form-control" name="descricao_gravames" rows="6" placeholder="Descreva aqui o gravame..." value={olharBem.descgravames} disabled={true}></textarea>
            </div>
          </div>  
        );
      }
  
    function returnRural(){
    return (
        <div>
        <div className='row' style={{marginTop: 15}}>
            <div className='form-group col-4'>
            <label>Número do CCIR:</label>
            <input type='text' class='form-control' name='ccir' id='ccir' value={olharBem.ccir} disabled={true}/>
            </div>
            <div className='form-group col-4'>
            <label>Número do NIRF:</label>
            <input type='text' class='form-control' name='nirf' id='nirf' value={olharBem.nirf} disabled={true}/>
            </div>
            
            <div className='form-group col-4 text-center'>
                <label>Possui Georreferenciamento?</label>
                <div className='text-center' id='custom-radio'>
                <input type="radio" name="georreferenciamento" id="sim_geo" value="sim_geo" checked={olharBem.georreferenciamento === 'possui georreferenciamento'} disabled={true}/>
                <label style={{padding: 7}} for="sim_geo">Sim</label>

                <input type="radio" name="georreferenciamento" id="nao_geo" value="nao_geo" checked={olharBem.georreferenciamento === 'não possui georreferenciamento'} disabled={true}/>
                <label style={{padding: 7}} for="nao_geo">Não</label>
                </div> 
            </div>
        </div>
        <div className='row'>
            <div className='form-group col-4'>
            <label>Área do imóvel em hectares:</label>
            <input type='text' class='form-control' name='area' id='area' value={olharBem.hectares} disabled={true}/>
            </div>
            <div className='form-group col-4'>
            <label>Valor constante no ITR:</label>
            <input type='text' class='form-control' name='itr' id='itr' value={olharBem.itr} disabled={true}/>
            </div>
            <div className='form-group col-4 text-center'>
            <label>Possui CAR?</label>
            <div className='text-center' id='custom-radio'>
                <input type="radio" name="car" id="sim_car" value="sim_car" checked={olharBem.car === 'possui CAR'} disabled={true}/>
                <label style={{padding: 7}} for="sim_car">Sim</label>

                <input type="radio" name="car" id="nao_car" value="nao_car" checked={olharBem.car === 'não possui CAR'} disabled={true}/>
                <label style={{padding: 7}} for="nao_car">Não</label>
            </div> 
            </div> 
        </div>
        </div>
    );
    }

    function returnUrbano(){
    return(
        <div>
        <div className='row' style={{marginTop: 15}}>
            <div className='form-group col-6'>
            <label>Cadastro Municipal:</label>
            <input type='text' class='form-control' name='cadastro_municipal' id='cadastro_municipal' value={olharBem.cadastro} disabled={true}/>
            </div>
            <div className='form-group col-6'>
            <label>Valor constante do IPTU:</label>
            <input type='text' class='form-control' name='iptu' id='iptu' value={olharBem.iptu} disabled={true}/>
            </div>
        </div>
        </div>
    );
    }

    function bensImoveis(){
        return(
            <div>
            <div className='row'>
              <div class="form-group col-12">
                <label>Denominação:</label>
                <input type="text" name="denominacao" id="denominacao_imoveis" class="form-control" value={olharBem.denominacao} disabled={true}/>
              </div>
            </div>
            <div className='row'>
              <div className='col-5'>
                <label>Número de matrícula do bem:</label>
                <input type="text" name="matricula_bem" id="matricula_bem" class="form-control" value={olharBem.matriculabem} disabled={true}/>
              </div>
              <div className='col-4'>
                <label>Número do CRI:</label>
                <input type='text' name='cri' id='cri' class='form-control' value={olharBem.cri} disabled={true}/>
              </div>
              <div className='col-3 text-center'>
                <label>Possui gravames?</label>
                <div className='text-center' id='custom-radio'>
                  <input type="radio" name="gravames" id="sim" value="sim" checked={olharBem.gravames === 'possui gravames'} disabled={true}/>
                  <label style={{marginLeft: 2, padding: 7}} for="sim">Sim</label>

                  <input type="radio" name="gravames" id="nao" value="nao" checked={olharBem.gravames === 'não possui gravames'} disabled={true}/>
                  <label style={{marginLeft: 8, padding: 7}} for="nao">Não</label>
                </div> 
              </div>
            </div>
            <div className='row'>
              <div className=' col-4'>
                <label>Comarca do bem:</label>
                <input type="text" name="comarca" id="comarca" class="form-control" value={olharBem.comarca} disabled={true}/>
              </div>
              <div className=' col-3 text-center'>
                <label>Tipo do imóvel:</label>
                <div style={{marginTop: 2}} className=' text-center' id='custom-radio'>
                  <input type="radio" name="tipo_imovel" id="rural" value="rural" checked={olharBem.tipobem === 'rural'} disabled={true}/>
                  <label style={{marginLeft: 2}} for="rural">Rural</label>

                  <input type="radio" name="tipo_imovel" id="urbano" value="urbano" checked={olharBem.tipobem === 'urbano'} disabled={true}/>
                  <label for="urbano">Urbano</label>
                </div> 
              </div>
              <div className='form-group col-5'>
                <label>Cidade em que o bem está situado:</label>
                <input type="text" name="cidade_bem" id="cidade_bem" class="form-control" value={olharBem.cidadebem} disabled={true}/>
              </div>
            </div>
            <div className='row'>
              <div className='form-group col-4'>
                <label>Valor do imposto de renda:</label>
                <input type="text" name="imposto_renda" id="imposto_renda" class="form-control" value={olharBem.imposto} disabled={true}/>
              </div>
              <div className='form-group col-4'>
                <label>Valor de mercado:</label>
                <input type='text' name='valor_mercado' id='valor_mercado' class='form-control' value={olharBem.mercado} disabled={true}/>
              </div>
              <div className='form-group col-4'>
                <label>Valor recebido de locação:</label>
                <input type='text' name='valor_locacao' id='valor_locacao' class='form-control' value={olharBem.valorlocacao} disabled={true}/>
              </div>
            </div>
            <div className='row'>
              <div className="col-md-12">
                <i class="fa fa-comment"></i>
                <label style={{marginLeft: 3}}>Descrição do bem:</label>
                <textarea id="descricao_imovel" class="form-control" name="descricao_imovel" rows="6" placeholder="Descreva aqui o bem inserido..." value={olharBem.descbem} disabled={true}></textarea>
              </div>
            </div>
        </div>
        );
    }
    
    function returnBem(){
        if(idTipo == 1){
            return(
                <div>
                    <div className='row'>
                        <div className='col-6'>
                            <label for="nome">Valor em reais:</label>
                        </div>
                        <div className='col-6'>
                            <i className="fa fa-users mr-3 text-black"></i><label for="lista-socios">
                                Sócio(s):
                            </label>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='form-group col-6'>
                            <input  type="text" name="valor" id="valor" class="form-control" value={formatFloatToMoney(olharBem.valor)} disabled={true}/>
                        </div>
                        <div className='col-6 col-sm-6 form-group' style={{marginTop: -5}}> 
                            <ul className="list-group list-group-flash" id="lista-socios">
                                {
                                    initialSocios && initialSocios.current.map(resp => (
                                        <li className="d-flex align-items-center"><i className="fa fa-user mr-3 text-black"></i>{resp}</li>
                                    ))
                                }
                            </ul>
                        </div>
                        
                    </div>
                    <div className='row'>
                        
                        
                    </div>
                        
                    
                </div>
            );
        }
        if(idTipo == 2){
            return(
                <div className='card-body'>
                    <div className='row'>
                        <div className='col-6'>
                            <label for="nome">Denominação:</label>
                        </div>
                        <div className='col-6'>
                            <label for="nome">Valor monetário:</label>
                        </div>    
                    </div>
                    <div className='row'>
                        <div class="form-group col-6">
                            <input type="text" name="denominacao" id="denominacao_moveis" class="form-control" value={olharBem.denominacao} disabled={true}/>
                        </div>
                        <div class="form-group col-6">
                            <input type="text" name="valor_monetario" id="valor_monetario" class="form-control" value={formatFloatToMoney(olharBem.monetario)} disabled={true}/>
                        </div>
                    </div>
                    <div className='row'>
                        <div className="col-md-12">
                            <div className="form-group">
                            <i class="fa fa-comment"></i>
                            <label style={{marginLeft: 3}}>Descrição do bem:</label>
                            <textarea id="descricao_movel" class="form-control" name="descricao_movel" rows="6" placeholder="Descreva aqui o bem inserido..." value={olharBem.descbem} disabled={true}></textarea>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className='col-6'>
                            <i className="fa fa-users mr-3 text-black"></i><label for="lista-socios">
                                Sócio(s):
                            </label>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-6 form-group'> 
                            <ul className="list-group list-group-flash" id="lista-socios">
                                {
                                    initialSocios && initialSocios.current.map(resp => (
                                        <li className="d-flex align-items-center"><i className="fa fa-user mr-3 text-black"></i>{resp}</li>
                                    ))
                                }
                            </ul>
                        </div>
                    </div>           
                </div>
            );
        }
        if(idTipo == 3 && tipoImovel == 0 && gravames == false){
            return(
              <div className='card-body'>
                    {bensImoveis()}
                    {returnRural()}
                    <div className="row">
                        <div className='col-6'>
                            <i className="fa fa-users mr-3 text-black"></i><label for="lista-socios">
                                Sócio(s):
                            </label>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-6 form-group'> 
                            <ul className="list-group list-group-flash" id="lista-socios">
                                {
                                    initialSocios && initialSocios.current.map(resp => (
                                        <li className="d-flex align-items-center"><i className="fa fa-user mr-3 text-black"></i>{resp}</li>
                                    ))
                                }
                            </ul>
                        </div>
                    </div> 
              </div>
            );
        }
        else if(idTipo == 3 && tipoImovel == 0 && gravames == true){
        return(
            <div className='card-body'>
                {bensImoveis()}
                {returnRural()}
                {returnGravames()}
                <div className="row">
                    <div className='col-6'>
                        <i className="fa fa-users mr-3 text-black"></i><label for="lista-socios">
                            Sócio(s):
                        </label>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-6 form-group'> 
                        <ul className="list-group list-group-flash" id="lista-socios">
                            {
                                initialSocios && initialSocios.current.map(resp => (
                                    <li className="d-flex align-items-center"><i className="fa fa-user mr-3 text-black"></i>{resp}</li>
                                ))
                            }
                        </ul>
                    </div>
                </div> 
            </div>
        );
        }
        else if(idTipo == 3 && tipoImovel == 1 && gravames == false){
        return(
            <div className='card-body'>
                {bensImoveis()}
                {returnUrbano()}
                <div className="row">
                    <div className='col-6'>
                        <i className="fa fa-users mr-3 text-black"></i><label for="lista-socios">
                            Sócio(s):
                        </label>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-6 form-group'> 
                        <ul className="list-group list-group-flash" id="lista-socios">
                            {
                                initialSocios && initialSocios.current.map(resp => (
                                    <li className="d-flex align-items-center"><i className="fa fa-user mr-3 text-black"></i>{resp}</li>
                                ))
                            }
                        </ul>
                    </div>
                </div> 
            </div>
        );
        }
        else if(idTipo == 3 && tipoImovel == 1 && gravames == true){
        return(
            <div className='card-body'>
                {bensImoveis()}
                {returnUrbano()}
                {returnGravames()}
                <div className="row">
                    <div className='col-6'>
                        <i className="fa fa-users mr-3 text-black"></i><label for="lista-socios">
                            Sócio(s):
                        </label>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-6 form-group'> 
                        <ul className="list-group list-group-flash" id="lista-socios">
                            {
                                initialSocios && initialSocios.current.map(resp => (
                                    <li className="d-flex align-items-center"><i className="fa fa-user mr-3 text-black"></i>{resp}</li>
                                ))
                            }
                        </ul>
                    </div>
                </div> 
            </div>
        );
        }
        else if(idTipo == 4) {
            return(
            <div>
                <div className='row'>
                <div class="form-group col-12">
                    <label>Denominação:</label>
                    <input type="text" class="form-control" id="denominacao_acoes" value={olharBem.denominacao} disabled={true}/>
                </div>
                </div>
                <div className='row'>
                <div className='form-group col-6'>
                    <label>Informe a razão social da empresa:</label>
                    <input type='text' class='form-control' id="razao_social_acoes" value={olharBem.razao} disabled={true}/>
                </div>
                <div className='form-group col-3'>
                    <label>Informe o CNPJ/ME:</label>
                    <input type='text'  class='form-control' id="cnpj_me_acoes" value={olharBem.cnpj_me} disabled={true}/>
                </div>
                <div className='form-group col-3'>
                    <label>Informe o NIRE:</label>
                    <input type='text' class='form-control' id="nire_acoes" value={olharBem.nire} disabled={true}/>
                </div>
                </div>
                <div className='row'>
                <div className='form-group col-6'>
                    <label>Valor do capital social:</label>
                    <input type='text' class='form-control' id="valor_capital_acoes" value={formatFloatToMoney(olharBem.capital_social)} disabled={true}/>
                </div>
                <div className='form-group col-6'>
                    <label>Valor do patrimônio líquido:</label>
                    <input type='text' class='form-control' id="valor_patrimonio_acoes" value={formatFloatToMoney(olharBem.patrimonio_liquido)} disabled={true}/>
                </div>
                </div>
                <div className='row'>
                <div className='form-group col-6'>
                    <label>Informe o número de quotas ou ações:</label>
                    <input type='text' class='form-control' id="num_quotas" value={olharBem.quotas_acoes} disabled={true}/>
                </div>
                <div className='form-group col-6'>
                    <label>Informe o valor das quotas ou ações:</label>
                    <input type='text' class='form-control' id="valor_quotas" value={formatFloatToMoney(olharBem.valor_quotas)} disabled={true}/>
                </div>
                </div>
                <div className="row">
                    <div className='col-6'>
                        <i className="fa fa-users mr-3 text-black"></i><label for="lista-socios">
                            Sócio(s):
                        </label>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-6 form-group'> 
                        <ul className="list-group list-group-flash" id="lista-socios">
                            {
                                initialSocios && initialSocios.current.map(resp => (
                                    <li className="d-flex align-items-center"><i className="fa fa-user mr-3 text-black"></i>{resp}</li>
                                ))
                            }
                        </ul>
                    </div>
                </div> 
            </div>
            );
        }
    }

    // * Fuções para modal de visualização do bem

    const handleCloseModal = () => {
        setShowModal(false);
      };
    
      const handleShowModal = () => {
        setShowModal(true);
    };

    async function searchAsset(id) {
        console.log('1')
        let left = 0;
        let right = bens.length - 1;

        while(left <= right) {
            const mid = Math.floor((left + right) / 2);
            const midValue = bens[mid].id;

            if(midValue == id) {
                setOlharBem(bens[mid]);
                console.log(olharBem)
                await getOneAsset(bens[mid].id);
                break;
            }
            else if(midValue < id) {
                left = mid + 1;
            }
            else {
                right = mid - 1;
            }
        }
    }
  
    function createModal() {
      return (
  
        <Modal show={showModal} onHide={handleCloseModal} size="lg">
           <Modal.Header className='bg-gray center-element'>
            <Modal.Title>Visualizando o bem: {olharBem != null ? olharBem.denominacao : null}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {returnBem()}
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-gray" onClick={handleCloseModal}>Voltar</button>
          </Modal.Footer>
          </Modal>
        )
        
    }
    // * Funções de formatação

    // formatar o nome do tipo do bem
    function formatTipo(tipo) {
        if (tipo == 'dinheiro') {
            return 'Dinheiro';
        }
        else if (tipo == 'moveis') {
            return 'Bem móvel';
        }
        else if (tipo == 'imoveis') {
            return 'Bem imóvel';
        }
        else if (tipo == 'quotas') {
            return 'Quotas/Ações';
        }
        return null;
    }

    // coloca o valor em float do bd para o valor monetário (R$ 000.000,00) para ser apresentado na página
    function formatFloatToMoney(value) {
        const floatValue = parseFloat(value);
        if (isNaN(floatValue)) {
          return value; // Caso o valor não seja um número válido, retorna R$ 0,00
        }
      
        const formattedValue = floatValue.toFixed(2).replace(".", ",");
        return `R$ ${formattedValue.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")}`;
    }

    return(
        <>
        <AppHeader/>
        <AppMenu/>
        {/* <!-- Content Wrapper. Contains page content --> */}
        <div class="content-wrapper">
            {/* <!-- Content Header (Page header) --> */}
            <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                <div class="col-sm-6">
                    <h1></h1>
                </div>
                <div class="col-sm-6">
                    <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="/">Início</a></li>
                    <li class="breadcrumb-item active">Meus bens</li>
                    </ol>
                </div>
                </div>
            </div>
            {/* <!-- /.container-fluid --> */}
            </section>

            {/* <!-- Main content --> */}
            <section class="content">
            <div class="container-fluid">
                <div class="row">
                <div class="col-12">
                    <div class="card">
                    <div class="card-header">
                        <h3 class="card-title">Meus bens</h3>
                    </div>
                    <div class="card-header">
                        <a href="/assets/create-asset"><button class="btn btn-sm btn-success">Novo bem</button></a>
                    </div>
                    {/* <!-- /.card-header --> */}
                    <div class="card-body">
                        <table id="example1" className="table table-bordered table-striped dataTable dtr-inline">
                        <thead>
                            <tr>
                                <th>Denominação</th>
                                <th>Tipo</th>
                                <th>Valor</th>
                                <th style={{width: '105px'}}>Ações</th>
                            </tr>
                        </thead>
                        <tbody id="tabela-corpo">
                            {
                                bens && bens.map(resp=> (
                                    <tr>
                                        <td><a>{resp.denominacao}</a></td>
                                        <td><a>{formatTipo(resp.capital)}</a></td>
                                        <td><a>{formatFloatToMoney(resp.valor) || formatFloatToMoney(resp.monetario) || formatFloatToMoney(resp.mercado) || formatFloatToMoney(resp.valor_quotas)}</a></td>
                                        <td className='center-element' style={{width: '125px'}}>
                                            <button className='btn btn-gray eye-animation' style={{marginRight: 5}} onClick={()=>{searchAsset(resp.id); handleShowModal()}}><i class='fa fa-eye icon-white'></i></button>
                                            <a href={`/edit/asset/${resp.id}`}><button className='btn btn-primary pen-animation'><i class='fa fa-pen icon-white'></i></button></a>
                                            <button className='btn btn-danger trash-animation' onClick={()=>{removeAsset(resp.id)}} style={{marginLeft: 5}}><i className='fa fa-trash'></i></button>
                                        </td>
                                    </tr>    
                                ))
                            }
                        </tbody>
                        </table>
                        <div className='row'>
                            <div class="col-12 text-center">
                                {createModal()}
                            </div>
                        </div>
                    </div>
                    {/* <!-- /.card-body --> */}
                    </div>
                    {/* <!-- /.card --> */}
                </div>
                {/* <!-- /.col --> */}
                </div>
                {/* <!-- /.row --> */}
            </div>
            {/* <!-- /.container-fluid --> */}
            </section>
            {/* <!-- /.content --> */}
        </div>
        {/* <!-- /.content-wrapper --> */}
        <AppFooter/>
        <AppSetting/>
        </>
    )
}

export default ViewAssets;