import Card from "../../basic/app-dashboard/Card";

function CardManageSsj() {
    return(
        <Card
            href="/manage-ssj"
            image="/dist/img/scott-graham-OQMZwNd3ThU-unsplash.jpg"
            title="Gerenciador do Ssj"
            description="Organize e gerencie as solicitações de serviços jurídicos"
        />
    );
}

export default CardManageSsj;