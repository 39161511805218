// função para formatar o campo de cpf(000.000.000-00)
export function formatCpf(e) {
    const campo = e.target;
    const valor = campo.value.replace(/\D/g, ""); // Remove tudo o que não é dígito

    let novoValor = "";

    novoValor = valor.replace(/(\d{3})(\d{3})(\d{3})(\d{2}).*/, "$1.$2.$3-$4");

    campo.value = novoValor;
}