import React from 'react';
import axios from 'axios';

function AppHeader() {

    function logout(){
	    axios.post('/api/logout', null, {withCredentials: true}).then((res)=>(
		    window.location.href = '/login'
	    )).catch((error)=>(
		    console.log(error)
	    ))
    }

    return (
        <nav class="main-header navbar navbar-expand navbar-white navbar-light fixed-top">
            {/* <!-- Left navbar links --> */}
            <ul class="navbar-nav">
                <li class="nav-item">
                <a class="nav-link" data-widget="pushmenu" href="#" role="button"><i class="fas fa-bars"></i></a>
                </li>
            </ul>
        
            {/* <!-- Right navbar links --> */}
            <ul class="navbar-nav ml-auto">
                
                {/* <!-- Notifications Dropdown Menu -->   */}
                <li class="nav-item dropdown">
                <a  class="nav-link">
                    <button id="logout-button" class="btn btn-block btn-outline-dark" onClick={logout}>Sair</button>
                </a>
                </li>

            </ul> 
        </nav>
    );
}

export default AppHeader;
