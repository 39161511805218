import AppHeader from "../../basic/app-header/AppHeader";
import AppMenu from "../../basic/app-menu/AppMenu";
import AppFooter from "../../basic/app-footer/AppFooter";
import AppSetting from "../../basic/app-setting/AppSetting";
import React, { useState } from "react";
import { postAxios } from "../../../hooks/useAxios";
import PdfUploader from "./PdfUploader";

function RequestAnalysisSentence() {
    const [pdfFile, setPdfFile] = useState(null);
    const [isProcessing, setIsProcessing] = useState(false);
    const form = new FormData();

    console.log(pdfFile)

    // envio do formulario
    function sendForm() {
        if (isProcessing) return; // Impede cliques adicionais enquanto já está em andamento

        console.log("entrou no send");
        setIsProcessing(true);

        const service = "sentenca";
        const part = document.querySelector("#part").value;
        const nature = document.querySelector("#nature").value;
        const description = document.querySelector("#description").value;
        // const info = document.querySelector("#info").value;

        // insercao no formulario para o envio
        form.append("service", service);
        form.append("part", part);
        form.append("nature", nature);
        form.append("description", description);
        // form.append("info", info);
        form.append("file", pdfFile);

        console.log(part);
        console.log(nature);
        console.log(description);
        console.log(pdfFile);

        console.log("Dados enviados: ");
        // Iterar sobre os pares de chave/valor e logar cada um
        for (const pair of form.entries()) {
            console.log(pair[0], pair[1]);
        }

        const config = {
            headers: {
                "Content-Type": "multipart/form-data",
            },
            // withCredentials: true
        };

        console.log("fora do axios");
        postAxios(
            "https://adm.almeidaenogueira.com.br/api/service/ai",
            form,
            null,
            config
        )
            .then((resp) => {
                console.log(resp);
                window.location = `/analysis/result/sentenca/${resp.data.id}`;
            })
            .catch((error) => {
                console.log(error);
            
                // Verifica se há uma resposta do servidor
                if (error.response) {
                    // Se o status for 429, lida com esse caso específico
                    if (error.response.status === 429) {
                        alert("Muitas requisições foram feitas ao Gemini, por favor aguande alguns minutos e tente novamente!");
                    } else {
                        // Para outros status de erro, você pode lidar de maneira genérica
                        if (error.response.data) {
                            alert(error.response.data);
                        } else {
                            alert("Ocorreu um erro! Tente novamente mais tarde.");
                        }
                    }
                } else {
                    // Caso o erro não tenha uma resposta do servidor (erro de rede, por exemplo)
                    alert("Erro de conexão. Por favor confira sua conexão e tente novamente.");
                }
            
                console.error("Erro na solicitação POST:", error);
            })
            .finally(() => {
                setIsProcessing(false); // Habilita o botão novamente após a conclusão ou erro
            });
    }

    // * Funcoes auxiliares

    const handlePdfFileChange = (file) => {
        setPdfFile(file);
    };

    return (
        <>
            <AppHeader />
            <AppMenu />
            
                <div class="content-wrapper">
                    <section
                        class="content-header"
                        style={{ marginBottom: -5 }}
                    >
                        <div class="container-fluid">
                            <div class="row mb-2">
                                <div class="col-sm-6">
                                    <h1>
                                        Solicitação de Análise de Sentenças
                                        <span class="beta-text">(beta)</span>
                                    </h1>
                                </div>
                                <div class="col-sm-6">
                                    <ol class="breadcrumb float-sm-right">
                                        <li class="breadcrumb-item">
                                            <a href="/">Início</a>
                                        </li>
                                        <li class="breadcrumb-item active">
                                            Análise de sentenças
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section class="content">
                        <div class="container-fluid">
                            <div class="card card-default">
                                <div class="card-header bg-gray center-element">
                                    <h3
                                        class="card-title"
                                        style={{ fontWeight: 600 }}
                                    >
                                        Envie a sentença para análise
                                    </h3>
                                </div>
                                <div class="card-body">
                                {isProcessing ? (
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        height: "60vh"
                                    }}>
                                        <div className="loader-container">
                                            <p className="loading-text">Analisando a sentença</p>
                                            <div className="loader">
                                                <div className="ball"></div>
                                                <div className="ball"></div>
                                                <div className="ball"></div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                ) : (
                                    <div className="analysis-request-grid form-group">
                                        <div className="campo-analysis1">
                                            <label class="required" for="type">
                                                Partes:
                                            </label>
                                            <select
                                                type="text"
                                                name="part"
                                                id="part"
                                                class="form-control"
                                            >
                                                <option></option>
                                                <option>Autor</option>
                                                <option>Réu</option>
                                            </select>
                                        </div>
                                        <div className="campo-analysis2">
                                            <label
                                                for="nature"
                                            >
                                                Natureza:
                                            </label>
                                            <select
                                                name="nature"
                                                id="nature"
                                                className="form-control"
                                            >
                                                <option></option>
                                                <option>Empresarial</option>
                                                <option>Trabalhista</option>
                                            </select>
                                        </div> 
                                        <div className="campo-analysis3">
                                            <label
                                                for="client"
                                            >
                                                Descrição:
                                            </label>
                                            <input
                                                type="text"
                                                name="description"
                                                id="description"
                                                class="form-control"
                                            />
                                        </div>
                                        <div className="campo-analysis6">
                                            <div style={{ marginLeft: "20%" }}>
                                                <i class="fa fa-folder"></i>
                                                <label
                                                    htmlFor="archive"
                                                    style={{ marginLeft: 5 }}
                                                    className="required"
                                                >
                                                    Anexar documento:
                                                </label>
                                            </div>
                                            <PdfUploader
                                                name="archive"
                                                setPdfFile={handlePdfFileChange}
                                            />
                                        </div>
                                    </div>
                                )}
                                    <div class="col-12 center-element">
                                        <a href="/ssj/analysis">
                                            <button
                                                type="button"
                                                class="cancel btn btn-gray"
                                            >
                                                Cancelar
                                            </button>
                                        </a>
                                        <a>
                                            <button
                                                type="submit"
                                                class="enviar btn btn-gray"
                                                style={{ marginLeft: 5 }}
                                                onClick={sendForm}
                                                disabled={isProcessing}
                                            >
                                                {isProcessing
                                                    ? "Aguarde..."
                                                    : "Analisar"}
                                            </button>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            <AppFooter />
            <AppSetting />
        </>
    );
}

export default RequestAnalysisSentence;

