import React from 'react';
import { Box, Container, Unstable_Grid2 as Grid } from '@mui/material';
import OverviewClients from './OverviewClients';
import OverviewUsers from './OverviewUsers';
import OverviewReceipt from './OverviewReceipt';
import { OverviewSales } from './OverviewSales';
import { OverviewTraffic } from './OverviewTraffic';

function AdmDashboard() {
  // const[user, setUser] = useState(null);

  return (
    <div className="content-wrapper" style={{minHeight: 675}}>
      <div className="content">
        <div className="container-fluid">
          <div className="row mb-2 center-element">
            <div className="col-12" style={{marginTop: "15px"}}>
            <h2 style={{marginLeft: "20px"}}>
                Bem vindo ao Portal do Administrador!
            </h2>
            <Box
              component="main"
              sx={{
                flexGrow: 1,
                py: 2
              }}
            >
              <Container maxWidth="xl">
                <Grid
                  container
                  spacing={3}
                >
                  <Grid
                    xs={12}
                    sm={4}
                    lg={4}
                  >
                    <OverviewClients
                      difference={12}
                      positive
                      sx={{ height: '100%' }}
                      value="5"
                    />
                  </Grid>
                  <Grid
                    xs={12}
                    sm={4}
                    lg={4}
                  >
                    <OverviewUsers
                      difference={12}
                      positive
                      sx={{ height: '100%' }}
                      value="14"
                    />
                  </Grid>
                  <Grid
                    xs={12}
                    sm={4}
                    lg={4}
                  >
                    <OverviewReceipt
                      width={-4}
                      height={3}
                      fontSize={'h4'}
                      positive
                      sx={{ height: '100%' }}
                      value="R$ 5.7k"
                    />
                  </Grid>
                  <Grid
                    xs={6}
                    md={7}
                    lg={7}
                  >
                    <OverviewSales
                      chartSeries={[
                        {
                          name: 'Esse ano',
                          data: [18, 16, 5, 8, 3, 14, 14, 16, 17, 19, 18, 20]
                        },
                        {
                          name: 'Ano passado',
                          data: [12, 11, 4, 6, 2, 9, 9, 10, 11, 12, 13, 13]
                        }
                      ]}
                      sx={{ height: '100%' }}
                    />
                  </Grid>
                  <Grid
                    xs={6}
                    md={5}
                    lg={5}
                  >
                    <OverviewTraffic
                      chartSeries={[63, 15, 22]}
                      labels={['Desktop', 'Tablet', 'Phone']}
                      sx={{ height: '100%' }}
                    />
                  </Grid>
                </Grid>
              </Container>
            </Box>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdmDashboard;
