export function formatPhone(e) {
    const campo = e.target;
    let valor = campo.value.replace(/\D/g, ""); // Remove tudo o que não é dígito

    let novoValor = "";

    if (valor.length > 2) {
        novoValor = `(${valor.slice(0, 2)}) `;
        if (valor.length > 7) {
            novoValor += `${valor.slice(2, 7)}-${valor.slice(7, 11)}`;
        } else {
            novoValor += valor.slice(2);
        }
    } else {
        novoValor = valor;
    }

    campo.value = novoValor;
}
