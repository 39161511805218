import AppHeader from '../../../basic/app-header/AppHeader';
import AppMenu from '../../../basic/app-menu/AppMenu';
import AppFooter from '../../../basic/app-footer/AppFooter';
import AppSetting from '../../../basic/app-setting/AppSetting';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { format } from 'date-fns'

// TODO: fazer modal para visualização do projeto

function ViewProjects(){
    const [projetos, setProjetos] = useState(null);

    useEffect(() => {
        const getProjects = async () => {
          try {
            const response = await axios.post('http://localhost:5000/api/get/projects', { withCredentials: true });
            if (response.data.status === 200) {
                setProjetos(response.data.projects);
            } else {
                window.location.href = "http://localhost:3000/bad/request";
            }
          } catch (error) {
            console.error(error);
            window.location.href = "http://localhost:3000/bad/request";
          }
        };
    
        getProjects();
        
    }, []);

    if(projetos === null) {
        return(
            <>
            <AppHeader/>
            <AppMenu/>
            <div className='content-wrapper'>
                <div className='card-body'>
                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100vh"
                    }}>
                        <div class="spinner-border" role="status">
                        <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>
            </div>
            </>
        ); 
    }

    function formatarDataHora(dataDesformatada) {
        // Criando um objeto Date a partir da string desformatada
        const data = new Date(dataDesformatada);
      
        // Utilizando o date-fns para formatar a data e horas no padrão brasileiro
        const dataHoraFormatada = format(data, 'dd/MM/yyyy HH:mm');
      
        return dataHoraFormatada;
    }

    return(
        <>
        <AppHeader/>
        <AppMenu/>
        <div className="content-wrapper">
            {/* <!-- Content Header (Page header) --> */}
            <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                <div class="col-sm-6">
                    <h1></h1>
                </div>
                <div class="col-sm-6">
                    <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="/">Início</a></li>
                    <li class="breadcrumb-item active">Meus projetos</li>
                    </ol>
                </div>
                </div>
            </div>
            {/* <!-- /.container-fluid --> */}
            </section>
            {/* <!-- Main content --> */}
            <section class="content">
            <div class="container-fluid">
                <div class="row">
                <div class="col-12">
                    <div class="card">
                    <div class="card-header">
                        <h3 class="card-title">Meus projetos</h3>
                    </div>
                    <div class="card-header">
                        <a href="/create/project"><button class="btn btn-sm btn-success">Novo projeto</button></a>
                    </div>
                    {/* <!-- /.card-header --> */}
                    <div class="card-body">
                        <table id="example1" class="table table-bordered table-striped">
                        <thead>
                        <tr>
                            <th>Nome</th>
                            <th>Data da criação</th>
                            <th className='coluna-visualizar2'>Ações</th>
                        </tr>
                        </thead>
                        <tbody id="tabela-corpo">
                            {
                                projetos && projetos.map(resp=> (
                                    <tr>
                                        <td><a>{resp.nome}</a></td>
                                        <td><a>{formatarDataHora(resp.createdAt)}</a></td>
                                        <td classList="action-column" className='center-element coluna-visualizar'>
                                           <a href={`/view/project/${resp.id}`}><button className='btn btn-gray eye-animation' style={{marginRight: 5}}><i class='fa fa-eye icon-white'></i></button></a>
                                            <a href='/edit/project'><button className='btn btn-primary pen-animation'><i class='fa fa-pen icon-white'></i></button></a>
                                            <button className='btn btn-danger trash-animation' style={{marginLeft: 5}}><i class='fa fa-trash'></i></button>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                        </table>
                    </div>
                    {/* <!-- /.card-body --> */}
                    </div>
                    {/* <!-- /.card --> */}
                </div>
                {/* <!-- /.col --> */}
                </div>
                {/* <!-- /.row --> */}
            </div>
            {/* <!-- /.container-fluid --> */}
            </section>
            {/* <!-- /.content --> */}
        </div>
        <AppFooter/>
        <AppSetting/>
        </>
    )

}

export default ViewProjects;