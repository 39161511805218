import Card from "../../basic/app-dashboard/Card";

function CardHolding() {
    return(
        <Card
            href="/projects"
            image="/dist/img/samson-ZGjbiukp_-A-unsplash.jpg"
            title="Holding"
            description="Crie e edite projetos de Holding, associando bens com sócios"
        />
    );
}

export default CardHolding;