import Card from "../../basic/app-dashboard/Card";

function CardPaymentPortal() {
    return(
        <Card
            href="https://guias.almeidaenogueira.com.br/"
            image="/dist/img/SCR-20230921-mcbz.jpeg"
            title="Portal de Pagamentos"
            description="Realize a troca de comprovantes e outros documentos e veja relatórios dinâmicos sobre os processos"
        />
    );
}

export default CardPaymentPortal;
