import { Link } from 'react-router-dom';

function ItemMenuPaymentPortal() {
    return(
        <>
        <li className="nav-item">
            <Link to="https://guias.almeidaenogueira.com.br/" className="nav-link" target="_blank">
            <i className="nav-icon fas fa-money-bill-wave"></i>
            <p>
                Portal de Pagamentos
            </p>
            </Link>
        </li>
        </>
    );
}

export default ItemMenuPaymentPortal;
