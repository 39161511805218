import Card from "../../basic/app-dashboard/Card";
import PropTypes from 'prop-types';

function CardManageUsers(props) {
    const { show } = props;

    if(show) {
        return(
            <Card
                href="/manage-users"
                image="/dist/img/management.jpg"
                title="Gerenciar Usuários"
                description="Crie, visualize, edite ou remova os usuários de sua empresa
                
                "
            />
        );
    }
    
}

CardManageUsers.prototypes = {
    show: PropTypes.bool,
  };

export default CardManageUsers;