    import AppHeader from '../../../basic/app-header/AppHeader';
    import AdmMenu from '../adm-menu/AdmMenu';
    import AppFooter from '../../../basic/app-footer/AppFooter';
    import AppSetting from '../../../basic/app-setting/AppSetting';
    import React, {useEffect, useState} from 'react';
    import { Avatar, IconButton } from '@mui/material';
    import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
    import DeleteIcon from '@mui/icons-material/Delete';
    import { postAxios } from '../../../../hooks/useAxios';
    import { formatCpf } from '../../../../utils/formatCpf';
    import { format } from 'date-fns';
    
    function CreateAdminUser() {
        const [clients, setClients] = useState(null);
	const [departments, setDepartments] = useState(null);
        const [imagePreview, setImagePreview] = useState(null);
        const [isProcessing, setIsProcessing] = useState(false);
        const form = new FormData();

        const handleImageChange = (selectedImage) => {
            console.log(selectedImage)
            if (selectedImage) {
                const imageUrl = URL.createObjectURL(selectedImage);
                setImagePreview(imageUrl);
                // Adicione a imagem ao FormData
                form.append('image', selectedImage, selectedImage.name);
            }
        };
        
        const handleImageDrop = (e) => {
            e.preventDefault();
            const selectedImage = e.dataTransfer.files[0];
            handleImageChange(selectedImage);
        };

        const handlePreviewClick = () => {
            document.getElementById('imageInput').click();
        };

        const handleRemoveImage = () => {
            // Remova a imagem do FormData
            form.delete('image');
            setImagePreview(null);
        };
        
        // obter as opcoes dos selects
        useEffect(() => {
            const getFormOptions = async () => {
                postAxios('/api/load/clients')
                    .then((resp) => {
                    setClients(resp.data.clients);
		    setDepartments(resp.data.departamentos);
                    console.log(resp.data.clients)
                    })
                    .catch((error) => {
                        window.location.href = "/bad/request";
                        console.log(error.response);
                    })
            };
            
            getFormOptions();
        
        }, []);

        if(clients === null) {
            return(
                <>
                <AppHeader/>
                <AdmMenu/>
                <div className='content-wrapper'>
                    <div className='card-body'>
                        <div style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100vh"
                        }}>
                            <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                    </div>
                </div>
                <AppFooter/>
                <AppSetting/>
                </>
            ); 
        }

        // * Requisição para API
        async function createAdmUser(){
            if (isProcessing) return; // Impede cliques adicionais enquanto já está em andamento
            setIsProcessing(true);
            const username = document.querySelector('#username').value;
            const cpf = document.querySelector('#cpf').value;
            const email = document.querySelector('#email').value;
            const client = document.querySelector('#client').value;
            const password = document.querySelector('#password').value;
            const confirmPassword = document.querySelector('#confirmPassword').value;
	    const department = document.querySelector('#department').value;

            // insercao no formulario para o envio
            form.append('username', username);
            form.append('cpf', cpf);
            form.append('email', email);
            form.append('client', client);
            form.append('password', password);
            form.append('confirmPassword', confirmPassword);
	    form.append('department', department);
            const config = {
                headers: {
                'Content-Type': 'multipart/form-data', // Importante: defina o cabeçalho 'Content-Type' como 'multipart/form-data' para enviar arquivos
                },
            };

            postAxios(`/api/create/adm`, form, null, config)
            .then((resp) => {
                if(resp.data.errors){
                    for(let i=0; i<resp.data.errors.length; i++){
                        let elemento = document.querySelector(`#${resp.data.errors[i].elemento}`);
                        console.log(resp.data.errors[i].elemento)
                        console.log(elemento)
                        
                        elemento.placeholder = "Preencha esse campo";
                        elemento.classList.add('erro'); 
                        
                        if(resp.data.errors[i].mensagem === 'Este CPF/CNPJ já está em uso!') {
                            alert('Este CPF/CNPJ já está em uso!');
                        }
                    }
                    return;
                }
                window.location = `/adm/admin-users`
            })
            .catch((error) => {
                // window.location.href = "http://localhost:3000/bad/request";
                console.error('Erro na solicitação POST:', error);
            })
            .finally(() => {
                setIsProcessing(false); // Habilita o botão novamente após a conclusão ou erro
            })
            
            }
            
            return (
            <>
            <AppHeader />
            <AdmMenu />
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <section className="content-header">
                    <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                        <h1>Criando novo usuário administrador do cliente</h1>
                        </div>
                        <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                            <li className="breadcrumb-item"><a href="/adm">Portal Administrador</a></li>
                            <li className="breadcrumb-item"><a href="/adm/admin-users">Administradores - Cliente</a></li>
                            <li className="breadcrumb-item active">Criar Administrador</li>
                        </ol>
                        </div>
                    </div>
                    </div>
                </section>

                {/* Main content */}
                <section class="content">
                    <div class="container-fluid">
                        <div class="card card-default">
                            <div class="card-body">  
                            <div className='input-grid form-group'>
                                <div className='campo1'>
                                    <label htmlFor="profileImage" style={{marginLeft: 40}}>Foto de Perfil:</label>
                                    <div
                                        className=' form-group image-input-preview'
                                        onDrop={handleImageDrop}
                                        onDragOver={(e) => e.preventDefault()}
                                    >
                                        <div className="circle-placeholder" onClick={handlePreviewClick}>
                                            <div className="image-container">
                                                {imagePreview ? (
                                                    <div>
                                                        <Avatar alt="Profile Image" src={imagePreview} sx={{ width: 175, height: 175 }} />
                                                    </div>
                                                ) : (
                                                    <div className="input-indicator">                                                    
                                                        <PhotoCameraIcon />
                                                    </div>
                                                )}
                                            </div>
                                            <input
                                                id="imageInput"
                                                type="file"
                                                accept="image/*"
                                                onChange={(e) => handleImageChange(e.target.files[0])}
                                                style={{ display: 'none' }}
                                            />
                                        </div>
                                        <label htmlFor="imageInput" className='small-text' style={{marginLeft: 35}}>Clique ou arraste!</label>
                                        <div className='row'>
                                            {imagePreview && (
                                                <div style={{marginLeft: 150, marginTop: -45}}>
                                                    <IconButton
                                                        color="error"
                                                        aria-label="remove"
                                                        onClick={handleRemoveImage}
                                                        className="remove-button"
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </div>
                                            )}
                                            
                                        </div>
                                    </div>
                                </div>
                                <div class="campo2">
                                    <label class="required" for="username">Nome do usuário administrador:</label>
                                    <input type="text" name="username" id="username" class="form-control"/>
                                </div> 
                                <div class="campo3">
                                    <label class="required" >CPF:</label>
                                    <input type="text" name="cpf" id="cpf" class="form-control" onInput={formatCpf}/>
                                </div>
                                <div class="campo4">
                                    <label class="required" >E-mail:</label>
                                    <input type="email" name="email" id="email" class="form-control"/>
                                </div>
                                <div class="campo5">
                                    <label class="required" >Cliente (Empresa):</label>
                                    <select name="client" id="client" class="form-control">
                                        <option></option>
                                        {clients && clients.map(resp => (
                                            <option>{resp.name}</option>
                                        ))}
                                    </select>
                                </div>
		                <div class="campo6">
                                    <label class="required" >Departamento:</label>
                                    <select name="department" id="department" class="form-control">
                                        <option></option>
                                        {departments && departments.map(resp => (
                                            <option>{resp.departamento}</option>
                                        ))}
                                    </select>
                                </div>
                                <div class="campo7">
                                    <label class="required" >Senha:</label>
                                    <input type="password" name="password" id="password" class="form-control"/>
                                </div>
                                <div class="campo8">
                                    <label class="required" >Confirme a senha:</label>
                                    <input type="password" name="confirmPassword" id="confirmPassword" class="form-control"/>
                                </div>
                            </div>
                            <div class="col-12 center-element">
                                <a href="/adm/admin-users"><button type="button" class="cancel btn btn-gray">Cancelar</button></a>
                                <button type="submit" class="enviar btn btn-gray" style={{marginLeft: 5}} onClick={createAdmUser}>
                                    {isProcessing ? 'Aguarde...' : 'Enviar'}    
                                </button>
                            </div>
                            </div> 
                        </div>
                    </div>
                </section>
            {/* /.content */}
            </div>
            <AppFooter />
            <AppSetting />
            </>  
        );
    }

    export default CreateAdminUser;
