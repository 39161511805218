import AppHeader from "../../basic/app-header/AppHeader";
import AppMenu from "../../basic/app-menu/AppMenu";
import AppFooter from "../../basic/app-footer/AppFooter";
import AppSetting from "../../basic/app-setting/AppSetting";
import OptionsMenu from "./OptionsMenu";
import { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import { useParams } from "react-router-dom";
import { getAxios } from "../../../hooks/useAxios";
import { IconButton } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';

export default function AnalysisResultSentence() {
    const { idAnalysis } = useParams();

    console.log(idAnalysis)
    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [selectedStyle, setSelectedStyle] = useState(null);
    const [data, setData] = useState(null)
    const [doc, setDoc] = useState(null)
    console.log(selectedOption);
    console.log(selectedIndex);

    useEffect(()=> {
        getAxios(`https://adm.almeidaenogueira.com.br/api/get/one/service/ai/${idAnalysis}`, null)
        .then(resp => {
            console.log('Dados recebidos:', resp);
            setData(resp.analise)
	    setDoc(resp.arquivo)
            if (resp.analise && resp.analise.length > 0) {
                handleButtonClick(resp.analise[0].id, resp.analise[0], 0); // Seleciona o primeiro botão ao carregar
            }
            
        })
        .catch(error => {
            // Trate qualquer erro que ocorra durante a solicitação
            console.error('Erro:', error);
        });
    },[])

    //   console.log(selectedOption.name)

    const handleOptionSelect = (option) => {
        setSelectedOption(option);
    };

    const handleIndexSelect = (index) => {
        setSelectedIndex(index);
    };

    const handleStyleSelect = (style) => {
        setSelectedStyle(style);
    };

    const handleButtonClick = (id, item, index) => {
        setSelectedOption(item);
        setSelectedIndex(id);
        setSelectedStyle(index);
    };

    console.log(data)

    return (
        <>
            <AppHeader />
            <AppMenu />
            <div className="content-wrapper">
                <section class="content-header" style={{ marginBottom: -5 }}>
                    <div class="container-fluid">
                        <div class="row mb-2">
                            <div class="col-sm-6">
                                <h1>Resultados da análise do serviço</h1>
                            </div>
                            <div class="col-sm-6">
                                <ol class="breadcrumb float-sm-right">
                                    <li class="breadcrumb-item">
                                        <a href="/">Início</a>
                                    </li>
                                    <li class="breadcrumb-item active">
                                        Resultados da análise
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="content">
                    <div className="container-fluid">
                        <div className="card card-default">
                            <div className="card-body analysis-result">
                                <div className="contract">
                                    <h3 className="contract-title ">
                                        {`Análise de Sentença ${idAnalysis}`}
	                                <a href={`https://adm.almeidaenogueira.com.br/api/download/analise/${doc}`}><IconButton
                                                sx={{ ml: 2, color: '#343a40'}}
                                            >
                                                <DownloadIcon />
                                            </IconButton></a>
                                    </h3>
                                    <div style={{ marginTop: 60}}>
                                        {data && <OptionsMenu
                                            data={data}
                                            itemsPerPage={5}
                                            onItemSelect={handleOptionSelect}
                                            onIndexSelected={handleIndexSelect}
                                            onStyleSelected={handleStyleSelect}
                                            onButtonClick={handleButtonClick}
                                        />}
                                    </div>
                                </div>
                                <div className="separator" />
                                <div className="result-card">
                                    {selectedOption && selectedIndex && (
                                        <>
                                            <div
                                                className={`option-btn btn btn-${selectedStyle} result-title`}
                                            >
                                                {selectedOption.name}
					        <a href={`https://adm.almeidaenogueira.com.br/api/generate/analysis/${idAnalysis}/${selectedOption.id}`}><IconButton
                                                sx={{ ml: 2, color: '#343a40'}}
                                            >
                                                <DownloadIcon />
                                            </IconButton></a>
                                            </div>
                                            <div className=" result-body markdown-content">
                                                <ReactMarkdown>
                                                    {selectedOption.response}
                                                </ReactMarkdown>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <AppFooter />
            <AppSetting />
        </>
    );
}

