import AppHeader from "../../basic/app-header/AppHeader";
import AppMenu from "../../basic/app-menu/AppMenu";
import AppFooter from "../../basic/app-footer/AppFooter";
import AppSetting from "../../basic/app-setting/AppSetting";
import { useState, useEffect } from "react";
import { getAxios } from "../../../hooks/useAxios";
import  axios  from "axios";

export default function TranscriptionHistory() {
    const [transcription, setTranscription] = useState(null)

    useEffect(()=> {
    
        async function fetchData() {
            try {
                const transcriptionResponse = await getAxios(`https://adm.almeidaenogueira.com.br/api/get/degravation`, null, { withCredentials: true });
                // const analyzedData = transcriptionResponse.analises;
//                console.log(analyzedData)
		console.log(transcriptionResponse)
                setTranscription(transcriptionResponse.degravations)
        
            } catch (error) {
                console.error('Erro ao buscar transcrições:', error);
            }
        }
        fetchData();
    },[])

	console.log(transcription)

    if(transcription === null || transcription === '' || transcription === undefined){
        return(
            <>
            <AppHeader/>
            <AppMenu/>
            <div className='content-wrapper'>
                <div className='card-body'>
                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100vh"
                    }}>
                        <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>
            </div>
            <AppFooter/>
            <AppSetting/>
            </>
        );
    }


    return(
        <>
        <AppHeader/>
        <AppMenu/>
        {/* <!-- Content Wrapper. Contains page content --> */}
        <div class="content-wrapper">
            {/* <!-- Content Header (Page header) --> */}
            <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                <div class="col-sm-6">
                    <h1></h1>
                </div>
                <div class="col-sm-6">
                    <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="/">Início</a></li>
                    <li class="breadcrumb-item active">Transcrições</li>
                    </ol>
                </div>
                </div>
            </div>
            {/* <!-- /.container-fluid --> */}
            </section>

            {/* <!-- Main content --> */}
            <section class="content">
            <div class="container-fluid">
                <div class="row">
                <div class="col-12">
                    <div class="card">
                    <div class="card-header">
                        <h3 class="card-title" style={{ fontWeight: 600 }}>Trancrições de vídeos</h3>
                    </div>
                    <div class="card-body">
                        <table id="example1" class="table table-bordered table-striped dataTable dtr-inline">
                        <thead>
                            <tr>
                                <th>
                                    Transcrição (id)
                                </th>
                                <th>Título do vídeo</th>
                                <th className='coluna-visualizar'>Transcrição (Doc)</th>
                            </tr>
                        </thead>
                        <tbody id="tabela-corpo">
                        {
                            transcription && transcription.map(resp=> (
                                <tr>
                                    <td><a>Transcrição ({resp.id})</a></td>
                                    <td>
                                        <a>
                                            {resp.titulo}
                                        </a>
                                    </td>
                                    <td classList="action-column" className='center-element coluna-visualizar'>
                                        <a href={`https://adm.almeidaenogueira.com.br/api/download/analise/${resp.arquivo}`}><button className='btn btn-primary pen-animation'><i class='fa fa-download icon-white' style={{marginRight: 10}}></i>Baixar Doc</button></a>
                                    </td>
                                </tr>
                            ))
                        }
                        </tbody>
                        </table>
                    </div>
                    {/* <!-- /.card-body --> */}
                    </div>
                    {/* <!-- /.card --> */}
                </div>
                {/* <!-- /.col --> */}
                </div>
                {/* <!-- /.row --> */}
            </div>
            {/* <!-- /.container-fluid --> */}
            </section>
            {/* <!-- /.content --> */}
        </div>
        {/* <!-- /.content-wrapper --> */}
        <AppFooter/>
        <AppSetting/>
        </>
    )
}
