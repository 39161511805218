import AppHeader from "../../basic/app-header/AppHeader";
import AppMenu from "../../basic/app-menu/AppMenu";
import AppFooter from "../../basic/app-footer/AppFooter";
import AppSetting from "../../basic/app-setting/AppSetting";
import { useState, useEffect } from "react";
import { getAxios } from "../../../hooks/useAxios";
import axios from "axios";

export default function Video() {
    const [analysis, setAnalysis] = useState(null)
    const [url, setUrl] = useState(null)
    useEffect(()=> {
    
        async function fetchData() {
            try {
                const analysisResponse = await getAxios(`https://adm.almeidaenogueira.com.br/api/get/all/service/ai`, null, { withCredentials: true });
                const analyzedData = analysisResponse.analises;
                setAnalysis(analyzedData)
        
            } catch (error) {
                console.error('Erro ao buscar análises:', error);
            }
        }
        fetchData();
    },[])

    if(analysis === null || analysis === '' || analysis === undefined){
        return(
            <>
            <AppHeader/>
            <AppMenu/>
            <div className='content-wrapper'>
                <div className='card-body'>
                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100vh"
                    }}>
                        <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>
            </div>
            <AppFooter/>
            <AppSetting/>
            </>
        );
    }

	axios('https://adm.almeidaenogueira.com.br/api/get/prints/video-Serv-0004533.webm')
            .then(response => response.blob())
            .then(blob => {
                const videoURL = URL.createObjectURL(blob);
                setUrl(videoURL);
            })
            .catch(error => console.error('Erro ao carregar o vídeo:', error));


    return(
        <>
        <AppHeader/>
        <AppMenu/>
        {/* <!-- Content Wrapper. Contains page content --> */}
        <div class="content-wrapper">
           <h1>Teste</h1>
	   <video id="videoPlayer" width="640" height="360" src={url} controls>
	    Seu navegador não suporta o elemento de vídeo.
	    </video> 
        </div>
        {/* <!-- /.content-wrapper --> */}
        <AppFooter/>
        <AppSetting/>
        </>
    )
}    
