import AppHeader from '../../basic/app-header/AppHeader';
import AppMenu from '../../basic/app-menu/AppMenu';
import AppFooter from '../../basic/app-footer/AppFooter';
import AppSetting from '../../basic/app-setting/AppSetting';
import BiFrame from './BiFrame';
import { postAxios } from '../../../hooks/useAxios';
import { useState, useEffect } from 'react';
import axios from 'axios';

function ViewBi() {
    
    const [title, setTitle] = useState('Visualização do BI');
    const [url, setUrl] = useState(null);
    
    useEffect(() => {
        const getUrl = async () => {
            axios.post('/api/get/bi', null, {withCredentials: true})
                .then((resp) => {
                    setUrl(resp.data.url);
                })
                .catch((error) => {
                    //window.location.href = "/login";
                    console.error('Erro na solicitação POST:', error);
                })
        }

        getUrl();
        
    }, []);

    if(url === null) {
        return(
            <>
            <AppHeader/>
            <AppMenu/>
            <div className='content-wrapper'>
                <div className='card-body'>
                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100vh"
                    }}>
                        <div class="spinner-border" role="status">
                        <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>
            </div>
            <AppFooter/>
            <AppSetting/>
            </>
        ); 
    }

    return(
        <>
        <AppHeader/>
        <AppMenu/>
        <div className="content-wrapper">
            {/* <!-- Content Header (Page header) --> */}
            <section class="content-header" style={{marginBottom: -5}}>
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-6"/>
                        <div class="col-sm-6">
                            <ol class="breadcrumb float-sm-right">
                                <li class="breadcrumb-item"><a href="/">Início</a></li>
                                <li class="breadcrumb-item active">Business Intelligence (BI)</li>
                            </ol>
                        </div>
                    </div>
                </div>
                {/* <!-- /.container-fluid --> */}
            </section>
            {/* <!-- Main content --> */}
            <section class="content">
            <div class="container-fluid">
                <div class="row">
                <div class="col-12">
                    <div class="card">
                    <div class="card-body">
                        <BiFrame
                            title={title}
                            url={url}
                        />
                    </div>
                    {/* <!-- /.card-body --> */}
                    </div>
                    {/* <!-- /.card --> */}
                </div>
                {/* <!-- /.col --> */}
                </div>
                {/* <!-- /.row --> */}
            </div>
            {/* <!-- /.container-fluid --> */}
            </section>
            {/* <!-- /.content --> */}
        </div>
        <AppFooter/>
        <AppSetting/>
        </>
    )

}

export default ViewBi;
