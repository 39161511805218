    import AppHeader from "../../basic/app-header/AppHeader";
    import AppMenu from "../../basic/app-menu/AppMenu";
    import AppFooter from "../../basic/app-footer/AppFooter";
    import AppSetting from "../../basic/app-setting/AppSetting";
    import OptionsMenu from "./OptionsMenu";
    import { useEffect, useState } from "react";
    import ReactMarkdown from "react-markdown";
    import { useParams } from "react-router-dom";
    import { getAxios } from "../../../hooks/useAxios";
import { IconButton } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';

    export default function AnalysisResult() {
        const { idAnalysis } = useParams();
        const [selectedOption, setSelectedOption] = useState(null);
        const [selectedIndex, setSelectedIndex] = useState(null);
        const [selectedStyle, setSelectedStyle] = useState(null);
        const [data, setData] = useState(null);
        const [doc, setDoc] = useState(null);
        // const data = [
        //     {
        //         id: 1,
        //         name: "Resumo",
        //         response:
        //             "Este Contrato Jurídico Empresarial estabelece os termos e condições entre duas partes empresariais, identificadas como Parte A e Parte B. O contrato define o objeto da relação comercial, os prazos de vigência, as condições de pagamento e a propriedade intelectual. Além disso, prevê cláusulas de confidencialidade e procedimentos para rescisão, regidos pela legislação aplicável e com mecanismos de resolução de disputas. Ambas as partes concordam em cumprir os termos estabelecidos e reconhecem que o contrato pode ser modificado apenas por escrito e com consentimento mútuo. Este documento reflete o entendimento completo entre as partes e deve ser assinado pelos representantes legais de ambas as empresas para entrar em vigor.",
        //     },
        //     {
        //         id: 2,
        //         name: "Objeto",
        //         response:
        //         "### Objeto do Contrato: Prestação de Serviços de Consultoria Empresarial\n\n**Explicação:**\nPrestação de Serviços de Consultoria Empresarial: Este contrato estabelece que a Parte A, uma empresa de consultoria empresarial, fornecerá serviços de consultoria à Parte B, uma empresa que busca melhorar sua eficiência operacional e estratégica. Os serviços incluem análise de processos, identificação de áreas de melhoria, desenvolvimento e implementação de estratégias, treinamento de equipe, entre outros.\n\nDentro desse contexto, o contrato detalharia os serviços específicos a serem prestados, os prazos de execução, as responsabilidades de cada parte, as taxas de remuneração e qualquer outra condição relevante para a prestação eficaz dos serviços de consultoria.",
        //     },
        //     {  id: 3, name: "Partes", response: "" },
        //     { id: 4, name: "Prazos", response: "" },
        //     { id: 5, name: "Cláusulas", response: "" },
        //     { id: 6, name: "Recisão", response: "" },
        //     { id: 7, name: 'Extra1' },
        //     { id: 8, name: "Extra2" }
        // ];

        useEffect(()=> {
            getAxios(`https://adm.almeidaenogueira.com.br/api/get/one/service/ai/${idAnalysis}`, null)
            .then(resp => {
                console.log('Dados recebidos:', resp);
                setData(resp.analise)
		setDoc(resp.arquivo)
                if (resp.analise && resp.analise.length > 0) {
                    handleButtonClick(resp.analise[0].id, resp.analise[0], 0); // Seleciona o primeiro botão ao carregar
                }
                
            })
            .catch(error => {
                // Trate qualquer erro que ocorra durante a solicitação
                console.error('Erro:', error);
            });
        },[])

        //   console.log(selectedOption.name)

	console.log(data);

        const handleOptionSelect = (option) => {
            setSelectedOption(option);
        };

        const handleIndexSelect = (index) => {
            setSelectedIndex(index);
        };

        const handleStyleSelect = (style) => {
            setSelectedStyle(style);
        };

        const handleButtonClick = (id, item, index) => {
            setSelectedOption(item);
            setSelectedIndex(id);
            setSelectedStyle(index);
        };

        return (
            <>
                <AppHeader />
                <AppMenu />
                <div className="content-wrapper">
                    <section class="content-header" style={{ marginBottom: -5 }}>
                        <div class="container-fluid">
                            <div class="row mb-2">
                                <div class="col-sm-6">
                                    <h1>Resultados da análise do serviço</h1>
                                </div>
                                <div class="col-sm-6">
                                    <ol class="breadcrumb float-sm-right">
                                        <li class="breadcrumb-item">
                                            <a href="/">Início</a>
                                        </li>
                                        <li class="breadcrumb-item active">
                                            Resultados da análise
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="card card-default">
                                <div className="card-body analysis-result">
                                    <div className="contract">
                                        <h3 className="contract-title ">
                                            {`Análise de Contrato ${idAnalysis}`}
		                        
		                            <a href={`https://adm.almeidaenogueira.com.br/api/download/analise/${doc}`}><IconButton
                                                sx={{ ml: 2, color: '#343a40'}}
                                            >
                                                <DownloadIcon />
                                            </IconButton></a>
                                        </h3>
                                        <div style={{ marginTop: 60 }}>
                                            {data && <OptionsMenu
                                                data={data}
                                                itemsPerPage={6}
                                                onItemSelect={handleOptionSelect}
                                                onIndexSelected={handleIndexSelect}
                                                onStyleSelected={handleStyleSelect}
                                                onButtonClick={handleButtonClick}
                                            />}
                                        </div>
                                    </div>
                                    <div className="separator" />
                                    <div className="result-card">
                                        {selectedOption && selectedIndex && (
                                            <>
                                                <div
                                                    className={`option-btn btn btn-${selectedStyle} result-title`}
                                                >
                                                    {selectedOption.name}
						    <a href={`https://adm.almeidaenogueira.com.br/api/generate/analysis/${idAnalysis}/${selectedOption.id}`}><IconButton
                                                sx={{ ml: 2, color: '#343a40'}}
                                            >
                                                    <DownloadIcon />
                                                    </IconButton></a>
                                                </div>
                                                <div className=" result-body markdown-content">
                                                    <ReactMarkdown>
                                                        {selectedOption.response}
                                                    </ReactMarkdown>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <AppFooter />
                <AppSetting />
            </>
        );
    }

