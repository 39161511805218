import Card from "../../basic/app-dashboard/Card";

function CardSsj() {
    return(
        <Card
            href="/ssj"
            image="/dist/img/scott-graham-OQMZwNd3ThU-unsplash.jpg"
            title="Solicitação de Serviço Jurídico (SSJ)"
            description="Realize o envio dos serviços jurídicos de sua empresa"
        />
    );
}

export default CardSsj;