
function ItemMenuClients() {
    return(
        <li className="nav-item">
            <a href="/adm/clients" className="nav-link">
            <i className="nav-icon fas fa-user-tie"></i>
            <p>
                Clientes
            </p>
            </a>
        </li>
    );
}

export default ItemMenuClients;