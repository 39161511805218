import React from 'react';
import ItemMenuClients from '../clients/ItemMenuClients';
import ItemMenuAdminUsers from '../admin-users/ItemMenuAdminUsers'
import ItemMenuScreenRec from '../screen-rec/ItemMenuScreenRec';

function AdmMenu() {
  return (
    <aside className="main-sidebar sidebar-dark-primary elevation-4">
      {/* <!-- Brand Logo --> */}
      <a href="/" className="brand-link">
        <img src="/dist/img/pngBranco.png" alt="AdminLTE Logo" className="brand-image img-circle elevation-3" style={{ opacity: '.8' }}/>
        <span className="brand-text font-weight-light">Administrador AN</span>
      </a>

      {/* <!-- Sidebar --> */}
      <div className="sidebar">
        {/* <!-- Sidebar user (optional) --> */}
        <div className="user-panel mt-3 pb-3 mb-3 d-flex">
          <div className="image" id="foto-perfil">
            <img src="/dist/img/AdminLTELogo.png" className="img-circle elevation-2" alt="User Image"/>
          </div>
          <div className="info" id="nome-perfil" style={{color: '#a3a6a9'}}>
            {'Adm'}
          </div>
        </div>

        {/* <!-- Sidebar Menu --> */}
        <nav className="mt-2">
          <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
            <li className="nav-item">
              <a href="/adm" className="nav-link">
                <i className="nav-icon fas fa-th"></i>
                <p>
                  Portal Administrador
                </p>
              </a>
            </li>
            <ItemMenuClients/>
            <ItemMenuAdminUsers/>
	    <ItemMenuScreenRec/>

          </ul>
        </nav>

        {/* <!-- /.sidebar-menu --> */}
      </div>
      {/* <!-- /.sidebar --> */}
    </aside>
  );
}

export default AdmMenu;
