import AppHeader from '../../../basic/app-header/AppHeader';
import AppMenu from '../../../basic/app-menu/AppMenu';
import AppFooter from '../../../basic/app-footer/AppFooter';
import AppSetting from '../../../basic/app-setting/AppSetting';
import React, {useState, useEffect} from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

function EditPartner(){
    // considerando que no bd os bens terão um idTipo, que será de 1 e 2, indicando, respectivamente, pessoa física e pessoa jurídica
    const [idTipo, setIdTipo] = useState(0)
    // variável para pegar o id da url
    const {id} = useParams();
    // armazenar as informações pré-carregadas do sócio que será editado
    const [socio, setSocio] = useState({
        nome: null, 
        nacionalidade: null, 
        nascimento: null, 
        cpf: null, 
        rg: null, 
        email: null, 
        profissao: null, 
        estado_civil: null, 
        regime: null, 
        endereco: null, 
        cep: null, 
        cidade: null, 
        estado: null, 
        cnpj: null, 
        nire: null, 
        representante: null, 
        cpfrepre: null, 
        endereco_representante: null
    })    

    // * Carregar dados do sócio
    useEffect(() => {
        const getPartner = async () => { 
          try {
            const response = await axios.post(`http://localhost:5000/api/get/partners`, {id}, { withCredentials: true });
            if (response.data.status === 200) {
                setSocio(response.data.partner);
                if(response.data.partner.cpf){
                    setIdTipo(1);
                } else if(response.data.partner.cnpj){
                    setIdTipo(2);
                }
            } else {
                console.log("status: "+response.data.status)
                window.location.href = "http://localhost:3000/bad/request";
                console.log("status: "+response.data.status)

            }
          } catch (error) {
            console.error(error);
            window.location.href = "http://localhost:3000/bad/request";
          }
        };
    
        getPartner();
    }, []);
    
    // função para permitir que os campos com valores pré-carregados possam ser alterados
    function loadForm(event){
        const { name, value } = event.target;
        console.log(name)
        console.log(value)
        setSocio(prevState => ({
          ...prevState,
          [name]: value
        }));
        
    }

    // * Função para atualizar o sócio
    function updatePartner(){
        if(idTipo == 1){
            const acao = "update";
            let tipo = "fisica"
            let nome = document.querySelector('#nome').value
            let nascimento = document.querySelector('#nascimento').value
            let nacionalidade = document.querySelector('#nacionalidade').value
            let rg = document.querySelector('#rg').value
            let cpf = document.querySelector('#cpf').value
            let profissao = document.querySelector('#profissao').value
            let civil = socio.estado_civil
            let regime = document.querySelector('#regime').value
            let endereco = document.querySelector('#endereco').value
            let cep = document.querySelector('#cep').value
            let cidade = document.querySelector('#cidade').value
            let estado = document.querySelector('#estado').value
            let email = document.querySelector('#email').value
            try {
                axios.post('http://localhost:5000/api/actions/partners',  {acao, id, tipo, nome, nascimento, nacionalidade, rg, cpf, profissao, civil, regime, endereco, cep, cidade, estado, email}, {withCredentials: true})
                .then((resp)=>{
                    if(Array.isArray(resp.data.erros)){
                        if(resp.data.erros.length === 0 && resp.data.status == 200){
                            window.location.href = "http://localhost:3000/partners";  
                        } else {
                            console.log(resp.data.erros)
                            for(let i=0; i<resp.data.erros.length; i++){
                                let elemento = document.querySelector(`#${resp.data.erros[i].elemento}`)
                                elemento.classList.add('erro')
                                if(resp.data.erros[i].elemento != nascimento || resp.data.erros[i].elemento != civil){
                                    elemento.placeholder = "Preencha esse campo"
                                }
                            }
                        }
                    }
                })
            } catch (error) {
                console.log(error);
                window.location.href = "http://localhost:3000/bad/request";
            }
        } else if(idTipo == 2) {
            const acao = "update";
            let tipo = "juridica"
            let nome = document.querySelector('#nome').value
            let nacionalidade = document.querySelector('#nacionalidade').value
            let cnpj = document.querySelector('#cnpj').value
            let nire = document.querySelector('#nire').value
            let endereco = document.querySelector('#endereco').value
            let cep = document.querySelector('#cep').value
            let cidade = document.querySelector('#cidade').value
            let estado = document.querySelector('#estado').value
            let representante = document.querySelector('#representante').value
            let cpfrepre = document.querySelector('#cpfrepre').value
            let enderecorep = document.querySelector('#enderecorep').value
            try {
                axios.post('http://localhost:5000/api/actions/partners',  {acao, id, tipo, nome, nacionalidade, cnpj, nire, endereco, cep, cidade, estado, representante, cpfrepre, enderecorep}, {withCredentials: true})
                .then((resp)=>{
                    if(Array.isArray(resp.data.erros)){
                        if(resp.data.erros.length === 0 && resp.data.status == 200){
                            window.location.href = "http://localhost:3000/partners";  
                        } else {
                            console.log(resp.data.erros)
                            for(let i=0; i<resp.data.erros.length; i++){
                                let elemento = document.querySelector(`#${resp.data.erros[i].elemento}`)
                                elemento.placeholder = "Preencha esse campo"
                                elemento.classList.add('erro')
                            }
                        }
                    }
                })
            } catch (error) {
                console.log(error);
                window.location.href = "http://localhost:3000/bad/request";
            }
        }
    }

    // * Funções de formatação

    // função para o funcionamento do radio button
    function handleRadioChange(event) {
        const radioButton = event.target;
        const labels = document.querySelectorAll('.custom-radio label');
        labels.forEach(function(label) {
          label.classList.remove('selected');
        });
      
        if (radioButton.checked) {
          radioButton.nextElementSibling.classList.add('selected');
          setSocio(prevState => ({
            ...prevState,
            estado_civil: radioButton.value 
          }));
        }
    }

    // função para formatar o campo de cep (00000-000)
    function formatCep(e) {
        const campo = e.target;
        const valor = campo.value.replace(/\D/g, ""); // Remove tudo o que não é dígito
        let novoValor = "";

        if (valor.length <= 10) {
        novoValor = valor.replace(/(\d{5})(\d{3}).*/, "$1-$2");
        } 

        campo.value = novoValor;
    }

    // função para formatar o campo de cpf(000.000.000-00)
    function formatCpf(e) {
        const campo = e.target;
        const valor = campo.value.replace(/\D/g, ""); // Remove tudo o que não é dígito
    
        let novoValor = "";
    
        novoValor = valor.replace(/(\d{3})(\d{3})(\d{3})(\d{2}).*/, "$1.$2.$3-$4");
    
        campo.value = novoValor;
    }

    // função para formatar o campo de cnpj(00.000.000/0000-00)
    function formatCnpj(e) {
        const campo = e.target;
        const valor = campo.value.replace(/\D/g, ""); // Remove tudo o que não é dígito
    
        let novoValor = "";
    
        novoValor = valor.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2}).*/, "$1.$2.$3/$4-$5");
    
        campo.value = novoValor;
    }

    // função para formatar o campo de RG(00.000.000-0)
    function formatRG(e) {
        const campo = e.target;
        const valor = campo.value.replace(/\D/g, ""); // Remove tudo o que não é dígito
    
        let novoValor = "";
    
        novoValor = valor.replace(/(\d{2})(\d{3})(\d{3})(\d{1}).*/, "$1.$2.$3-$4");
    
        campo.value = novoValor;
    }
    
    // * Função principal para edição do sócio
    function returnSocio(){
        // pessoa física
        if(idTipo == 1){
            return(
                <>
                    <div className="row">
                        <div className='col-8'>
                            <div className="form-group">
                                <label className="required" for="nome">Nome completo:</label>
                                <input type="text" name="nome" id="nome" className="form-control" value={socio.nome} onChange={loadForm}/>
                            </div>
                        </div>
                        <div className='col-4'>
                            <div class="form-group">
                                <label class="required" >Nacionalidade:</label>
                                <input type="text" name="nacionalidade" id="nacionalidade" class="form-control" value={socio.nacionalidade} onChange={loadForm}/>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-4'>
                            <div class="form-group">
                                <label class="required" for="birthday">Data de nascimento:</label>
                                <input class="form-control" type="date" id="nascimento" name="nascimento" value={socio.nascimento} onChange={loadForm}/>
                            </div>
                        </div>
                        <div className='col-4'>
                            <div class="form-group">
                                <label class="required" >CPF:</label>
                                <input type="text" name="cpf" id="cpf" class="form-control" onInput={formatCpf} value={socio.cpf} onChange={loadForm}/>
                            </div>
                        </div>
                        <div className='col-4'>
                            <div class="form-group">
                                <label class="required" >RG:</label>
                                <input type="text" name="rg" id="rg" class="form-control" onInput={formatRG} value={socio.rg} onChange={loadForm}/>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-6'>
                            <div class="form-group">
                                <label class="required" >E-mail:</label>
                                <input type="email" name="email" id="email" class="form-control" value={socio.email} onChange={loadForm}/>
                            </div>
                        </div>
                        <div className='col-6'>
                            <div class="form-group">
                                <label class="required" >Profissão:</label>
                                <input type="text" name="profissao" id="profissao" class="form-control" value={socio.profissao} onChange={loadForm}/>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-7'>
                            <div class="form-group">
                                <label class="required" >Estado civil:</label>
                                <div class="form-group">
                                    <div id="custom-radio">
                                        <input type="radio" name="estadocivil" id="casado" value="casado" checked={socio.estado_civil === 'casado'} onChange={handleRadioChange}/>
                                        <label style={{marginLeft: 2}} for="casado">Casado(a)</label>

                                        <input type="radio" name="estadocivil" id="solteiro" value="solteiro" checked={socio.estado_civil === 'solteiro'} onChange={handleRadioChange}/>
                                        <label for="solteiro">Solteiro(a)</label>

                                        <input type="radio" name="estadocivil" id="divorcio" value="divorcio" checked={socio.estado_civil === 'divorcio'} onChange={handleRadioChange}/>
                                        <label for="divorcio">Divorcio(a)</label>

                                        <input type="radio" name="estadocivil" id="uniao" value="uniao" checked={socio.estado_civil === 'uniao'} onChange={handleRadioChange}/>
                                        <label for="uniao">União estável</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-5'>
                            <div class="form-group">
                                <label class="required" >Regime de casamento:</label>
                                <input type="text" name="regime" id="regime" class="form-control" value={socio.regime} onChange={loadForm}/>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-5'>
                            <div class="form-group">
                                <label class="required" >Endereço:</label>
                                <input type="text" name="endereco" id="endereco" class="form-control" value={socio.endereco} onChange={loadForm}/>
                            </div>
                        </div>
                        <div className='col-3'>
                            <div class="form-group">
                                <label class="required" >CEP:</label>
                                <input type="text" name="cep" id="cep" class="form-control" onInput={formatCep} value={socio.cep} onChange={loadForm}/>
                            </div>
                        </div>
                        <div className='col-2'>
                            <div class="form-group">
                                <label class="required" >Cidade:</label>
                                <input type="text" name="cidade" id="cidade" class="form-control" value={socio.cidade} onChange={loadForm}/>
                            </div>
                        </div>
                        <div className='col-2'>
                            <label class="required" >Estado:</label>
                            <select className="form-control" id="estado" name="estado" value={socio.estado} onChange={loadForm}  >
                                <option></option>
                                <option>AC</option>
                                <option>AL</option>
                                <option>AP</option>
                                <option>AM</option>
                                <option>BA</option>
                                <option>CE</option>
                                <option>DF</option>
                                <option>ES</option>
                                <option>GO</option>
                                <option>MA</option>
                                <option>MT</option>
                                <option>MS</option>
                                <option>MG</option>
                                <option>PA</option>
                                <option>PB</option>
                                <option>PR</option>
                                <option>PE</option>
                                <option>PI</option>
                                <option>RJ</option>
                                <option>RN</option>
                                <option>RS</option>
                                <option>RO</option>
                                <option>RR</option>
                                <option>SC</option>
                                <option>SP</option>
                                <option>SE</option>
                                <option>TO</option>
                            </select>
                        </div>
                    </div>
                </>     
            );  
        // pessoa jurídica
        } else if(idTipo == 2){
            return(
                <>
                <div className="row">
                    <div className='col-8'>
                        <div class="form-group">
                            <label class="required" for="nome">Nome completo:</label>
                            <input type="text" name="nome" id="nome" class="form-control" value={socio.nome} onChange={loadForm}/>
                        </div>
                    </div>
                    <div className='col-4'>
                        <div class="form-group">
                            <label class="required" >Nacionalidade:</label>
                            <input type="text" name="nacionalidade" id="nacionalidade" class="form-control" value={socio.nacionalidade} onChange={loadForm}/>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-6'>
                        <div class="form-group">
                            <label className='required'>CNPJ:</label>
                            <input type="text" name="cnpj" id="cnpj" class="form-control" onInput={formatCnpj} value={socio.cnpj} onChange={loadForm}/>
                        </div>
                    </div>
                    <div className='col-6'>
                        <div class="form-group">
                            <label className='required'>NIRE:</label>
                            <input type="text" name="nire" id="nire" class="form-control" value={socio.nire} onChange={loadForm}/>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-5'>
                        <div class="form-group">
                            <label class="required" >Endereço:</label>
                            <input type="text" name="endereco" id="endereco" class="form-control" value={socio.endereco} onChange={loadForm}/>
                        </div>
                    </div>
                    <div className='col-3'>
                        <div class="form-group">
                            <label class="required" >CEP:</label>
                            <input type="text" name="cep" id="cep" class="form-control" onInput={formatCep} value={socio.cep} onChange={loadForm}/>
                        </div>
                    </div>
                    <div className='col-2'>
                        <div class="form-group">
                            <label class="required" >Cidade:</label>
                            <input type="text" name="cidade" id="cidade" class="form-control" value={socio.cidade} onChange={loadForm}/>
                        </div>
                    </div>
                    <div className='col-2'>
                        <label class="required" >Estado:</label>
                        <select className="form-control" id="estado" name="estado" value={socio.estado} onChange={loadForm}>
                            <option></option>
                            <option>AC</option>
                            <option>AL</option>
                            <option>AP</option>
                            <option>AM</option>
                            <option>BA</option>
                            <option>CE</option>
                            <option>DF</option>
                            <option>ES</option>
                            <option>GO</option>
                            <option>MA</option>
                            <option>MT</option>
                            <option>MS</option>
                            <option>MG</option>
                            <option>PA</option>
                            <option>PB</option>
                            <option>PR</option>
                            <option>PE</option>
                            <option>PI</option>
                            <option>RJ</option>
                            <option>RN</option>
                            <option>RS</option>
                            <option>RO</option>
                            <option>RR</option>
                            <option>SC</option>
                            <option>SP</option>
                            <option>SE</option>
                            <option>TO</option>
                        </select>
                    </div>
                </div>      
                <div className='row'>
                    <div className='col-4'>
                        <div class="form-group">
                            <label className='required'>Representante:</label>
                            <input type="text" name="representante" id="representante" class="form-control" value={socio.representante} onChange={loadForm}/>
                        </div>
                    </div>
                    <div className='col-3'>
                        <div class="form-group">
                            <label className='required'>CPF (representante):</label>
                            <input type="text" name="cpfrepre" id="cpfrepre" class="form-control" onInput={formatCpf} value={socio.cpfrepre} onChange={loadForm}/>
                        </div>
                    </div>
                    <div className='col-5'>
                        <div class="form-group">
                            <label className='required'>Endereço (representante):</label>
                            <input type="text" name="enderecorep" id="enderecorep" class="form-control" value={socio.endereco_representante} onChange={loadForm}/>
                        </div>
                    </div>
                </div>
                </>
            );
        }
    }
    
    return(
        <>
         <AppHeader/>
        <AppMenu/>
        <div class="content-wrapper">
            <section class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-6">
                            <h1>Editor de sócios</h1>
                        </div>
                        <div class="col-sm-6">
                            <ol class="breadcrumb float-sm-right">
                                <li class="breadcrumb-item"><a href="/">Início</a></li>
                                <li class="breadcrumb-item active">Editor de sócios</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </section>
            <section class="content">
                <div class="container-fluid">
                    <div class="card card-default">
                        <div className="card-header center-element bg-gray">
                          <h3 className="card-title" style={{fontWeight: 600}}>{`Editar sócio: ${socio.nome}`}</h3>
                        </div>
                        <div className='card-body'>
                            {returnSocio()}
                            <div class="col-12 center-element">
                                <a href="/partners"><button type="button" class="cancel btn btn-gray">Cancelar</button></a>
                                <button type="submit" class="enviar btn btn-gray" style={{marginLeft: 5}} onClick={updatePartner}>Confirmar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <AppFooter/>
        <AppSetting/>
        </>
    );
}

export default EditPartner;